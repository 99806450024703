// utility functions to determine height and width of yearbook cards on mobile devices

export function isDeviceMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
}

export function getYearbookHeightWidth() {
  const width = window.innerWidth / 2 - 15;
  const height = (7 * width) / 5;

  return [width, height];
}
