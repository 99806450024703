import React from 'react';
import { IconButton } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import FavoriteIcon from '@material-ui/icons/Favorite';

const ScrapSectionSkeleton = () => {
  return (
    <section className={'pp-scraps-section'}>
      <div className="pp-scraps-heading">Scraps</div>
      <div className="pp-scraps-container">
        <>
          {[1, 2, 3, 4, 5, 6, 7].map((item) => (
            <ScrapSkeleton key={item} />
          ))}
        </>
      </div>
      <div className="pp-new-scrap">
        <Skeleton />
      </div>
    </section>
  );
};

const ScrapSkeleton = () => (
  <div className={'pp-scrap-container'}>
    <div className="pp-scrap-photo-name">
      <div className="pp-scrap-photo">
        <Skeleton circle={true} height={45} width={45} />
      </div>

      <div className="pp-scrap-name">
        <Skeleton count={1} />
      </div>
    </div>

    <div className="pp-scrap-content-container">
      <div className="pp-scrap-content">
        <span>
          <Skeleton count={3} />
        </span>
        <div className="pp-scrap-time">
          <Skeleton count={1} />
        </div>
      </div>
    </div>
  </div>
);

export default ScrapSectionSkeleton;
