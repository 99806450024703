import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import college from '../../assets/collegeOne.jpeg';
import '../../styles/new/ProfilePageComponent.css';
import { Avatar } from '@material-ui/core';
import FormatQuoteRoundedIcon from '@material-ui/icons/FormatQuoteRounded';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import userAPI from '../../utils/api/UserAPI';
import Backdrop from '@material-ui/core/Backdrop';
import { School } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EmailIcon from '@material-ui/icons/Email';
import CakeIcon from '@material-ui/icons/Cake';
import { Redirect, Link } from 'react-router-dom';
import moment from 'moment';
import ProfilePageStyles from '../../styles/mui-theme-styles/ProfilePageStyles';
import { timeDiff } from '../../utils/timeDiff';
import { formatDate } from '../../utils/formatDate';

class ProfilePage extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.state = {
      value: 'Enter your Scrap Her',
      loadingBackDropOpen: true,
      alertMessage: '',
      alertSeverity: '',
      alertOpen: false,
      alertMessageHeading: '',
      createScrapOpen: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.updateComment = this.updateComment.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ loadingBackDropOpen: true });

    if (nextProps.routeProps.id !== this.props.routeProps.id) {
      let userPromise = userAPI.getUserFromDBById(nextProps.routeProps.id);
      userPromise
        .then((res) => res.json())
        .then((data) => {
          this.setState({ user: { ...data, dob: moment(data.dob) } });
          this.setState({ people: data.scraps });
          this.setState({ loadingBackDropOpen: false });
        })
        .catch((err) => console.log(err));
    }
  }

  componentDidMount() {
    this.removeGlobalListener = userAPI.addListener((state) => {
      this.setState(state);
    });

    let userPromise = userAPI.getUserFromDBById(this.props.routeProps.id);
    userPromise
      .then((res) => res.json())
      .then((data) => {
        this.setState({ user: { ...data, dob: moment(data.dob) } });
        this.setState({ people: data.scraps });
        this.setState({ loadingBackDropOpen: false });
      })
      .catch((err) => console.log(err));
  }

  handleChange(event) {
    this.setState({
      value: event.target.value,
    });
  }

  async updateComment(event) {
    this.setState({ loadingBackDropOpen: true });
    await userAPI
      .postScrap(this.state.value, this.props.routeProps.id)
      .then((res) => res.json())
      .then((data) => {
        if (data.msg === 'Scrap created successfully') {
          let scrapHeading = 'Scrap Creation Successful!';
          let scrapMessage = `Thank you for posting a scrap! I'm sure ${this.state.user.name} will be delighted to read your scrap. Your scrap will appear on their wall, once approved by them.`;
          this.setState({
            alertMessageHeading: scrapHeading,
            alertMessage: scrapMessage,
          });
        } else {
          let scrapHeading = 'Something Went Wrong.';
          let scrapMessage = `${data.msg}. Try again or contact your network administrator.`;

          this.setState({
            alertMessageHeading: scrapHeading,
            alertMessage: scrapMessage,
          });
        }
      });

    this.setState({ value: '' });

    let newPromise = userAPI.getScrapsFromDBById(this.props.routeProps.id);

    newPromise
      .then((res) => res.json())
      .then((data) => {
        this.setState({ people: data.scraps });
        this.setState({ createScrapOpen: true });
        this.setState({ loadingBackDropOpen: false });
      })
      .catch((err) => console.log(err));
  }

  render() {
    if (this.props.routeProps.id === localStorage.getItem('id')) {
      return <Redirect to="/profile" />;
    } else {
      let loadingBackDrop = (
        <Backdrop
          className={this.props.classes.backdrop}
          open={this.state.loadingBackDropOpen}
        >
          <School className="loadingSchool" />
        </Backdrop>
      );

      let dialog = (
        <Dialog
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          open={this.state.createScrapOpen}
          onClose={() => {
            this.setState({ createScrapOpen: false });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle disableTypography={true} id="alert-dialog-title">
            <h6 className="dialogHeadingCSS">
              {this.state.alertMessageHeading}
            </h6>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              disableTypography={true}
              className={this.props.classes.dialogMessage}
              id="alert-dialog-description"
            >
              <p className="dialogBodyCSS">{this.state.alertMessage}</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="dialogCloseCSS"
              onClick={() => {
                this.setState({ createScrapOpen: false });
              }}
              color="primary"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      );
      if (!this.state.user || !this.state.people) {
        return (
          <Backdrop className={this.props.classes.backdrop} open={true}>
            <School className="loadingSchool" />
          </Backdrop>
        );
      } else {
        const user = this.state.user;

        const listItems = this.state.people.map((person) =>
          person.visibility ? (
            <li key={person.id} className="comment">
              <div className="info">
                <Link
                  to={`/profiles/${person.posted_by.id}`}
                  className=""
                  href=""
                >
                  {person.posted_by.name}
                </Link>
                <span className="spanOut">
                  {timeDiff(person.timestamp, this.state.people.length)}
                </span>
              </div>
              <Link to={`/profiles/${person.posted_by.id}`} className="avatar">
                <Avatar
                  src={person.posted_by.photo.replace('/800/', '/200/')}
                  className={this.props.classes.commentAvatar}
                  alt="Photo"
                />
              </Link>
              <p className="commentText">
                <span className="pText">{person.content}</span>
              </p>
            </li>
          ) : null,
        );

        const nameHeader = `${user.name} - ${user.dept}`;
        const quote = user.quote;
        let dob;
        if (this.state.user.dob === null) {
          dob = new Date();
        } else {
          dob = this.state.user.dob;
        }
        const email = user.email;
        const firstName = user.name.substring(0, user.name.indexOf(' '));
        const firstNameScrap = `${firstName}'s Scraps`;

        return (
          <React.Fragment>
            <main id="content" role="main">
              <div className="profilePageCard2">
                {loadingBackDrop}
                {dialog}
                <Card className={this.props.classes.root} elevation={8}>
                  <div className={this.props.classes.cover}>
                    <CardMedia
                      className={this.props.classes.image}
                      image={college}
                      title="Live from space album cover"
                      height="80vh"
                    />
                    <div className={this.props.classes.profile}>
                      <div className={this.props.classes.nameWrapper}>
                        <p className={this.props.classes.name}>{nameHeader}</p>
                      </div>

                      <div className={this.props.classes.avatarWrapper}>
                        <Avatar
                          src={this.state.user.photo}
                          className={this.props.classes.avatar}
                          alt="Avatar"
                        />
                      </div>
                      <div className={this.props.classes.quoteWrapper}>
                        <FormatQuoteRoundedIcon />
                        <Typography className={this.props.classes.quote}>
                          {quote}
                        </Typography>
                      </div>
                      <div className={this.props.classes.bottomWrapper}>
                        <div className={this.props.classes.emailIconWrapper}>
                          <EmailIcon className={this.props.classes.emailIcon} />
                          <Typography className={this.props.classes.textSimp}>
                            {email}
                          </Typography>
                        </div>
                        <div className={this.props.classes.calendarIconWrapper}>
                          {' '}
                          <CakeIcon
                            className={this.props.classes.calendarIcon}
                          />
                          <Typography className={this.props.classes.textSimp}>
                            {formatDate(dob)}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={this.props.classes.content}>
                    <CardContent className={this.props.classes.cardDiv}>
                      <Typography
                        className={this.props.classes.firstNameTitleProfile}
                      >
                        {firstNameScrap}
                      </Typography>
                      <div id="scrapArea" className="scrapArea">
                        <ul id="comment" className="commentSection">
                          {listItems}
                        </ul>
                      </div>
                      <div className="scrapWriteArea">
                        <TextareaAutosize
                          rowsMax={3}
                          aria-label="empty-textarea"
                          className={this.props.classes.textArea}
                          value={this.state.value}
                          onChange={this.handleChange}
                        />
                        <div>
                          <Avatar
                            src={localStorage.getItem('photo')}
                            className={this.props.classes.commentWriteAvatar}
                          />
                          <Button
                            className={this.props.classes.submitPassButton}
                            onClick={this.updateComment}
                            variant="outlined"
                            color="primary"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </div>
                      </div>
                    </CardContent>
                  </div>
                </Card>
              </div>
            </main>
            <footer className="footer"> © 2020 Team GradGoggles</footer>
          </React.Fragment>
        );
      }
    }
  }
}

export default withStyles(ProfilePageStyles, { withTheme: true })(ProfilePage);
