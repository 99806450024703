import React, { useEffect, useRef, useState } from 'react';
import UserProfileModal from '../UserProfileModal';
import '../../../styles/new/YearbookCard.css';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import ShareIcon from '@material-ui/icons/Share';
import { Tooltip } from '@material-ui/core';
import userAPI from '../../../utils/api/UserAPI';
import { isDeviceMobile } from '../../../utils/yearbookCardDimensionsUtils';
import ReactGA from 'react-ga';

const quoteMaxCharLimit = isDeviceMobile() ? 19 : 57;

const YearbookCard = ({
  id,
  profile,
  profileBgImage,
  isCloseFriend,
  openShareProfileDialog,
  ybCardHeight,
  ybCardWidth,
  cardOpen,
  setCardOpen,
  stepIndex,
  setStepIndex,
  run,
  setRun,
}) => {
  let trimmedQuote = '';

  if (profile.quote) {
    trimmedQuote =
      profile.quote.length <= quoteMaxCharLimit
        ? profile.quote
        : profile.quote
            .substring(0, quoteMaxCharLimit)
            .split(' ')
            .slice(0, -1)
            .join(' ') + '...';
  }

  const [quote, setQuote] = useState(trimmedQuote);
  const [isHovered, setIsHovered] = useState(false);
  const [isProfileCloseFriend, setIsProfileCloseFriend] = useState(
    isCloseFriend,
  );
  const cardDivRef = useRef();

  useEffect(() => {
    setIsProfileCloseFriend(isCloseFriend);
  }, [isCloseFriend]);

  const handleMouseEnter = () => {
    setQuote(profile.quote);
    setIsHovered(true);
  };

  const handleMouseOut = (event) => {
    const e = event.toElement || event.relatedTarget;

    if (e.parentNode == cardDivRef.current || e == cardDivRef) {
      return; // stop absolute positioned element to trigger change in quote
    }

    setQuote(trimmedQuote);
    setIsHovered(false);
  };

  const handleAddAsCloseFriend = (event) => {
    event.stopPropagation();
    setIsProfileCloseFriend(true);
    userAPI.addCloseFriend(profile.id).catch((e) => {
      console.log(e);
    });
    ReactGA.event({
      category: 'User',
      action: 'Added someone as close friend',
    });
  };

  const handleRemoveAsCloseFriend = (event) => {
    event.stopPropagation();
    setIsProfileCloseFriend(false);
    userAPI.removeCloseFriend(profile.id).catch((e) => {
      console.log(e);
    });
    ReactGA.event({
      category: 'User',
      action: 'Removed someone as close friend',
    });
  };

  const handleShareProfileClick = async (event) => {
    event.stopPropagation();
    if (isDeviceMobile()) {
      const shareData = {
        title: 'GradGoggles',
        text: 'Checkout this profile!',
        url: `https://gradgoggles.com/yearbook?id=${profile.id}`,
      };
      await navigator.share(shareData);
    } else {
      openShareProfileDialog(profile.id);
    }
    // openShareProfileDialog(profile.id);
  };

  return (
    <div
      className={'yb-card'}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseOut}
      ref={cardDivRef}
      style={
        isDeviceMobile() ? { width: ybCardWidth, height: ybCardHeight } : null
      }
    >
      <UserProfileModal
        id={id}
        profileBgImage={profileBgImage}
        setIsHovered={setIsHovered}
        handleShareProfileClick={handleShareProfileClick}
        isCloseFriend={isProfileCloseFriend}
        handleAddAsCloseFriend={handleAddAsCloseFriend}
        handleRemoveAsCloseFriend={handleRemoveAsCloseFriend}
        setCardOpen={setCardOpen}
        setRun={setRun}
        stepIndex={stepIndex}
        setStepIndex={setStepIndex}
      >
        <div
          className="yb-card-content-container"
          style={isDeviceMobile() ? { width: ybCardWidth } : null}
        >
          <div className="yb-card-photo">
            <img
              src={profile.photo}
              alt={profile.name}
              style={
                isDeviceMobile()
                  ? { width: ybCardWidth, height: ybCardHeight }
                  : null
              }
            />
            <div
              className={`yb-card-bg ${
                isHovered ? 'yb-card-bg-hovered' : null
              }`}
            />
            <div
              className={`yb-card-icons-bg ${
                isHovered ? 'yb-card-icons-bg-hovered' : null
              }`}
            />
            <div className="yb-card-quoname-container">
              <div className="yb-card-user-name">{profile.name}</div>
              <div className="yb-card-user-quote">{quote}</div>
            </div>
            <div
              className={`yb-card-share-star-container ${
                isHovered ? 'icons-visible' : 'icons-hidden'
              }`}
            >
              <div className="yb-card-share">
                <Tooltip title={'Share this profile'}>
                  <ShareIcon
                    className={'yb-card-icon'}
                    onClick={handleShareProfileClick}
                  />
                </Tooltip>
              </div>
              <div className="yb-card-star">
                <Tooltip
                  title={
                    isProfileCloseFriend
                      ? 'Remove as close friend'
                      : 'Add as close friend'
                  }
                >
                  {isProfileCloseFriend ? (
                    <StarIcon
                      className={'yb-card-icon'}
                      onClick={handleRemoveAsCloseFriend}
                    />
                  ) : (
                    <StarBorderIcon
                      className={'yb-card-icon'}
                      onClick={handleAddAsCloseFriend}
                    />
                  )}
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="yb-card-below-quote" />
        </div>
      </UserProfileModal>
    </div>
  );
};

export default YearbookCard;
