import { generateRandomString } from './generateRandomString';
import { apiURL } from './apiURL';

export async function uploadFileToS3(file) {
  let fileName = generateRandomString() + '.jpeg';

  let getSignedS3URL =
    apiURL + 'sign_s3?file_name=' + fileName + '&file_type=' + file.type;

  const res = await fetch(getSignedS3URL, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
    .then((data) => data.json())
    .then((data) => JSON.parse(data))
    .catch((err) => console.log(err));

  return await uploadFile(file, res.data, res.url);
}

async function uploadFile(file, s3Data, url) {
  const postData = new FormData();

  for (let key in s3Data.fields) {
    postData.append(key, s3Data.fields[key]);
  }

  postData.append('file', file);

  let response = await fetch(s3Data.url, {
    method: 'POST',
    body: postData,
  });

  if (!response.ok) {
    console.log('Could not upload file.');
    return null;
  }
  return url;
}

