import React, { Component } from 'react';
import '../../styles/YearbookCardComponent.css';
import FormatQuoteRoundedIcon from '@material-ui/icons/FormatQuoteRounded';
import CakeIcon from '@material-ui/icons/Cake';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import { formatDate } from '../../utils/formatDate';
import { useFormControl } from '@material-ui/core';
import UserProfileModal from './UserProfileModal';

const YearbookCard = (props) => {
  return (
    <UserProfileModal id={props.id} profileBgImage={props.profileBgImage}>
      <div id="photoContainer" className="photoContainer">
        <img
          src={props.user.photo}
          className={`avatarSection`}
          alt={props.user.name}
        />
        <svg
          className="editorial"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28 "
          preserveAspectRatio="none"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0
                    58-18 88-18s
                    58 18 88 18
                    58-18 88-18
                    58 18 88 18
                    v44h-352z"
            />
          </defs>
          <g className="parallax1">
            <use xlinkHref="#gentle-wave" x="50" y="3" fill="#f461c1" />
          </g>
          <g className="parallax2">
            <use xlinkHref="#gentle-wave" x="50" y="0" fill="#4579e2" />
          </g>
          <g className="parallax3">
            <use xlinkHref="#gentle-wave" x="50" y="9" fill="#3461c1" />
          </g>
          <g className="parallax4">
            <use xlinkHref="#gentle-wave" x="50" y="6" fill="#fff" />
          </g>
        </svg>
      </div>
      <div className="infoContainer">
        <div className="nameDeptSection">
          <p className="name">{props.user.name}</p>
          <p className="dept">{props.user.dept}</p>
        </div>
        <div className="quoteSection">
          <FormatQuoteRoundedIcon className="quoteIcon" />
          <p className="quote">{props.user.quote}</p>
        </div>
        <div className="additionalSection">
          <div className="dob">
            <CakeIcon />
            <p className="date">{formatDate(props.user.dob)}</p>
          </div>
          <div className="email">
            <EmailRoundedIcon />
            <p className="address">{props.user.email}</p>
          </div>
        </div>
      </div>
    </UserProfileModal>
  );
};
export default YearbookCard;
