import React, { useEffect, useState } from 'react';
import '../../../styles/LoginSignUp.css';
import {
  Typography,
  TextField,
  Button,
  Snackbar,
  Tooltip,
} from '@material-ui/core';
import { School } from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import 'date-fns';
import { Redirect, Link } from 'react-router-dom';
import { isLoggedIn } from '../../../utils/auth';
import moment from 'moment';
import { apiURL } from '../../../utils/apiURL';
import Login from './Login';
import SignUp1 from './SignUp1';
import SignUp3 from './SignUp3';
import SignUp2 from './SignUp2';
import { getCollegeData } from '../../../utils/api/collegeDataAPI';
import { uploadFileToS3 } from '../../../utils/s3Utils';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SelectCollegeDialogBox from './SelectCollegeDialogBox';
import { useHistory } from 'react-router';

const useStyles = (theme) => ({
  root: {
    [theme.breakpoints.down(769)]: {
      color: 'white',
    },
  },
  logSignButton: {
    color: '#3caea3',
    backgroundColor: '#fff',
    borderColor: '#3caea3',
    borderRadius: 5,
    width: '50%',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#3caea3',
    },
  },
  prevButton: {
    color: '#3caea3',
    backgroundColor: '#fff',
    borderColor: '#3caea3',
    borderRadius: 5,
    width: '40%',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#3caea3',
    },
  },
  nextButton: {
    float: 'right',
    color: '#3caea3',
    backgroundColor: '#fff',
    borderColor: '#3caea3',
    borderRadius: 5,
    width: '40%',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#3caea3',
    },
  },
  iconHandler: {
    cursor: 'pointer',
  },
  forgotPassword: {
    fontSize: '13px',
    cursor: 'pointer',
  },
  signUpText: {
    fontSize: '13px',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  backdrop: {
    zIndex: '1900',
    color: '#fff',
  },
  popUpButton: {
    color: '#3caea3',
  },
});

const LoginSignUpParent = (props) => {
  const [passVisibility, setPassVisibility] = useState(false);
  const [loginForm, setLoginForm] = useState({ email: '', password: '' });
  const [emailErrorHelperText, setEmailHelperText] = useState('');
  const [passwordHelperText, setPasswordHelperText] = useState('');
  const [forgotPassOpen, setForgotPassOpen] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [forgotPasswordHelperText, setForgotPasswordHelperText] = useState('');
  const [verificationOpen, setVerificationOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [loadingBackDropOpen, setLoadingBackDropOpen] = useState(false);
  const [toggleForm, setToggleForm] = useState(true);
  const [signUpState, setSignUpState] = useState(1);
  const [postRegOpen, setPostRegOpen] = useState(false);
  const [samedeptPhoto, setSameDeptPhoto] = useState(false);
  const [signUpForm, setSignUpForm] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',

    dept: '',
    dob: new Date(),
    quote: '',
    photo: null,

    photoData: null,
    dept_photo: null,
    social_web: null,
    social_insta: null,
    social_linkedin: null,
    social_twitter: null,
    batch: '2021',
    isGoogleAuth: false,
  });
  const [signUpHelperText, setSignUpHelperText] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    dept: '',
    dob: '',
    quote: '',
    photo: '',
    dept_photo: '',
    batch: 2021,
  });
  const [signUpHelperTextArray, setSignUpHelperTextArray] = useState({
    email: 'Please Enter a Valid Email',
    password: 'Please Enter a Valid Password',
    firstName: 'Please Enter First Name',
    lastName: 'Please Enter Last Name',
    GRNo: 'Please Enter a Valid GRNo',
    dept: 'Please Enter a Valid Department',
    dob: 'Please Enter a Valid DOB',
    quote: 'Please Enter a Valid Quote',
    photo: 'Please Enter a Valid Photo',
    dept_photo: 'Please Enter a Valid Department Photo',
  });
  const [accessToken, setAccessToken] = useState();
  const [user, setUser] = useState();
  const [collegeData, setCollegeData] = useState();
  const [currentCollege, setCurrentCollege] = useState('vit');
  const [imageDisplayNum, setImageDisplayNum] = useState(0);
  const [
    isSelectCollegeDialogBoxOpen,
    setIsSelectCollegeDialogBoxOpen,
  ] = useState(false);

  useEffect(() => {
    if (props.location.state) {
      setCurrentCollege(props.location.state.selectedCollege);
    }
  }, []);

  useEffect(() => {
    getCollegeData(currentCollege).then((data) => {
      // TODO: change paramter from 'vit' to dyanamic
      setCollegeData(data);
    });
  }, [currentCollege]);

  useEffect(() => {
    if (collegeData) {
      setImageDisplayNum(
        Math.floor(Math.random() * collegeData.backgrounds.length),
      );
    }
  }, [signUpState]);

  const departments = collegeData ? collegeData.departments : null;

  const history = useHistory();

  const resetForms = () => {
    setLoginForm({ email: '', password: '' });
    setSignUpForm({
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      dept: '',
      dob: new Date(),
      quote: '',
      photo: null,
    });
    setSignUpState(1);
  };

  let isVerified;

  const canceledPostReg = () => {
    setPostRegOpen(false);
    if (
      accessToken !== null ||
      accessToken !== undefined ||
      accessToken !== ''
    ) {
      localStorage.setItem('name', user.name);
      localStorage.setItem('photo', user.photo);
      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('isVerified', isVerified);
      localStorage.setItem('id', user.id);
      localStorage.setItem('college', user.college);
      localStorage.setItem('batch', user.batch);

      window.location.replace('/yearbook');
    }
  };
  const cancelContinue = () => {
    setVerificationOpen(false);
    if (
      accessToken !== null ||
      accessToken !== undefined ||
      accessToken !== ''
    ) {
      localStorage.setItem('name', user.name);
      localStorage.setItem('photo', user.photo);
      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('isVerified', user.isVerified);
      localStorage.setItem('id', user.id);
      localStorage.setItem('college', user.college);
      localStorage.setItem('batch', user.batch);

      window.location.replace('/yearbook');
    }
  };
  const signUpSubmit = () => {
    let flagInput = false;
    let listErrors = '';
    let url = apiURL + 'register';
    let formData = new FormData();
    let data = signUpForm;

    if (!moment(signUpForm.dob).isValid()) {
      flagInput = true;
    }
    for (let name in data) {
      var value = data[name];

      if (
        name === 'photoData' ||
        name === 'social_web' ||
        name === 'social_insta' ||
        name === 'social_linkedin' ||
        name === 'social_twitter'
      ) {
        continue;
      }
      var helperText = signUpHelperTextArray[name];
      if (value === '' || value === null || value === undefined) {
        flagInput = true;
        setSignUpHelperText({ ...signUpHelperText, [name]: helperText });
      }
    }
    if (flagInput) {
      setAlertMessage('Some Fields Are Missing!');
      setAlertSeverity('error');
      setAlertOpen(true);
    } else {
      setLoadingBackDropOpen(true);
      for (let name in data) {
        var value = data[name];
        if (name === 'dob') {
          value = moment(value);
          let year = value.year();
          let month = value.month() + 1;
          let date = value.date();
          let monthString = month,
            datestring = date;
          if (month < 10) {
            monthString = `0${month}`;
          }
          if (date < 10) {
            datestring = `0${date}`;
          }
          let dateString = `${datestring}-${monthString}-${year}`;
          value = dateString;
        }

        if (name === 'photo' || name === 'dept_photo') {
          value = value.replace(
            'gradgoggles-images-uploaded.s3.amazonaws.com',
            'd1577j21c01vef.cloudfront.net/800',
          );
        }
        if (name === 'social_insta' && value) {
          if (!value.includes('instagram')) {
            value = 'https://www.instagram.com/' + value.trim();
          }
        }
        if (name === 'social_twitter' && value) {
          if (!value.includes('twitter')) {
            value = 'https://twitter.com/' + value.trim();
          }
        }

        if (name === 'social_linkedin' && value) {
          if (!value.includes('linkedin')) {
            value = 'https://www.linkedin.com/in/' + value.trim();
          }
        }

        if (name === 'social_web' && value) {
          value = value.trim();
        }

        formData.append(name, value);
      }
      const fName = formData.get('firstName');

      const lName = formData.get('lastName');

      formData.delete('firstName');
      formData.delete('lastName');
      const fullName = fName.trim() + ' ' + lName.trim();
      formData.append('fullName', fullName);
      formData.append('college', currentCollege);

      fetch(url, {
        method: 'POST',
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error !== 'none') {
            setLoadingBackDropOpen(false);
            setAlertMessage(data.error);
            setAlertSeverity('error');
            setAlertOpen(true);
          } else {
            if (
              data.access_token !== null &&
              data.access_token !== 'undefined' &&
              data.access_token !== undefined
            ) {
              resetForms();
              let user = {
                id: data.id,
                name: data.name,
                photo: data.photo,
                isVerified: data.isVerified,
                college: data.college,
                batch: data.batch,
              }; //Add isVerified Here
              setUser(user);

              setAccessToken(data.access_token);
              localStorage.setItem('isFirstTime', 'yes'); // isFirstTime only while signing up ( the verification dialog box is common for login and signup )
              if (!signUpForm.isGoogleAuth) {
                setPostRegOpen(true);
              } else {
                localStorage.setItem('name', data.name);
                localStorage.setItem('photo', data.photo);
                localStorage.setItem('access_token', data.access_token);
                localStorage.setItem('isVerified', 'true');
                localStorage.setItem('id', data.id);
                localStorage.setItem('college', data.college);
                localStorage.setItem('batch', data.batch);
                window.location.replace('/yearbook');
              }
              setLoadingBackDropOpen(false);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handlePhotoChange = async (event) => {
    if (
      event.target.files[0] &&
      event.target.files[0].name.match(/\.(jpg|jpeg|png|)$/)
    ) {
      let imageURL = null;
      setLoadingBackDropOpen(true);
      imageURL = await uploadFileToS3(event.target.files[0]);
      setLoadingBackDropOpen(false);
      setSignUpForm({
        ...signUpForm,
        photo: imageURL.replace(
          'd1577j21c01vef.cloudfront.net/800',
          'gradgoggles-images-uploaded.s3.amazonaws.com',
        ),
      });
    } else {
      alert('Please upload a valid file');
    }
  };

  const handleCheckBox = (same) => {
    if (same) {
      setSignUpForm({ ...signUpForm, dept_photo: signUpForm.photo });
    } else {
      setSignUpForm({ ...signUpForm, dept_photo: null });
    }
    setSameDeptPhoto(same);
  };
  const handleDeptPhotoChange = async (event) => {
    if (
      event.target.files[0] &&
      event.target.files[0].name.match(/\.(jpg|jpeg|png|)$/)
    ) {
      let imageURL = null;
      setLoadingBackDropOpen(true);
      imageURL = await uploadFileToS3(event.target.files[0]);
      setLoadingBackDropOpen(false);

      setSignUpForm({
        ...signUpForm,
        dept_photo: imageURL.replace(
          'd1577j21c01vef.cloudfront.net/800',
          'gradgoggles-images-uploaded.s3.amazonaws.com',
        ),
      });
    } else {
      alert('Please upload a valid file');
    }
  };
  const handlePhotoUpload = () => {
    document.getElementById('hiddenPhotoInput').click();
  };
  const handleDeptUpload = () => {
    if (!samedeptPhoto) {
      document.getElementById('hiddenDeptInput').click();
    }
  };

  const handleDob = (e) => {
    setSignUpForm({ ...signUpForm, dob: e.target.value });
  };

  const toggleform = () => {
    if (!toggleForm) {
      return (
        <Login
          signUpHelperText={signUpHelperText}
          handleLoginChange={handleLoginChange}
          signUpForm={signUpForm}
          passVisibility={passVisibility}
          setPassVisibility={setPassVisibility}
          setSignUpState={setSignUpState}
          setToggleForm={setToggleForm}
          loginForm={loginForm}
          loginSubmit={loginSubmit}
          emailErrorHelperText={emailErrorHelperText}
          passwordHelperText={passwordHelperText}
          setForgotPassOpen={setForgotPassOpen}
          setAlertOpen={setAlertOpen}
          setAlertMessage={setAlertMessage}
          setAlertSeverity={setAlertSeverity}
          setSignUpForm={setSignUpForm}
        />
      );
    } else {
      if (signUpState === 1) {
        return (
          <SignUp1
            signUpHelperText={signUpHelperText}
            handleSignUpChange={handleSignUpChange}
            signUpForm={signUpForm}
            passVisibility={passVisibility}
            setPassVisibility={setPassVisibility}
            setSignUpState={setSignUpState}
            setToggleForm={setToggleForm}
            setAlertOpen={setAlertOpen}
            setAlertMessage={setAlertMessage}
            setAlertSeverity={setAlertSeverity}
            setSignUpForm={setSignUpForm}
            collegeId={currentCollege ? currentCollege : null}
          />
        );
      } else if (signUpState === 2) {
        return (
          <SignUp2
            signUpHelperText={signUpHelperText}
            handleSignUpChange={handleSignUpChange}
            signUpForm={signUpForm}
            setSignUpState={setSignUpState}
            handleCheckBox={handleCheckBox}
            departments={departments ? departments : null}
            collegeId={currentCollege ? currentCollege : null}
            setAlertOpen={setAlertOpen}
            setAlertMessage={setAlertMessage}
            setAlertSeverity={setAlertSeverity}
            handlePhotoUpload={handlePhotoUpload}
            handleDeptUpload={handleDeptUpload}
            sameDeptPhoto={samedeptPhoto}
            setSameDeptPhoto={setSameDeptPhoto}
            handleDeptPhotoChange={handleDeptPhotoChange}
            handlePhotoChange={handlePhotoChange}
          />
        );
      } else if (signUpState === 3) {
        return (
          <SignUp3
            signUpHelperText={signUpHelperText}
            handleSignUpChange={handleSignUpChange}
            signUpForm={signUpForm}
            setSignUpState={setSignUpState}
            signUpSubmit={signUpSubmit}
            handleDob={handleDob}
            setAlertOpen={setAlertOpen}
            setAlertMessage={setAlertMessage}
            setAlertSeverity={setAlertSeverity}
          />
        );
      }
    }
  };

  const sendVerification = async () => {
    if (postRegOpen) {
      setVerificationOpen(false);
      setLoadingBackDropOpen(true);
      setPostRegOpen(false);
      const url = apiURL + 'resend_email';
      let sendVerificationPromise = await fetch(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => res.json())
        .then((resendData) => {
          setLoadingBackDropOpen(false);
          let Message, Severity;
          if (resendData !== null && resendData.msg == 'success') {
            Message = 'Verification Mail Sent Successfully!';
            Severity = 'success';
          } else {
            Message = 'Verification Mail Failure! Contact Administrator.';
            Severity = 'error';
          }
          setAccessToken('');
          setAlertMessage(Message);
          setAlertSeverity(Severity);
          setAlertOpen(true);
          if (
            accessToken !== null ||
            accessToken !== undefined ||
            accessToken !== ''
          ) {
            localStorage.setItem('name', user.name);
            localStorage.setItem('photo', user.photo);
            localStorage.setItem('access_token', accessToken);
            localStorage.setItem('isVerified', user.isVerified);
            localStorage.setItem('id', user.id);
            localStorage.setItem('college', user.college);
            localStorage.setItem('batch', user.batch);
            window.location.replace('/yearbook');
          }
        });
    } else {
      setVerificationOpen(false);
      setLoadingBackDropOpen(true);
      setPostRegOpen(false);
      const url = apiURL + 'resend_email';
      let sendVerificationPromise = await fetch(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => res.json())
        .then((resendData) => {
          setLoadingBackDropOpen(false);
          let Message, Severity;
          if (resendData !== null && resendData.msg == 'success') {
            Message = 'Verification Mail Sent Successfully!';
            Severity = 'success';
          } else {
            Message = 'Verification Mail Failure! Contact Administrator.';
            Severity = 'error';
          }
          setAlertMessage(Message);
          setAlertSeverity(Severity);
          setAlertOpen(true);
          if (
            accessToken !== null ||
            accessToken !== undefined ||
            accessToken !== ''
          ) {
            localStorage.setItem('name', user.name);
            localStorage.setItem('photo', user.photo);
            localStorage.setItem('access_token', accessToken);
            localStorage.setItem('isVerified', user.isVerified);
            localStorage.setItem('id', user.id);
            localStorage.setItem('college', user.college);
            localStorage.setItem('batch', user.batch);
            window.location.replace('/yearbook');
          }
        });
    }
  };
  const checkVerificationAndLogin = async (data) => {
    if (!(data.access_token != null)) {
      return;
    }
    const url = apiURL + 'check_verification';
    await fetch(url, {
      headers: {
        Authorization: `Bearer ${data.access_token}`,
      },
    })
      .then((res) => res.json())
      .then((checkData) => {
        setLoadingBackDropOpen(false);
        if (checkData.msg === 'yes') {
          setLoginForm({ email: '', password: '' });
          localStorage.setItem('access_token', data.access_token);
          localStorage.setItem('name', data.name);
          localStorage.setItem('photo', data.photo);
          localStorage.setItem('isVerified', data.isVerified);
          localStorage.setItem('id', data.id);
          localStorage.setItem('college', data.college); // TODO: change this to user's college
          localStorage.setItem('batch', data.batch);
          if (
            localStorage.getItem('access_token') !== null &&
            localStorage.getItem('access_token') !== 'undefined'
          ) {
            window.location.replace('/yearbook');
          } else {
            let Message, Severity;
            Message = 'Wrong Email/Password';
            Severity = 'error';
            setAlertMessage(Message);
            setAlertSeverity(Severity);
            setAlertOpen(true);
          }
        } else {
          setVerificationOpen(true);
          setAccessToken(data.access_token);
        }
      })
      .catch((err) => console.log(err));
  };
  const forgotPasswordSubmit = () => {
    if (forgotPasswordEmail === '') {
      setForgotPasswordHelperText('Enter a valid email address. ');
    } else {
      setForgotPassOpen(false);
      setLoadingBackDropOpen(true);
      const url = apiURL + 'forgotPasswordSendMail';
      let formData = new FormData();
      formData.append('email', forgotPasswordEmail);

      fetch(url, {
        method: 'POST',
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          setLoadingBackDropOpen(false);
          let Message, Severity;
          if (data.msg === 'email sent') {
            Message = 'Check email for reset link!';
            Severity = 'success';
          } else {
            Message = 'Unable to send mail! Contact Administrator.';
            Severity = 'error';
          }
          setForgotPasswordEmail('');
          setForgotPasswordHelperText('');
          setAlertMessage(Message);
          setAlertSeverity(Severity);
          setAlertOpen(true);
        })
        .catch((err) => console.log(err));
    }
  };

  const forgotPasswordChange = (event) => {
    let value = event.target.value;
    setForgotPasswordEmail(value);

    if (value !== '') {
      setForgotPasswordHelperText('');
    }
  };

  const handleLoginChange = (event) => {
    const nameOfField = event.target.name;
    const value = event.target.value;
    setLoginForm({ ...loginForm, [nameOfField]: value });

    if (nameOfField === 'email' && value !== '') {
      setEmailHelperText('');
    } else if (nameOfField === 'password' && value !== '') {
      setPasswordHelperText('');
    }
  };

  const handleSignUpChange = (event) => {
    const nameOfField = event.target.name;
    const value = event.target.value;
    const helperText = signUpHelperTextArray[nameOfField];
    setSignUpForm({ ...signUpForm, [nameOfField]: value });

    if (value !== '') {
      setSignUpHelperText({ ...signUpHelperText, [nameOfField]: '' });
    }
  };

  const loginSubmit = async () => {
    if (loginForm.email === '') {
      setEmailHelperText('Please Enter A Valid Email');
    }

    if (loginForm.password === '') {
      setPasswordHelperText('Please Enter A Password');
    }

    if (loginForm.email === '' || loginForm.password === '') {
      return;
    } else {
      setLoadingBackDropOpen(true);
      let url = apiURL + 'login';
      let formData = new FormData();

      formData.append('email', loginForm.email);
      formData.append('password', loginForm.password);

      fetch(url, {
        method: 'POST',
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error !== null && data.error !== 'none') {
            setLoadingBackDropOpen(false);
            let Message, Severity;
            Message = 'Wrong Email/Password';
            Severity = 'error';
            setAlertMessage(Message);
            setAlertSeverity(Severity);
            setAlertOpen(true);
          } else {
            let user = {
              id: data.id,
              name: data.name,
              photo: data.photo,
              isVerified: data.isVerified,
              college: data.college,
              batch: data.batch,
            };
            setUser(user);

            setAccessToken(data.access_token);
            let checkVerificationPromise = checkVerificationAndLogin(data);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const forgotClose = () => {
    setForgotPassOpen(false);
  };

  let loadingBackDrop = (
    <Backdrop className={props.classes.backdrop} open={loadingBackDropOpen}>
      <School className="loadingSchool" />
    </Backdrop>
  );

  let dialogBox = (
    <Dialog
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      open={forgotPassOpen}
      onClose={forgotClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Forgot your Password?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          No worries, happens to all of us. We'll hook you up with a new one in
          no time. Drop your email here.
        </DialogContentText>
        <TextField
          margin="dense"
          id="name"
          label="Email Address"
          type="email"
          value={forgotPasswordEmail}
          onChange={forgotPasswordChange}
          fullWidth
          error={forgotPasswordHelperText === '' ? false : true}
          helperText={forgotPasswordHelperText}
        />
      </DialogContent>
      <DialogActions>
        <Button
          className={props.classes.popUpButton}
          onClick={forgotClose}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          className={props.classes.popUpButton}
          onClick={forgotPasswordSubmit}
          color="primary"
        >
          Send Mail
        </Button>
      </DialogActions>
    </Dialog>
  );

  let verificationAlertDialog = (
    <Dialog
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      open={verificationOpen}
      onClose={() => {
        setVerificationOpen(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {'Verification Pending'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Oops, looks like somebody hasn't verified their account. Click on
          "Verify Me" to send the verification email again.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className={props.classes.popUpButton}
          onClick={cancelContinue}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          className={props.classes.popUpButton}
          onClick={sendVerification}
          color="primary"
          autoFocus
        >
          Verify Me
        </Button>
      </DialogActions>
    </Dialog>
  );
  let postRegDialog = (
    <Dialog
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      open={postRegOpen}
      onClose={() => {
        setPostRegOpen(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Welcome Aboard.'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          A very warm welcome to you from Team GradGoggles. Please verify your
          account with the link sent on your email. If you haven't received one,
          please click Resend Email.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className={props.classes.popUpButton}
          onClick={sendVerification}
          color="primary"
          autoFocus
        >
          Resend Email
        </Button>
        <Button
          className={props.classes.popUpButton}
          onClick={canceledPostReg}
          color="primary"
        >
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );

  let snackBar = (
    <Snackbar
      open={alertOpen}
      autoHideDuration={6000}
      onClose={() => {
        setAlertOpen(false);
      }}
    >
      <Alert
        onClose={() => {
          setAlertOpen(false);
        }}
        severity={alertSeverity}
      >
        {alertMessage}
      </Alert>
    </Snackbar>
  );

  if (isLoggedIn()) {
    return <Redirect to={{ pathname: '/yearbook' }} />;
  } else {
    return (
      <>
        <section className="loginSignUpHolder">
          <div className="joinContainer">
            {collegeData ? (
              <>
                {collegeData.backgrounds.map((bg, i) => (
                  <img
                    alt={'gradgoggles login background'}
                    key={bg}
                    src={bg}
                    className="login-bg"
                    style={
                      i === imageDisplayNum
                        ? { opacity: '100%' }
                        : { opacity: '0' }
                    }
                  />
                ))}
              </>
            ) : (
              <>
                <img
                  alt={'gradgoggles login background'}
                  src={
                    'https://d1577j21c01vef.cloudfront.net/backgrounds/bits_goa/bg_0.jpg'
                  }
                  className={'login-bg'}
                />
              </>
            )}
            <div className="formHalf">
              <div className="joinHeading">
                <Link to={'/'}>
                  <div className="logoName">
                    <img
                      src="https://d1577j21c01vef.cloudfront.net/logo_with_shadow.png"
                      width="45"
                      height="45"
                      alt="gg_logo"
                    />
                    <Typography
                      style={{
                        color: 'white',
                        paddingLeft: '1%',
                        fontSize: '1.25em',
                        fontWeight: '500',
                        fontFamily: "'Inter', sans-seri",
                        alignSelf: 'center',
                      }}
                    >
                      GradGoggles
                    </Typography>
                  </div>
                </Link>
                <ArrowBackIcon
                  style={{
                    color: 'white',
                  }}
                  onClick={() => {
                    signUpState > 1
                      ? setSignUpState(signUpState - 1)
                      : history.push('/');
                  }}
                  className="arrowBackMobile"
                  fontSize="large"
                />
                <Typography className="heading-greet">
                  {toggleForm
                    ? signUpState === 1
                      ? 'Create an account'
                      : signUpState === 2
                      ? 'Set up your profile'
                      : 'Help your friends stay in touch with you'
                    : 'Welcome Back!'}
                </Typography>

                <div>
                  {collegeData ? (
                    <>
                      {collegeData.college_id === 'bits_goa' ? (
                        <div className="auth-college-logo">
                          <img
                            className={'auth-bits-logo'}
                            src={
                              'https://d1577j21c01vef.cloudfront.net/colleges/bits_goa_white.png'
                            }
                            alt={'GradGoggles'}
                          />
                        </div>
                      ) : null}
                    </>
                  ) : null}

                  <Tooltip title={'Change College'}>
                    <Typography
                      variant="h6"
                      className={'auth-college-name'}
                      onClick={() => {
                        setIsSelectCollegeDialogBoxOpen(true);
                      }}
                    >
                      {collegeData
                        ? collegeData.college_fullname
                        : 'Select your College'}
                    </Typography>
                  </Tooltip>
                </div>
              </div>
              <div className="joinBody">
                <div className="joinBodyInner">{toggleform()}</div>
              </div>
            </div>
          </div>
        </section>
        <SelectCollegeDialogBox
          isSelectCollegeDialogBoxOpen={isSelectCollegeDialogBoxOpen}
          setIsSelectCollegeDialogBoxOpen={setIsSelectCollegeDialogBoxOpen}
          setCurrentCollege={setCurrentCollege}
        />
        {dialogBox}
        {verificationAlertDialog}
        {snackBar}
        {loadingBackDrop}
        {postRegDialog}
      </>
    );
  }
};

export default withStyles(useStyles, { withTheme: true })(LoginSignUpParent);
