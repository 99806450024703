import React, { useEffect, useRef, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Tooltip,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import '../../../styles/new/ShareProfileDialog.css';
import IconButton from '@material-ui/core/IconButton';
import { Assignment, Done } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const ShareProfileDialog = ({ handleClose, isDialogOpen, profileID }) => {
  const readOnlyTextInputField = useRef();
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  useEffect(() => {
    setIsLinkCopied(false);
  }, [profileID]);

  const copyLinkToClipboard = () => {
    readOnlyTextInputField.current.select();
    document.execCommand('copy');
    setIsLinkCopied(true);
  };

  return (
    isDialogOpen && (
      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        fullWidth
        className={'share-link-dialog'}
      >
        <DialogTitle onClose={handleClose}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant={'h6'}>Share Profile</Typography>

            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent dividers fullWidth>
          <Typography>
            Use the link below to share this profile. Click to copy.
          </Typography>
          <br />
          <Tooltip
            title={
              isLinkCopied
                ? 'Link copied to clipboard'
                : 'Click to copy link to clipboard'
            }
            PopperProps={{ style: { zIndex: '2000' } }}
          >
            <div className="share-link-box">
              <TextField
                variant={'filled'}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position={'end'}>
                      <IconButton>
                        {isLinkCopied ? (
                          <Done onClick={copyLinkToClipboard} />
                        ) : (
                          <Assignment onClick={copyLinkToClipboard} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                inputRef={readOnlyTextInputField}
                defaultValue={`https://gradgoggles.com/yearbook?id=${profileID}`}
                onClick={copyLinkToClipboard}
              />
            </div>
          </Tooltip>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    )
  );
};

export default ShareProfileDialog;
