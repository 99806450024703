import React, { useEffect, useState } from 'react';
import YearbookHeader from './yearbook-components/new/YearbookHeader';
import Slider from 'react-slick';
import '../styles/new/MyCollegeWall.css';
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import Button from '@material-ui/core/Button';
import { CloudUploadOutlined } from '@material-ui/icons';
import { uploadFileToS3 } from '../utils/s3Utils';
import userAPI from '../utils/api/UserAPI';
import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteOutlined';
import ReactGA from 'react-ga';

const MyCollegeWall = ({ collegeData }) => {
  const [isNewItemDialogBoxOpen, setIsNewItemDialogBoxOpen] = useState(false);
  const [scribbles, setScribbles] = useState(null);

  useEffect(() => {
    userAPI.getMyCWPosts().then((d) => {
      setScribbles(d);
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div>
      <YearbookHeader />
      <div className="mcw-add-btn">
        <div className="mcw-add-btn-child">
          <IconButton
            onClick={() => {
              setIsNewItemDialogBoxOpen(true);
            }}
          >
            <AddOutlinedIcon />
          </IconButton>
        </div>
      </div>
      {collegeData ? (
        <div className="mcw-container">
          <div className="mcw-header">
            <MyCollegeHeaderCarousel
              backgrounds={collegeData.backgrounds}
              college={collegeData.college_id}
            />
            <div className="mcw-header-college">
              <div className="mcw-header-title">COLLEGE WALL</div>
              <div className="mcw-header-clg-name">
                {collegeData.college_fullname}
              </div>
            </div>
          </div>

          <div className="mcw-letter">
            <div className="mcw-letter-content">
              {collegeData.college_text} <br /> <br /> You can use this space to
              write your heart out about{' '}
              <span className={'mcw-letter-semibold'}>
                your most memorable experience
              </span>{' '}
              at {collegeData.college_name}. Take a moment to cherish the
              countless memories. You will move to further pursuits in life, but
              you can always return here to take a trip down the memory lane.
            </div>
          </div>

          {scribbles ? (
            <div className="mcw-scribbles-grid">
              {scribbles.map((scribble) => {
                if (scribble.type === 'text') {
                  return <TextScribble scribble={scribble} key={scribble.id} />;
                } else {
                  return (
                    <ImageScribble scribble={scribble} key={scribble.id} />
                  );
                }
              })}
            </div>
          ) : (
            <>
              <div className="mcw-loading">
                <CircularProgress />
              </div>
            </>
          )}
          <AddNewScribbleDialogBox
            isOpen={isNewItemDialogBoxOpen}
            setIsOpen={setIsNewItemDialogBoxOpen}
            collegeName={collegeData.college_name}
            setScribbles={setScribbles}
          />
        </div>
      ) : (
        <>
          <div className="mcw-loading">
            <CircularProgress />
          </div>
        </>
      )}
    </div>
  );
};

export default MyCollegeWall;

const MyCollegeHeaderCarousel = ({ backgrounds, college }) => {
  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    pauseOnHover: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  let [bgURL1, bgURL2, bgURL3, bgURL4] = backgrounds;

  if (college === 'bits_goa') {
    bgURL1 =
      'https://d1577j21c01vef.cloudfront.net/backgrounds/bits_goa/bg_8.jpg';
    bgURL2 =
      'https://d1577j21c01vef.cloudfront.net/backgrounds/bits_goa/bg_7.jpg';
    bgURL3 =
      'https://d1577j21c01vef.cloudfront.net/backgrounds/bits_goa/bg_11.jpg';
    bgURL4 =
      'https://d1577j21c01vef.cloudfront.net/backgrounds/bits_goa/bg_12.jpg';
  }

  return (
    <Slider {...settings}>
      <div className="mcw-header-carousal-slide">
        <div
          id={'bits-goa-lawn-pic'}
          className="mcw-header-carousal-slide-inner"
          style={{
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bgURL2})`,
          }}
        >
          A HANGOUT
        </div>
      </div>
      <div className="mcw-header-carousal-slide">
        <div
          className="mcw-header-carousal-slide-inner"
          style={{
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bgURL1})`,
          }}
        >
          A HOME
        </div>
      </div>
      <div className="mcw-header-carousal-slide">
        <div
          className="mcw-header-carousal-slide-inner"
          style={{
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bgURL3})`,
          }}
        >
          A STORY
        </div>
      </div>
      <div className="mcw-header-carousal-slide">
        <div
          id={'bits-goa-sac-pic'}
          className="mcw-header-carousal-slide-inner"
          style={{
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bgURL4})`,
          }}
        >
          A FEELING
        </div>
      </div>
    </Slider>
  );
};

const TextScribble = ({ scribble }) => {
  const [hasCurrentUserLiked, setHasCurrentUserLiked] = useState(false);
  const [numOfLikes, setNumOfLikes] = useState(scribble.num_of_likes);

  const handleLikeIconClick = () => {
    if (!hasCurrentUserLiked) {
      setNumOfLikes((prev) => prev + 1);
      setHasCurrentUserLiked(true);
      userAPI.likeMyCWPost(scribble.id).catch((e) => {
        console.log(e);
      });
    } else {
      setNumOfLikes((prev) => prev - 1);
      setHasCurrentUserLiked(false);
      userAPI.unlikeMyCWPost(scribble.id).catch((e) => {
        console.log(e);
      });
    }
  };

  return (
    <div
      className={'mcw-scribble'}
      style={{ width: getDivWidth(1, scribble.content.length) }}
    >
      <div className="mcw-scribble-content">{scribble.content}</div>
      <div className="mcw-scribble-bottom-bar">
        <div className="mcw-scribble-author">{scribble.author}</div>

        <IconButton
          className={'like-icon mcw-scribble-likes'}
          color={hasCurrentUserLiked ? 'secondary' : 'default'}
          onClick={handleLikeIconClick}
        >
          {hasCurrentUserLiked ? (
            <FavoriteOutlinedIcon />
          ) : (
            <FavoriteBorderOutlinedIcon />
          )}
          <div className="mcw-scribble-num-of-likes">{numOfLikes}</div>
        </IconButton>
      </div>
    </div>
  );
};

const ImageScribble = ({ scribble }) => {
  const [hasCurrentUserLiked, setHasCurrentUserLiked] = useState(false);
  const [numOfLikes, setNumOfLikes] = useState(scribble.num_of_likes);

  const handleLikeIconClick = () => {
    if (!hasCurrentUserLiked) {
      setNumOfLikes((prev) => prev + 1);
      setHasCurrentUserLiked(true);
      userAPI.likeMyCWPost(scribble.id).catch((e) => {
        console.log(e);
      });
    } else {
      setNumOfLikes((prev) => prev - 1);
      setHasCurrentUserLiked(false);
      userAPI.unlikeMyCWPost(scribble.id).catch((e) => {
        console.log(e);
      });
    }
  };

  return (
    <div className={'mcw-scribble'}>
      <div className="mcw-scribble-image">
        <img src={scribble.imgURL} alt={scribble.author + "'s scribble"} />
      </div>
      <div className="mcw-scribble-image-details">
        {scribble.content && scribble.content.trim() && (
          <div className="mcw-scribble-caption">{scribble.content}</div>
        )}
        <div className="mcw-scribble-bottom-bar">
          <div className="mcw-scribble-author mcw-scribble-image-author">
            {scribble.author}
          </div>
          <IconButton
            className={'like-icon mcw-scribble-likes'}
            color={hasCurrentUserLiked ? 'secondary' : 'default'}
            onClick={handleLikeIconClick}
          >
            {hasCurrentUserLiked ? (
              <FavoriteOutlinedIcon />
            ) : (
              <FavoriteBorderOutlinedIcon />
            )}
            <div className="mcw-scribble-num-of-likes">{numOfLikes}</div>
          </IconButton>
        </div>
      </div>
    </div>
  );
};

const AddNewScribbleDialogBox = ({
  isOpen,
  setIsOpen,
  collegeName,
  setScribbles,
}) => {
  const [scribbleContent, setScribbleContent] = useState('');
  const [scribbleImage, setScribbleImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    if (scribbleContent.trim() || scribbleImage) {
      setIsLoading(true);
      let imageURL = null;
      const newScribbleType = scribbleImage ? 'image' : 'text';

      if (scribbleImage) {
        imageURL = await uploadFileToS3(scribbleImage);
      }

      const newCWPost = await userAPI.addMyCWPost(
        newScribbleType,
        scribbleContent,
        imageURL,
      );

      const newScribble = {
        id: newCWPost.id,
        type: newScribbleType,
        content: scribbleContent,
        imgURL: imageURL
          ? imageURL.replace(
              'd1577j21c01vef.cloudfront.net/800',
              'gradgoggles-images-uploaded.s3.amazonaws.com',
            )
          : null,
        author: localStorage.getItem('name'),
        author_id: +localStorage.getItem('id'),
        num_of_likes: 0,
      };

      setScribbles((prevState) => [newScribble, ...prevState]);

      setScribbleImage(null);
      setScribbleContent('');
      setIsLoading(false);
      setIsOpen(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleFileUpload = (e) => {
    if (
      e.target.files[0] &&
      e.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)
    ) {
      setScribbleImage(e.target.files[0]);
    } else {
      alert('Please upload a valid file');
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth={true}>
      <DialogTitle onClose={handleClose}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant={'h6'}>Add to the College Wall</Typography>

          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        <div>
          Use this space to write about your most memorable experience at{' '}
          {collegeName}. You can also add a photo to go along with your note!
        </div>
        <TextField
          onChange={(e) => {
            setScribbleContent(e.target.value);
          }}
          multiline={true}
          rows={4}
          variant={'outlined'}
          placeholder={'Write about your most memorable experience here'}
          style={{ width: '100%', marginTop: '15px' }}
        />

        <Button
          variant={'outlined'}
          component={'label'}
          startIcon={<CloudUploadOutlined />}
          style={{ marginTop: '15px' }}
        >
          <span style={{ textTransform: 'none' }}>
            {scribbleImage ? (
              <>{scribbleImage.name}</>
            ) : (
              <>
                Add a photo{' '}
                <span style={{ fontWeight: '400' }}>(Optional)</span>
              </>
            )}
          </span>
          <input type={'file'} hidden onChange={handleFileUpload} />
        </Button>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleSubmit}>Submit</Button>
      </DialogActions>
      <Backdrop
        open={isLoading}
        style={{ zIndex: '1000' }}
        className={'mcw-dialog-backdrop'}
      >
        <CircularProgress style={{ zIndex: '1000', color: 'white' }} />
        <div style={{ color: 'white' }}>
          Hang on a second, we are posting your note ...
        </div>
      </Backdrop>
    </Dialog>
  );
};

const getDivWidth = (type, contentLength) => {
  if (contentLength < 50) {
    return '200px';
  } else if (contentLength < 150) {
    return '400px';
  } else if (contentLength < 250) {
    return '450px';
  } else if (contentLength < 350) {
    return '600px';
  } else {
    return 'auto';
  }
};
