import React, { useCallback, useEffect, useRef, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {
  Avatar,
  CircularProgress,
  InputAdornment,
  Menu,
  MenuItem,
  Snackbar,
  Tooltip,
} from '@material-ui/core';
import '../styles/new/ProfilePageComponent.css';
import '../styles/new/CurrentUserProfilePage.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import 'date-fns';
import Select from '@material-ui/core/Select';
import DateFnsUtils from '@date-io/date-fns';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import userAPI from '../utils/api/UserAPI';
import Backdrop from '@material-ui/core/Backdrop';
import {
  EditOutlined,
  MoreVertOutlined,
  School,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import CurrentUserProfileStyles from '../styles/mui-theme-styles/CurrentUserProfile';
import { apiURL } from '../utils/apiURL';
import { uploadFileToS3 } from '../utils/s3Utils';
import { timeDiff } from '../utils/timeDiff';
import { formatDate } from '../utils/formatDate';
import YearbookHeader from './yearbook-components/new/YearbookHeader';
import ScrapSectionSkeleton from './yearbook-components/UserProfileModalScrapSectionSkeleton';
import FormatQuoteOutlinedIcon from '@material-ui/icons/FormatQuoteOutlined';
import CakeOutlinedIcon from '@material-ui/icons/CakeOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import LanguageIcon from '@material-ui/icons/Language';
import { isDeviceMobile } from '../utils/yearbookCardDimensionsUtils';
import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import ReactGA from 'react-ga';

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const CurrentUserProfile = (props) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [editOpen, setEditOpen] = useState(false);
  const [dateEx, setDateEx] = useState(new Date());
  const [freezeDialog, setFreezeDialog] = useState(false);
  const [loadingBackDropOpen, setLoadingBackDropOpen] = useState(true);
  const [changePass, setChangePass] = useState({ current: '', new: '' });
  const [changePassHelperText, setChangePassHelperText] = useState({
    current: '',
    new: '',
  });
  const [saveUserHelperText, setSaveUserHelperText] = useState({
    name: '',
    quote: '',
  });
  const [passVisibility, setPassVisibility] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [Scraps, setScraps] = useState(null);
  const [user, setUser] = useState();
  let history = useHistory();

  const departments = props.collegeData ? props.collegeData.departments : null;

  const isValid = (date) => {
    let d = new Date(date);
    return isNaN(d);
  };

  const validateUser = () => {
    let currentDob = moment(user.dob);
    let currentMoment = moment();
    if (currentDob === null || !currentDob.isValid()) {
      console.log(currentDob.isValid());
      return false;
    }

    if (currentMoment.diff(currentDob) < 0) {
      console.log(currentMoment.diff(currentDob));
      return false;
    }

    let data = user;
    let flagInput = true;

    for (let name in data) {
      let value = data[name];
      if (
        value === null ||
        value === '' ||
        value === undefined ||
        value === 'Invalid Date Format'
      ) {
        if (name === 'dob') {
          continue;
        } else if (name === 'name') {
          setSaveUserHelperText({ name: 'Name cant be blank' });
          flagInput = false;
        } else if (name === 'quote' && !localStorage.getItem('is2020')) {
          setSaveUserHelperText({ quote: "Quote can't be blank" });
          flagInput = false;
        } else if (name === 'dept') {
          flagInput = false;
        }
      }
    }
    return flagInput;
  };

  const handleEditPassword = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    /**  this.setState((prevState) => ({
      changePass: {
        ...prevState.changePass,
        [name]: value,
      },
    }));
    */
    setChangePass((prevPass) => ({
      changePass: {
        ...prevPass.changePass,
        [name]: value,
      },
    }));

    if (value !== '') {
      /**  this.setState((prevState) => ({
        changePassHelperText: {
          ...prevState.changePassHelperText,
          [name]: '',
        },
      })); */
      setChangePassHelperText((prevHelperText) => ({
        changePassHelperText: {
          ...prevHelperText.changePassHelperText,
          [name]: '',
        },
      }));
    }
  };

  const changePassword = () => {
    let flagInput = false;
    if (
      changePass.current === null ||
      changePass.current === '' ||
      changePass.current === undefined
    ) {
      flagInput = true;
      /**   this.setState((prevState) => ({
        changePassHelperText: {
          ...prevState.changePassHelperText,
          current: 'Enter your Current Password',
        },
      }));
      */
      setChangePassHelperText((prevHelperText) => ({
        changePassHelperText: {
          ...prevHelperText.changePassHelperText,
          current: 'Enter your Current Password',
        },
      }));
    }

    if (
      changePass.new === undefined ||
      changePass.new === '' ||
      changePass.new === null
    ) {
      flagInput = true;
      /**   this.setState((prevState) => ({
        changePassHelperText: {
          ...prevState.changePassHelperText,
          new: 'Enter a New Password',
        },
      }));
      */
      setChangePassHelperText((prevHelperText) => ({
        changePassHelperText: {
          ...prevHelperText.changePassHelperText,
          new: 'Enter a New Password',
        },
      }));
    }

    if (flagInput) {
    } else {
      setLoadingBackDropOpen(true);

      let formData = new FormData();
      let changePwdURL = apiURL + 'change_password';

      formData.append('current_password', changePass.current);
      formData.append('new_password', changePass.new);
      fetch(changePwdURL, {
        headers: {
          Authorization: `Bearer ${localStorage.access_token}`,
        },
        method: 'POST',
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          setChangePass({ current: '', new: '' });
          setLoadingBackDropOpen(false);
          if (data.msg === 'Password Changed') {
            setAlertMessage(data.msg);
            setAlertSeverity('success');
          } else {
            setAlertMessage(data.msg);
            setAlertSeverity('error');
          }
          setAlertOpen(true);
        });
    }
  };

  useEffect(() => {
    setLoadingBackDropOpen(true);
    userAPI
      .getCurrentUser()
      .then((res) => res.json())
      .then((data) => {
        setUser({ ...data, dob: moment(data.dob) });
        setLoadingBackDropOpen(false);

        userAPI
          .getScraps()
          .then((data) => data.json())
          .then((scraps) => setScraps(scraps.scraps));
      });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const handleUpload = () => {
    document.getElementById('hiddenInputEdit').click();
  };

  const handleDeptPhotoUpload = () => {
    document.getElementById('hiddenDeptPhotoInputEdit').click();
  };

  const handleScrapDelete = async (id) => {
    setFreezeDialog(true);
    const index = Scraps.findIndex((x) => x.id === id);
    const newArray = Scraps.slice(0, index).concat(Scraps.slice(index + 1));
    setScraps(newArray);
    await userAPI.deleteScrap(id);
    setFreezeDialog(false);
  };

  const handleScrapVisibility = async (id, callback) => {
    setFreezeDialog(true);
    let index = Scraps.findIndex((x) => x.id === id);
    let person = Scraps[index];
    let visible = Scraps[index].visibility;
    person.visibility = !visible;
    let newArray = Scraps.slice(0, index).concat(person);
    newArray = newArray.concat(Scraps.slice(index + 1));
    setScraps(newArray);
    await userAPI.toggleScrapVisibility(person.id);
    setFreezeDialog(false);
    if (callback) {
      callback(false);
    }
  };

  const handleEditChange = (event) => {
    const nameOfField = event.target.name;
    let value = event.target.value;

    if (nameOfField.includes('social') && value.trim() === '') {
      value = 'null';
    }

    setUser({ ...user, [nameOfField]: value });
  };

  const handleEditChangeOfPhoto = async (event) => {
    if (
      event.target.files[0] &&
      event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)
    ) {
      let file = event.target.files[0];
      setLoadingBackDropOpen(true);
      const url = await uploadFileToS3(file);
      setLoadingBackDropOpen(false);
      setUser({
        ...user,
        photo: url.replace(
          'd1577j21c01vef.cloudfront.net/800',
          'gradgoggles-images-uploaded.s3.amazonaws.com',
        ),
      });
    } else {
      alert('Please select a valid photo');
    }
  };

  const handleEditChangeOfDeptPhoto = async (event) => {
    if (
      event.target.files[0] &&
      event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)
    ) {
      let file = event.target.files[0];
      setLoadingBackDropOpen(true);
      const url = await uploadFileToS3(file);
      setLoadingBackDropOpen(false);
      setUser({
        ...user,
        dept_photo: url.replace(
          'd1577j21c01vef.cloudfront.net/800',
          'gradgoggles-images-uploaded.s3.amazonaws.com',
        ),
      });
    } else {
      alert('Please select a valid photo');
    }
  };

  const handleDob = (date) => {
    setUser({ ...user, dob: date });
    setDateEx(date);
  };

  const handleOtherUserClick = (id) => {
    history.push(`/yearbook?id=${id}`);
  };

  const handleClickOpen = () => {
    setEditOpen(true);
  };

  const validateUserProfileLinks = () => {
    if (user.social_insta && user.social_insta !== 'null') {
      if (
        !user.social_insta.includes('http') ||
        !user.social_insta.includes('://')
      ) {
        setAlertSeverity('error');
        setAlertMessage(
          'Instagram Profile URL is invalid. Make sure it starts with http:// or https://',
        );
        return false;
      }
    }
    if (user.social_linkedin && user.social_linkedin !== 'null') {
      if (
        !user.social_linkedin.includes('http') ||
        !user.social_linkedin.includes('://')
      ) {
        setAlertSeverity('error');
        setAlertMessage(
          'LinkedIn Profile URL is invalid. Make sure it starts with http:// or https://',
        );
        return false;
      }
    }
    if (user.social_twitter && user.social_twitter !== 'null') {
      if (
        !user.social_twitter.includes('http') ||
        !user.social_twitter.includes('://')
      ) {
        setAlertSeverity('error');
        setAlertMessage(
          'Twitter Profile URL is invalid. Make sure it starts with http:// or https://',
        );
        return false;
      }
    }
    if (user.social_web && user.social_web !== 'null') {
      if (
        !user.social_web.includes('http') ||
        !user.social_web.includes('://')
      ) {
        setAlertSeverity('error');
        setAlertMessage(
          '"Any other link" URL is invalid. Make sure it starts with http:// or https://',
        );
        return false;
      }
    }
    return true;
  };

  const handleClose = async () => {
    if (!validateUser()) {
      setAlertSeverity('error');
      setAlertMessage("Can't update. One or more fields are missing");
      setAlertOpen(true);
    } else {
      if (validateUserProfileLinks()) {
        setLoadingBackDropOpen(true);
        await userAPI
          .updateCurrentUser(user)
          .then((res) => res.json())
          .then((data) => {
            setLoadingBackDropOpen(false);
            setEditOpen(false);
          });
        userAPI
          .getScraps()
          .then((res) => res.json())
          .then((data) => {
            setScraps(data.scraps);
          })
          .catch((err) => console.log(err));
      } else {
        setAlertOpen(true);
      }
    }
  };

  const updateComment = (event) => {
    event.preventDefault();
    let newPerson = {};
    setScraps(Scraps.concat(newPerson));
  };
  let loadingBackDrop = (
    <Backdrop className={props.classes.backdrop} open={loadingBackDropOpen}>
      <School className="loadingSchool" />
    </Backdrop>
  );

  let snackBar = (
    <Snackbar
      open={alertOpen}
      className={props.classes.alertBox}
      autoHideDuration={6000}
      onClose={() => {
        setAlertOpen(false);
      }}
    >
      <Alert
        onClose={() => {
          setAlertOpen(false);
        }}
        severity={alertSeverity}
      >
        {alertMessage}
      </Alert>
    </Snackbar>
  );

  const LoadingBackDrop = () => (
    <Backdrop className={props.classes.backdrop} open={loadingBackDropOpen}>
      <School className="loadingSchool" />
    </Backdrop>
  );

  /**   return (
    <>
      {!User ? (
        <LoadingBackDrop />
      ) : (
        <UserProfileModal
          id={id}
          profileBgImage={props.collegeData?.profile_bg}
          open={true}
        />
      )}
    </>
  );
  */

  if (!user) {
    return (
      <Backdrop className={props.classes.backdrop} open={true}>
        <School className="loadingSchool" />
      </Backdrop>
    );
  } else {
    const is2020 = localStorage.getItem('is2020') === 'false';

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {loadingBackDrop}
        {snackBar}
        {editOpen ? (
          <div fullScreen open={true} onClose={handleClose}>
            <AppBar className={props.classes.appBar}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  disabled={freezeDialog}
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={props.classes.title}>
                  Edit Profile
                </Typography>
                <Button
                  disabled={freezeDialog}
                  autoFocus
                  color="inherit"
                  onClick={handleClose}
                >
                  Save
                </Button>
              </Toolbar>
            </AppBar>
            <div>
              <AppBar className={props.classes.downAppBar} position="static">
                <Tabs
                  value={tabIndex}
                  onChange={(event, newValue) => {
                    setTabIndex(newValue);
                  }}
                  variant="fullWidth"
                >
                  <Tab label="Profile" {...a11yProps(0)} />
                  <Tab label="Change Password" {...a11yProps(2)} />
                </Tabs>
              </AppBar>
              <TabPanel value={tabIndex} index={0}>
                <div className="row avatarHolder">
                  <div className="cp-avatar-wrapper">
                    <div className="cp-avatar-label">Yearbook Photo</div>
                    <div className="avatarContainerDiv">
                      <img
                        src={user.photo}
                        className="main-profile-img"
                        onClick={handleUpload}
                      />
                      <EditIcon onClick={handleUpload} className="fa fa-edit" />
                      <input
                        onChange={handleEditChangeOfPhoto}
                        type="file"
                        name="fileName"
                        id="hiddenInputEdit"
                        className="hiddenInputEdit"
                      />
                    </div>
                  </div>
                  <div className="cp-avatar-wrapper">
                    <div className="cp-avatar-label">Dept Photo</div>
                    <div className="avatarContainerDiv">
                      <img
                        src={user.dept_photo ? user.dept_photo : user.photo}
                        onClick={handleDeptPhotoUpload}
                        className="main-profile-img"
                      />
                      <EditIcon
                        onClick={handleDeptPhotoUpload}
                        className="fa fa-edit"
                      />
                      <input
                        onChange={handleEditChangeOfDeptPhoto}
                        type="file"
                        name="fileName"
                        id="hiddenDeptPhotoInputEdit"
                        className="hiddenInputEdit"
                      />
                    </div>
                  </div>
                </div>
                <div className="editProfileTextFieldsContainer">
                  <div className="row formHolder">
                    <div className="col-md-12 col-lg-6 rowOneHolder">
                      <TextField
                        disabled={true}
                        value={user.email}
                        onChange={handleEditChange}
                        name="email"
                        className="editEmail editProfileTextField"
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                      />
                      <TextField
                        value={user.name}
                        onChange={handleEditChange}
                        name="name"
                        className="editPassword editProfileTextField"
                        id="outlined-basic"
                        label="Full Name"
                        variant="outlined"
                      />
                    </div>
                  </div>
                  <div className="row formHolder">
                    <div className="col-md-12 col-lg-6 rowTwoHolder">
                      <FormControl className="selectDept" variant="outlined">
                        <InputLabel id="demo-simple-select-required-label">
                          Department
                        </InputLabel>
                        <Select
                          value={user.dept}
                          onChange={handleEditChange}
                          labelId="demo-simple-select-required-label"
                          native
                          name="dept"
                          label="Department"
                          className="editSelect"
                        >
                          <option aria-label="None" value="" />
                          {departments
                            ? departments.map((dept) => (
                                <option value={dept} key={dept}>
                                  {dept}
                                </option>
                              ))
                            : null}
                        </Select>
                      </FormControl>

                      <KeyboardDatePicker
                        className="editDatePicker editProfileTextField"
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        disableFuture={true}
                        value={user.dob}
                        onChange={handleDob}
                        id="DOB"
                        label="Date of Birth"
                        allowKeyboardControl={false}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </div>
                  </div>
                  <div className="row formHolderSome">
                    <div className="col-md-12 formHolderSomeBody">
                      <TextField
                        variant={'outlined'}
                        value={user.quote}
                        onChange={handleEditChange}
                        className="textAreaInside"
                        name="quote"
                        label="Your Yearbook Quote"
                        multiline
                      />
                    </div>
                  </div>
                  <div className="row formHolder topSMForm">
                    <div className="formSM">
                      <TextField
                        value={
                          user.social_insta != 'null' ? user.social_insta : ''
                        }
                        onChange={handleEditChange}
                        name="social_insta"
                        className="editSM"
                        id="outlined-basic"
                        label="Instagram Profile"
                        placeholder={'Enter your Instagram profile URL'}
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <InstagramIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                  <div className="row formHolder SMRow">
                    <div className="formSM">
                      <TextField
                        value={
                          user.social_twitter != 'null'
                            ? user.social_twitter
                            : ''
                        }
                        onChange={handleEditChange}
                        name="social_twitter"
                        className="editSM"
                        id="outlined-basic"
                        label="Twitter Profile"
                        placeholder={'Enter your Twitter profile URL'}
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <TwitterIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>

                  <div className="row formHolder">
                    {' '}
                    <div className="formSM">
                      <TextField
                        value={
                          user.social_linkedin != 'null'
                            ? user.social_linkedin
                            : ''
                        }
                        onChange={handleEditChange}
                        name="social_linkedin"
                        className="editSM"
                        id="outlined-basic"
                        label="LinkedIn Profile"
                        placeholder={'Enter your LinkedIn profile URL'}
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LinkedInIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                  <div className="row formHolder SMRow">
                    <div className="formSM">
                      <TextField
                        value={user.social_web != 'null' ? user.social_web : ''}
                        onChange={handleEditChange}
                        name="social_web"
                        className="editSM"
                        id="outlined-basic"
                        label="Any Other Link"
                        placeholder={
                          'Enter any other link you wish to show on your profile'
                        }
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LanguageIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <div className="changePasswordHolder">
                  <div className="currentPasswordHolder">
                    <TextField
                      error={changePassHelperText.current !== ''}
                      helperText={changePassHelperText.current}
                      value={changePass.current}
                      onChange={handleEditPassword}
                      name="current"
                      id="outlined-basic"
                      label="Current Password"
                      type={passVisibility ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            className={props.classes.iconHandler}
                            onClick={() => {
                              setPassVisibility(!passVisibility);
                            }}
                            position="start"
                          >
                            {passVisibility ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                    />
                  </div>
                  <div className="newPasswordHolder">
                    <TextField
                      error={changePassHelperText.new !== ''}
                      helperText={changePassHelperText.new}
                      value={changePass.new}
                      onChange={handleEditPassword}
                      name="new"
                      id="outlined-basic"
                      label="New Password"
                      type={passVisibility ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            className={props.classes.iconHandler}
                            onClick={() => {
                              setPassVisibility(!passVisibility);
                            }}
                            position="start"
                          >
                            {passVisibility ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                    />
                  </div>
                  <div className="changePasswordButtonHolder">
                    <Button
                      className={props.classes.changePassButton}
                      onClick={changePassword}
                      variant="outlined"
                      color="primary"
                      size="medium"
                    >
                      Change Password
                    </Button>
                  </div>
                </div>
              </TabPanel>
            </div>
          </div>
        ) : (
          <>
            <YearbookHeader />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url(${props.collegeData?.mainCollegeBg})`,
              }}
            >
              {isDeviceMobile() ? (
                <>
                  <Card
                    className={
                      'pp-mobile-container current-pp-mobile-container'
                    }
                    style={{ width: '100vw' }}
                  >
                    <div className="pp-mobile-top-bar">
                      <div className="pp-mobile-top-bar-right" />
                      <div className="pp-mobile-top-bar-left">
                        <IconButton
                          onClick={handleClickOpen}
                          style={{ outline: 'none' }}
                        >
                          <EditOutlined />
                        </IconButton>
                      </div>
                    </div>

                    <div className="pp-mobile-user-intro">
                      <Avatar
                        className="pp-mobile-user-photo"
                        src={user.photo}
                        alt={user.name}
                      />

                      <div className="pp-mobile-user-name">{user.name}</div>

                      <div className="pp-mobile-user-dept">{user.dept}</div>

                      <div className="pp-mobile-user-quote">{user.quote}</div>
                    </div>

                    <div className="pp-user-details-tabs pp-mobile-tabs">
                      <MobileDetailsAndScrapsTabs
                        user={user}
                        scraps={Scraps}
                        handleNewUserClick={handleOtherUserClick}
                        handleScrapVisibility={handleScrapVisibility}
                      />
                    </div>
                  </Card>
                </>
              ) : (
                <div
                  className="profilePageCard2 current-pp-card"
                  style={{
                    outline: 'none',
                  }}
                >
                  <Card
                    className={`${props.classes.root} current-pp-min-h`}
                    elevation={0}
                  >
                    <div className={props.classes.cover}>
                      <CardMedia
                        className={props.classes.image}
                        image={props.profileBgImage}
                        height="80vh"
                      />

                      <div className={props.classes.profile}>
                        <div className="pp-lhs-img-wrapper">
                          <img src={user.photo} alt={user.name} />
                        </div>
                      </div>
                    </div>

                    <div className={props.classes.content}>
                      <CardContent className="pp-rhs-card">
                        <>
                          <div className="pp-user-info">
                            <div className="pp-user-name-dept">
                              <div className="pp-user-name">{user.name}</div>
                              <div className="pp-user-dept">
                                {user.dept.toUpperCase()}
                              </div>
                            </div>

                            <div className="pp-share-star-icons">
                              <div className="pp-share-icon">
                                <Tooltip title={'Edit your profile'}>
                                  <IconButton
                                    onClick={handleClickOpen}
                                    style={{ outline: 'none' }}
                                  >
                                    <EditOutlined />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                          <div className="pp-user-details-tabs">
                            <UserDetailsTabs user={user} />
                          </div>
                        </>
                        {Scraps ? (
                          <section className={'pp-scraps-section'}>
                            <div className="pp-scraps-heading">Scraps</div>
                            <div className="pp-scraps-container">
                              {Scraps.length ? (
                                <>
                                  {Scraps.map((scrap) => (
                                    <CurrentUserScrap
                                      key={scrap.id}
                                      scrap={scrap}
                                      numOfScraps={Scraps.length}
                                      handleNewUserClick={handleOtherUserClick}
                                      handleScrapVisibility={
                                        handleScrapVisibility
                                      }
                                      freezeDialog={freezeDialog}
                                    />
                                  ))}
                                </>
                              ) : (
                                <div className={'pp-scraps-none'}>
                                  You haven't recieved any scraps yet.
                                </div>
                              )}
                            </div>
                          </section>
                        ) : (
                          <ScrapSectionSkeleton />
                        )}
                      </CardContent>
                    </div>
                  </Card>
                </div>
              )}
            </div>
          </>
        )}
      </MuiPickersUtilsProvider>
    );
  }
};

export default withStyles(CurrentUserProfileStyles, { withTheme: true })(
  CurrentUserProfile,
);

const UserDetailsTabs = ({ user }) => {
  const [currentActiveTab, setCurrentActiveTab] = useState(0);
  return (
    <>
      <AppBar position={'static'}>
        <Tabs
          value={currentActiveTab}
          onChange={(e, newValue) => {
            setCurrentActiveTab(newValue);
          }}
        >
          <Tab label={'About'} />
          <Tab label={'Social Profiles'} />
        </Tabs>
      </AppBar>
      <UserDetailsTabPanel value={currentActiveTab} index={0}>
        <UserAbout quote={user.quote} dob={user.dob} email={user.email} />
      </UserDetailsTabPanel>
      <UserDetailsTabPanel value={currentActiveTab} index={1}>
        <UserSocialProfiles user={user} />
      </UserDetailsTabPanel>
    </>
  );
};

const UserAbout = ({ quote, dob, email }) => (
  <>
    <div className="pp-user-about-quote">
      <div className="pp-user-about-quote-icon">
        <FormatQuoteOutlinedIcon />
      </div>
      <div className="pp-user-about-quote-content">{quote}</div>
    </div>
    <div className="pp-user-about">
      <div className="pp-user-bday-container">
        <div className="pp-user-bday-icon">
          <CakeOutlinedIcon />
        </div>
        <div className="pp-user-bday">{formatDate(dob)}</div>
      </div>
      <div className="pp-user-email-container">
        <div className="pp-user-email-icon">
          <MailOutlineIcon />
        </div>
        <a href={`mailto:${email}`} target={'_blank'}>
          <div className="pp-user-email">{email.toLowerCase()}</div>
        </a>
      </div>
    </div>
  </>
);

const UserSocialProfiles = ({ user }) => {
  const areAnySocialProfiles =
    user.social_web ||
    user.social_twitter ||
    user.social_insta ||
    user.social_linkedin;

  return areAnySocialProfiles ? (
    <div className={'pp-user-socials'}>
      {user.social_insta != 'null' && (
        <div className="pp-user-social">
          <InstagramIcon />
          <div className="pp-user-social">
            <a href={user.social_insta} target={'_blank'}>
              {user.name}
            </a>
          </div>
        </div>
      )}

      {user.social_twitter != 'null' && (
        <div className="pp-user-social">
          <TwitterIcon />
          <div className="pp-user-social-twitter">
            <a href={user.social_twitter} target={'_blank'}>
              {user.name}
            </a>
          </div>
        </div>
      )}

      {user.social_linkedin != 'null' && (
        <div className="pp-user-social">
          <LinkedInIcon />
          <div className="pp-user-social-li">
            <a href={user.social_linkedin} target={'_blank'}>
              {user.name}
            </a>
          </div>
        </div>
      )}

      {user.social_web != 'null' && (
        <div className="pp-user-social">
          <LanguageIcon />
          <div className="pp-user-social">
            <a href={user.social_web} target={'_blank'}>
              {user.name}
            </a>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className={'pp-user-no-socials'}>
      Looks like {user.name.substring(0, user.name.indexOf(' '))} hasn't linked
      any social profiles.
    </div>
  );
};

function UserDetailsTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

const CurrentUserScrap = ({
  scrap,
  numOfScraps,
  handleNewUserClick,
  handleScrapVisibility,
  freezeDialog,
}) => {
  const [hasCurrentUserLiked, setHasCurrentUserLiked] = useState(false);
  const [scrapData, setScrapData] = useState(scrap); // initializing this state var from prop since we might need to change later
  const [currentUsermenuAnchorEl, setCurrentUserMenuAnchorEl] = useState(null);
  const [shouldVisibilityShowLoader, setShouldVisibilityShowLoader] = useState(
    false,
  );

  const handleMoreVertMenuClick = (e) => {
    setCurrentUserMenuAnchorEl(e.currentTarget);
  };

  const handleMoreVertMenuClose = () => {
    setCurrentUserMenuAnchorEl(null);
  };

  const isLikeRequestHappening = useRef(true);

  useEffect(() => {
    isLikeRequestHappening.current = true;
    const getLikes = async () => {
      await userAPI
        .getLikesOnScrap(scrapData.id)
        .then((data) => data.json())
        .then((likes) => {
          likes.forEach((like) => {
            if (like.user_id.id === +localStorage.getItem('id')) {
              setHasCurrentUserLiked(true);
            }
          });
          setScrapData((prevState) =>
            Object.assign(prevState, { likes: likes }),
          );
          isLikeRequestHappening.current = false;
        });
    };
    getLikes().catch((e) => {
      console.log(e);
    });
    // eslint-disable-next-line
  }, [scrapData]);

  useEffect(() => {
    setScrapData(scrap);
  }, [scrap]);

  const handleLike = () => {
    setHasCurrentUserLiked(true);

    setScrapData((prevScrap) => {
      const prevScrapLikes = prevScrap.likes ? [...prevScrap.likes] : [];
      return Object.assign(prevScrap, {
        num_of_likes: prevScrap.num_of_likes + 1,
        likes: [
          ...prevScrapLikes,
          {
            user_id: {
              id: +localStorage.getItem('id'),
              name: localStorage.getItem('name'),
            },
          },
        ],
      });
    });

    userAPI.likeScrap(scrapData.id).catch((e) => {
      console.log(e);
    });
  };

  const handleUnlike = () => {
    setHasCurrentUserLiked(false);

    setScrapData((prevScrap) =>
      Object.assign(prevScrap, {
        num_of_likes: prevScrap.num_of_likes - 1,
        likes: prevScrap.likes.filter(
          (likeObj) => likeObj.user_id.id !== +localStorage.getItem('id'),
        ),
      }),
    );

    userAPI.unlikeScrap(scrapData.id).catch((e) => {
      console.log(e);
    });
  };

  const handleLikeButtonClick = () => {
    if (hasCurrentUserLiked) {
      handleUnlike();
    } else {
      handleLike();
    }
  };

  // todo: what if the number of likes are very high
  const getScrapLikedByString = useCallback(() => {
    let scrapLikedByString = 'Liked by ';
    let isLikeEmpty = true;

    if (scrapData?.likes) {
      scrapData.likes.forEach((likeObj) => {
        scrapLikedByString =
          scrapLikedByString.concat(likeObj.user_id.name) + ', ';
        isLikeEmpty = false;
      });
    }

    return isLikeEmpty
      ? ''
      : scrapLikedByString.substring(0, scrapLikedByString.length - 2);
  }, []);

  const handleUserClick = () => {
    handleNewUserClick(scrapData.posted_by.id);
  };

  return (
    <div key={scrapData.id} className={'pp-scrap-container'}>
      <div className="pp-scrap-photo-name">
        <div className="pp-scrap-photo">
          <Avatar
            src={scrapData.posted_by.photo.replace('/800/', '/200/')}
            alt={scrapData.posted_by.name}
            onClick={() => {
              handleNewUserClick(scrapData.posted_by.id);
            }}
            style={{ cursor: 'pointer' }}
          />
        </div>
      </div>

      <div className="pp-scrap-content-container">
        <div className="pp-scrap-content">
          <span className="pp-scrap-name" onClick={handleUserClick}>
            {scrapData.posted_by.name.substring(
              0,
              scrapData.posted_by.name.indexOf(' '),
            ) + '  '}
          </span>
          <span>{scrapData.content}</span>
          <div className="pp-scrap-likes-time-container">
            <div className="pp-scrap-likes">
              <Tooltip title={getScrapLikedByString()}>
                <IconButton
                  className={'like-icon'}
                  color={hasCurrentUserLiked ? 'secondary' : 'default'}
                  style={{ outline: 'none' }}
                  onClick={handleLikeButtonClick}
                >
                  {hasCurrentUserLiked ? (
                    <FavoriteOutlinedIcon />
                  ) : (
                    <FavoriteBorderOutlinedIcon />
                  )}
                  <span className="like-count">{scrapData.num_of_likes}</span>
                </IconButton>
              </Tooltip>
            </div>
            <div className="pp-scrap-time">
              {timeDiff(scrapData.timestamp, numOfScraps)}
            </div>
            <div className="current-pp-visibility">
              {shouldVisibilityShowLoader ? (
                <div className={'current-pp-scrap-visible-loading'}>
                  <IconButton style={{ outline: 'none', color: '#828282' }}>
                    <CircularProgress />
                  </IconButton>
                </div>
              ) : (
                <Tooltip
                  title={
                    scrapData.visibility
                      ? 'Click to hide this scrap'
                      : 'Click to make this scrap public'
                  }
                >
                  <IconButton
                    style={{ outline: 'none', color: '#828282' }}
                    onClick={() => {
                      setShouldVisibilityShowLoader(true);
                      handleScrapVisibility(
                        scrapData.id,
                        setShouldVisibilityShowLoader,
                      );
                    }}
                  >
                    {scrapData.visibility ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
        <IconButton
          onClick={handleMoreVertMenuClick}
          style={{
            outline: 'none',
            position: 'absolute',
            top: '1px',
            right: '1px',
            color: '#828282',
          }}
        >
          <MoreVertOutlined />
        </IconButton>
        <Menu
          open={Boolean(currentUsermenuAnchorEl)}
          anchorEl={currentUsermenuAnchorEl}
          onClose={handleMoreVertMenuClose}
          transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <MenuItem
            onClick={() => {
              setShouldVisibilityShowLoader(true);
              handleMoreVertMenuClose();
              handleScrapVisibility(
                scrapData.id,
                setShouldVisibilityShowLoader,
              );
            }}
          >
            {scrapData.visibility ? (
              <>Hide this scrap from your profile</>
            ) : (
              <>Show this scrap on your profile</>
            )}
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

const MobileDetailsAndScrapsTabs = ({
  user,
  scraps,
  handleNewUserClick,
  handleScrapVisibility,
}) => {
  const [currentActiveTab, setCurrentActiveTab] = useState(0);
  return (
    <div className={'pp-mobile-user-tabs'}>
      <AppBar position={'static'}>
        <Tabs
          value={currentActiveTab}
          onChange={(e, newValue) => {
            setCurrentActiveTab(newValue);
          }}
        >
          <Tab label={'About'} />
          <Tab label={'Scraps'} />
        </Tabs>
      </AppBar>
      <UserDetailsTabPanel value={currentActiveTab} index={0}>
        <UserAboutMobile dob={user.dob} email={user.email} user={user} />
      </UserDetailsTabPanel>
      <UserDetailsTabPanel value={currentActiveTab} index={1}>
        <UserScrapsMobile
          handleNewUserClick={handleNewUserClick}
          handleScrapVisibility={handleScrapVisibility}
          scraps={scraps}
        />
      </UserDetailsTabPanel>
    </div>
  );
};

const UserAboutMobile = ({ dob, email, user }) => {
  const areAnySocialProfiles =
    user.social_web ||
    user.social_twitter ||
    user.social_insta ||
    user.social_linkedin;
  return (
    <div className={'pp-mobile-user-about'}>
      <div className="pp-user-bday-container">
        <div className="pp-user-bday-icon">
          <CakeOutlinedIcon />
        </div>
        <div className="pp-user-bday">{formatDate(dob)}</div>
      </div>
      <div className="pp-user-bday-container">
        <div className="pp-user-email-icon">
          <MailOutlineIcon />
        </div>
        <a href={`mailto:${email}`} target={'_blank'}>
          <div className="pp-user-email">{email.toLowerCase()}</div>
        </a>
      </div>
      {areAnySocialProfiles ? (
        <>
          {user.social_insta != 'null' && (
            <div className="pp-user-bday-container">
              <div className="pp-user-email-icon">
                <InstagramIcon />{' '}
              </div>

              <div className={'social-links-text'}>
                <a href={user.social_insta} target={'_blank'}>
                  {user.name}
                </a>
              </div>
            </div>
          )}

          {user.social_twitter != 'null' && (
            <div className="pp-user-bday-container">
              <div className="pp-user-email-icon">
                <TwitterIcon />
              </div>
              <div className={'social-links-text'}>
                <a href={user.social_twitter} target={'_blank'}>
                  {user.name}
                </a>
              </div>
            </div>
          )}

          {user.social_linkedin != 'null' && (
            <div className="pp-user-bday-container">
              <div className="pp-user-email-icon">
                <LinkedInIcon />{' '}
              </div>

              <div className={'social-links-text'}>
                <a href={user.social_linkedin} target={'_blank'}>
                  {user.name}
                </a>
              </div>
            </div>
          )}

          {user.social_web != 'null' && (
            <div className="pp-user-bday-container">
              <div className="pp-user-email-icon">
                <LanguageIcon />
              </div>

              <div className={'social-links-text'}>
                <a href={user.social_web} target={'_blank'}>
                  {user.name}
                </a>
              </div>
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

const UserScrapsMobile = ({
  scraps,
  handleNewUserClick,
  handleScrapVisibility,
}) => (
  <>
    {scraps ? (
      <>
        {scraps.length ? (
          <>
            {scraps.map((scrap) => (
              <CurrentUserScrap
                key={scrap.id}
                scrap={scrap}
                numOfScraps={scraps.length}
                handleNewUserClick={handleNewUserClick}
                handleScrapVisibility={handleScrapVisibility}
              />
            ))}
          </>
        ) : (
          <div className={'pp-scraps-none'}>
            You haven't recieved any scraps yet.
          </div>
        )}
      </>
    ) : (
      <ScrapSectionSkeleton />
    )}
  </>
);
