import React from 'react';
import Header from './home-page/Header';

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <h2 style={{ fontSize: '28px', padding: '10px 25%' }}>Privacy Policy</h2>
      <div style={{ padding: '25px 25%' }}>
        <div style={{ padding: '10px 0' }}>
          <p>
            GradGoggles recognises the importance of maintaining your privacy.
            We value your privacy and appreciate your trust in us. This Policy
            describes how we treat user information we collect on
            <a href="https://www.gradgoggles.com">
              https://www.gradgoggles.com
            </a>{' '}
            and other offline sources. This Privacy Policy applies to current
            and former visitors to our website. By visiting and/or using our
            website, you agree to this Privacy Policy.
            <br />
          </p>
        </div>
        <div style={{ padding: '10px 0' }}>
          <b>Information we collect</b>
          <p>
            Contact information: We might collect your name, email, date of
            birth, photo(s), year of graduation, college name, country, and IP
            Address
          </p>
          <p>
            Information you post: We collect information that you post in any
            space on our website.
          </p>
          <p>
            Demographic information: We may collect demographic information
            about you and any other information provided by you during the use
            of our website. We might collect this as a part of a survey also.
          </p>
          <p>
            Other information: If you use our website, we may collect
            information about your IP address and the browser that you're using.
            We might look at your point of origin, duration of time spent on our
            website, pages accessed or what site you visit when you leave us. We
            might also collect the type of mobile device you are using, or the
            version of the operating system your computer or device is running.
          </p>
        </div>
        <div style={{ padding: '10px 0' }}>
          <p>We collect information in different ways.</p>
          <p>
            We collect information directly from you: We collect information
            directly from you when you register on our website. We also collect
            information if you post a comment/scrap on our websites or ask us a
            question through our contact form or email.
          </p>
          <p>
            We collect information from you passively: We use tracking tools
            like Google Analytics, Google Webmaster, browser cookies and web
            beacons for collecting information about your usage of our website.
          </p>
          <p>
            We get information about you from third parties. For example, if you
            use an integrated social media feature on our websites. The
            third­party social media site will give us certain information about
            you. This could include your name and email address.
          </p>
        </div>
        <div style={{ padding: '10px 0' }}>
          <h3>Use of your personal information</h3>
          <p>
            We use information to contact you: We might use the information you
            provide to contact you for notifications and other promotional
            purposes.
          </p>
          <p>
            We use information to respond to your requests or questions: We
            might use your information to confirm and respond to your complaint
            and/or request.
          </p>
          <p>
            We use information to improve our products and services: We might
            use your information to customize your experience with us. This
            could include displaying content based upon your preferences.
          </p>
          <p>
            We use information to look at site trends and user interests: We may
            use your information to make our website and products better. We may
            combine information we get from you with information about you we
            get from third parties.
          </p>
          <p>
            We use information for security purposes: We may use information to
            protect our team, our users, or our website.
          </p>
          <p>
            We use information for marketing purposes: We might send you
            information about new features or launches.
          </p>
        </div>
        <div style={{ padding: '10px 0' }}>
          <b>Sharing of information with third parties</b>
          <p>
            We may share information if we think we have to in order to comply
            with the law or to protect ourselves. We will share information to
            respond to a court order or subpoena. We may also share it if a
            government agency or investigatory body requests it. Or, we might
            also share information when we are investigating potential fraud.
          </p>
          <p>
            We may share your information for reasons not described in this
            policy. We will tell you before we do this.
          </p>
        </div>
        <div style={{ padding: '10px 0' }}>
          <h2 style={{ fontSize: '20px', padding: '10px 0' }}>Cookie Policy</h2>
          <p>
            Please read this cookie policy (“cookie policy”, "policy") carefully
            before using GradGoggles (“website”, "service") operated by
            GradGoggles ("us", 'we", "our").
          </p>
          <b>What are cookies?</b>
          <p>
            Cookies are simple text files that are stored on your computer or
            mobile device by a website’s server. Each cookie is unique to your
            web browser. It will contain some anonymous information such as a
            unique identifier, the website’s domain name, and some digits and
            numbers.
          </p>
          <b>What types of cookies do we use? Necessary cookies</b>
          <p>
            Necessary cookies allow us to offer you the best possible experience
            when accessing and navigating through our website and using its
            features. For example, these cookies let us recognize that you have
            created an account and have logged into that account.
          </p>
          <b>Functionality cookies</b>
          <p>
            Functionality cookies let us operate the site in accordance with the
            choices you make. For example, we will recognize your username and
            remember how you customized the site during future visits.
          </p>
          <b>Analytical cookies</b>
          <p>
            These cookies enable us and third-party services to collect
            aggregated data for statistical purposes on how our visitors use the
            website. These cookies do not contain personal information such as
            names and email addresses and are used to help us improve your user
            experience of the website.
          </p>
          <b>How to delete cookies?</b>
          <p>
            If you want to restrict or block the cookies that are set by our
            website, you can do so through your browser setting. Alternatively,
            you can visit www.internetcookies.org, which contains comprehensive
            information on how to do this on a wide variety of browsers and
            devices. You will find general information about cookies and details
            on how to delete cookies from your device.
          </p>
        </div>
        <div style={{ padding: '10px 0' }}>
          <h2 style={{ fontSize: '20px', padding: '10px 0' }}>Contacting us</h2>
          <p>
            If you have any questions about this policy or our use of cookies,
            please{' '}
            <a
              href={
                'https://form.typeform.com/to/Nthlirwg?typeform-medium=embed-snippet'
              }
            >
              {' '}
              contact us.
            </a>
          </p>
          <h2 style={{ fontSize: '20px', padding: '10px 0' }}>
            Updates to this policy
          </h2>
          <p>
            This Privacy Policy was last updated on 25/05/2021. From time to
            time we may change our privacy practices. We will notify you of any
            material changes to this policy. We will also post an updated copy
            on our website. Please check our site periodically for updates.
          </p>
        </div>
        <div>
          <h2 style={{ fontSize: '20px', padding: '10px 0' }}>Jurisdiction</h2>
          <p>
            If you choose to visit the website, your visit and any dispute over
            privacy is subject to this Policy and the website's terms of use. In
            addition to the foregoing, any disputes arising under this Policy
            shall be governed by the laws of India.
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
