import {
  withStyles,
  ThemeProvider,
  Typography,
  TextField,
  Button,
  InputAdornment,
  Select,
} from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LanguageIcon from '@material-ui/icons/Language';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SignUp3 = ({
  handleSignUpChange,
  signUpSubmit,
  setSignUpState,
  handleDob,
  signUpForm,
  setAlertOpen,
  setAlertMessage,
  setAlertSeverity,
}) => {
  const classes = useStyles();

  const validateStep = () => {
    if (signUpForm.dob) {
      signUpSubmit();
    } else {
      setAlertMessage('Some Fields Are Missing!');
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  return (
    <React.Fragment>
      <ArrowBackIcon
        onClick={() => {
          setSignUpState(2);
        }}
        className="arrowBack"
        fontSize="large"
      />
      <div id="bday-header">When is your birthday?</div>
      <div className="dobHolder">
        <TextField
          required
          disableToolbar
          type="date"
          variant="outlined"
          format="dd/MM/yyyy"
          margin="normal"
          disableFuture={true}
          id="DOB"
          placeholder="dd/mm/yyyy"
          value={signUpForm.dob}
          onChange={handleDob}
          allowKeyboardControl={false}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </div>

      <div>Your social media links/usernames</div>
      <TextField
        id="name-with-icon"
        name="social_insta"
        value={signUpForm.social_insta}
        onChange={handleSignUpChange}
        placeholder="Instagram"
        variant="outlined"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <InstagramIcon />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        id="name-with-icon"
        name="social_linkedin"
        value={signUpForm.social_linkedin}
        onChange={handleSignUpChange}
        placeholder="LinkedIn"
        variant="outlined"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LinkedInIcon />
            </InputAdornment>
          ),
        }}
      />

      <TextField
        id="name-with-icon"
        name="social_twitter"
        value={signUpForm.social_twitter}
        onChange={handleSignUpChange}
        placeholder="Twitter"
        variant="outlined"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <TwitterIcon />
            </InputAdornment>
          ),
        }}
      />

      <TextField
        id="name-with-icon"
        name="social_web"
        value={signUpForm.social_web}
        onChange={handleSignUpChange}
        fullWidth
        placeholder="Any other link"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LanguageIcon />
            </InputAdornment>
          ),
        }}
      />

      <div className="logButtonHolder dualButtonHolder" id="register-final">
        <Button
          onClick={validateStep}
          variant="outlined"
          size="medium"
          style={{ borderRadius: 25, textTransform: 'none' }}
          fullWidth
        >
          Register
        </Button>
      </div>
    </React.Fragment>
  );
};

export default SignUp3;
