import React, { Component } from 'react';
// import '../../styles/LoginSignUpComponent.css';
import {
  Typography,
  TextField,
  IconButton,
  Input,
  InputLabel,
  InputAdornment,
  FormControl,
  Button,
  Snackbar,
  Select,
} from '@material-ui/core';
import {
  AccountCircle,
  Visibility,
  VisibilityOff,
  Person,
  School,
} from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import { withStyles, ThemeProvider } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import { isLoggedIn } from '../../utils/auth';
import ggLogo from '../../assets/gg_compressed.png';
import moment from 'moment';
import { apiURL } from '../../utils/apiURL';
const defaultTheme = createMuiTheme();
const theme = createMuiTheme({
  palette: {
    type: 'dark',
    text: {
      font: "'Playfair-display',serif",
    },
  },
});
const useStyles = (theme) => ({
  root: {
    [theme.breakpoints.down(769)]: {
      color: 'white',
    },
  },
  logSignButton: {
    color: '#3caea3',
    backgroundColor: '#fff',
    borderColor: '#3caea3',
    borderRadius: 5,
    width: '50%',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#3caea3',
    },
  },
  prevButton: {
    color: '#3caea3',
    backgroundColor: '#fff',
    borderColor: '#3caea3',
    borderRadius: 5,
    width: '40%',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#3caea3',
    },
  },
  nextButton: {
    float: 'right',
    color: '#3caea3',
    backgroundColor: '#fff',
    borderColor: '#3caea3',
    borderRadius: 5,
    width: '40%',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#3caea3',
    },
  },
  iconHandler: {
    cursor: 'pointer',
  },
  forgotPassword: {
    fontSize: '13px',
    cursor: 'pointer',
  },
  signUpText: {
    fontSize: '13px',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  backdrop: {
    zIndex: '1900',
    color: '#fff',
  },
  popUpButton: {
    color: '#3caea3',
  },
});
class LoginSignUpComponent extends Component {
  state = {};
  constructor() {
    super();
    this.state = {
      passVisibility: false,
      loginForm: {
        email: '',
        password: '',
      },
      emailErrorHelperText: '',
      passwordHelperText: '',
      forgotPassOpen: false,
      forgotPasswordEmail: '',
      forgotPasswordHelperText: '',
      verificationOpen: false,
      alertMessage: '',
      alertOpen: false,
      alertSeverity: 'success',
      loadingBackDropOpen: false,
      toggleForm: false,
      signUpState: 1,
      postRegOpen: false,
      signUpForm: {
        email: '',
        password: '',
        fullName: '',
        GRNo: '',
        dept: 'Computer',
        dob: new Date(),
        quote: '',
        photo: 'https://picsum.photos/200/300',
        class2020: true,
        photoData: null,
      },
      signUpHelperText: {
        email: '',
        password: '',
        fullName: '',
        GRNo: '',
        dept: '',
        dob: '',
        quote: '',
        photo: '',
      },
      signUpHelperTextArray: {
        email: 'Please Enter a Valid Email',
        password: 'Please Enter a Valid Password',
        fullName: 'Please Enter a Valid Name',
        GRNo: 'Please Enter a Valid GRNo',
        dept: 'Please Enter a Valid Department',
        dob: 'Please Enter a Valid DOB',
        quote: 'Please Enter a Valid Quote',
        photo: 'Please Enter a Valid Photo',
      },
    };
    this.handleLoginChange = this.handleLoginChange.bind(this);
    this.forgotClose = this.forgotClose.bind(this);
    this.loginSubmit = this.loginSubmit.bind(this);
    this.forgotPasswordSubmit = this.forgotPasswordSubmit.bind(this);
    this.forgotPasswordChange = this.forgotPasswordChange.bind(this);
    this.checkVerificationAndLogin = this.checkVerificationAndLogin.bind(this);
    this.sendVerification = this.sendVerification.bind(this);
    this.toggleForm = this.toggleForm.bind(this);
    this.handleSignUpChange = this.handleSignUpChange.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handlePhotoChange = this.handlePhotoChange.bind(this);
    this.getSignedRequest = this.getSignedRequest.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.randomString = this.randomString.bind(this);
    this.signUpSubmit = this.signUpSubmit.bind(this);
    this.resetForms = this.resetForms.bind(this);
    this.cancelContinue = this.cancelContinue.bind(this);
    this.canceledPostReg = this.canceledPostReg.bind(this);
  }

  resetForms() {
    this.setState({
      loginForm: {
        email: '',
        password: '',
      },
      signUpForm: {
        email: '',
        password: '',
        fullName: '',
        GRNo: '',
        dept: 'Computer',
        dob: new Date(),
        quote: '',
        photo: 'https://picsum.photos/200/300',
        class2020: true,
      },
      signUpState: 1,
    });
  }
  canceledPostReg() {
    this.setState({ postRegOpen: false });
    if (
      this.state.access_token !== null ||
      this.state.access_token !== undefined ||
      this.state.access_token !== ''
    ) {
      localStorage.setItem('name', this.state.user.name);
      localStorage.setItem('photo', this.state.user.photo);
      localStorage.setItem('access_token', this.state.access_token);
      localStorage.setItem('is2020', this.state.user.is2020);
      localStorage.setItem('isVerified', this.state.user.isVerified);
      localStorage.setItem('id', this.state.user.id);
      window.location.replace('/yearbook');
    }
  }
  cancelContinue() {
    this.setState({ verificationOpen: false });
    if (
      this.state.access_token !== null ||
      this.state.access_token !== undefined ||
      this.state.access_token !== ''
    ) {
      localStorage.setItem('name', this.state.user.name);
      localStorage.setItem('photo', this.state.user.photo);
      localStorage.setItem('access_token', this.state.access_token);
      localStorage.setItem('isVerified', this.state.user.isVerified);
      localStorage.setItem('is2020', this.state.user.is2020);
      localStorage.setItem('id', this.state.user.id);
      window.location.replace('/yearbook');
    }
  }
  signUpSubmit() {
    let flagInput = false;
    let listErrors = '';
    let url = apiURL + 'register';
    let formData = new FormData();
    let data = this.state.signUpForm;
    if (!moment(this.state.signUpForm.dob).isValid()) {
      flagInput = true;
    }
    for (let name in data) {
      var value = data[name];
      if ((name === 'quote' && !this.state.class2020) || name === 'photoData') {
        continue;
      }
      var helperText = this.state.signUpHelperTextArray[name];
      if (value === '' || value === null || value === undefined) {
        flagInput = true;
        this.setState((prevState) => ({
          signUpHelperText: {
            [name]: helperText,
          },
        }));
      }
    }
    if (flagInput) {
      this.setState({
        alertMessage: 'Some Fields Are Missing!',
        alertSeverity: 'error',
      });
      this.setState({ alertOpen: true });
    } else {
      this.setState({ loadingBackDropOpen: true });
      for (let name in data) {
        var value = data[name];
        if (name === 'dob') {
          value = moment(value);
          let year = value.year();
          let month = value.month() + 1;
          let date = value.date();
          let monthString = month,
            datestring = date;
          if (month < 10) {
            monthString = `0${month}`;
          }
          if (date < 10) {
            datestring = `0${date}`;
          }
          let dateString = `${datestring}-${monthString}-${year}`;
          value = dateString;
        }
        if (name === 'quote') {
          if (!this.state.signUpForm.class2020) {
            continue;
          }
        }
        if (name === 'class2020') {
          name = 'is2020';
          value = value ? '2020' : '-9999';
        }
        formData.append(name, value);
      }

      fetch(url, {
        method: 'POST',
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error !== 'none') {
            this.setState({
              loadingBackDropOpen: false,
              alertMessage: data.error,
              alertSeverity: 'error',
            });
            this.setState({ alertOpen: true });
          } else {
            if (
              data.access_token !== null &&
              data.access_token !== 'undefined' &&
              data.access_token !== undefined
            ) {
              this.resetForms();
              let user = {
                id: data.id,
                name: data.name,
                photo: data.photo,
                is2020: data.is2020,
                isVerified: data.isVerified,
              }; //Add isVerified Here
              this.setState({ user: user, access_token: data.access_token });
              this.setState({
                loadingBackDropOpen: false,
                access_token: data.access_token,
                postRegOpen: true,
              });
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }
  randomString() {
    var length = 32;
    var chars =
      '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var result = '';
    for (var i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }
  getSignedRequest(file) {
    this.setState({ loadingBackDropOpen: true });
    let fileName = this.randomString();
    var outObject = this;
    var xhr = new XMLHttpRequest();
    xhr.open(
      'GET',
      `${apiURL}/sign_s3?file_name=${fileName}&filetype=${file.type}`,
    );
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          var response1 = JSON.parse(xhr.responseText);
          var response = JSON.parse(response1);
          outObject.uploadFile(file, response.data, response.url);
        } else {
          this.setState({
            loadingBackDropOpen: false,
            alertMessage: "Couldn't Upload File",
            alertSeverity: 'error',
          });
          this.setState({ alertOpen: true });
          console.log('Could not get signed URL.');
        }
      }
    };
    xhr.send();
  }

  async uploadFile(file, s3Data, url) {
    var postData = new FormData();
    for (var key in s3Data.fields) {
      postData.append(key, s3Data.fields[key]);
    }
    postData.append('file', file);
    var response = await fetch(s3Data.url, {
      method: 'POST',
      body: postData,
    });
    if (!response.ok) {
      this.setState({
        loadingBackDropOpen: false,
        alertMessage: "Couldn't Upload File",
        alertSeverity: 'error',
      });
      this.setState({ alertOpen: true });
      console.log('Could not upload file.');
      return;
    }

    const reader = new FileReader();
    const photoData = null;

    reader.onload = (e) =>
      this.setState((prevState) => ({
        signUpForm: { ...prevState.signUpForm, photoData: e.target.result },
      }));

    reader.readAsDataURL(file); // convert to base64 string

    this.setState((prevState) => ({
      signUpForm: { ...prevState.signUpForm, photo: url },
    }));
    this.setState({
      loadingBackDropOpen: false,
      alertMessage: 'Upload Successful',
      alertSeverity: 'success',
    });
    this.setState({ alertOpen: true });
  }

  handlePhotoChange(event) {
    var files = event.target.files;
    var file = files[0];
    if (!file) {
      console.log('No file selected');
    } else {
      this.getSignedRequest(file);
    }
  }
  handleUpload() {
    document.getElementById('hiddenPhotoInput').click();
  }
  handleDob = (date) =>
    this.setState((prevState) => ({
      signUpForm: { ...prevState.signUpForm, dob: date },
    }));
  toggleForm() {
    if (!this.state.toggleForm) {
      return (
        <React.Fragment>
          <div className="emailHolder">
            <TextField
              error={this.state.emailErrorHelperText === '' ? false : true}
              helperText={this.state.emailErrorHelperText}
              required
              id="email-with-icon"
              name="email"
              value={this.state.loginForm.email}
              onChange={this.handleLoginChange}
              label="Email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="passHolder">
            <TextField
              error={this.state.passwordHelperText === '' ? false : true}
              helperText={this.state.passwordHelperText}
              required
              onChange={this.handleLoginChange}
              name="password"
              value={this.state.loginForm.password}
              InputLabelProps={{ shrink: true }}
              type={this.state.passVisibility ? 'text' : 'password'}
              id="password-with-icon"
              label="Password"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    className={this.props.classes.iconHandler}
                    onClick={() => {
                      this.setState((prevState) => ({
                        passVisibility: !prevState.passVisibility,
                      }));
                    }}
                    position="start"
                  >
                    {this.state.passVisibility ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="forgotPasswordHolder">
            <Typography
              onClick={() => {
                this.setState({ forgotPassOpen: true });
              }}
              className={this.props.classes.forgotPassword}
            >
              Forgot Password?
            </Typography>
          </div>
          <div className="logButtonHolder">
            <Button
              onClick={this.loginSubmit}
              variant="outlined"
              size="medium"
              className={this.props.classes.logSignButton}
            >
              Login
            </Button>
          </div>
          <div className="signUpChanger">
            <Typography
              onClick={() => {
                this.setState({ toggleForm: true });
              }}
              className={this.props.classes.signUpText}
            >
              Wanna celebrate the Class of 2020? Sign Up{' '}
            </Typography>
          </div>
        </React.Fragment>
      );
    } else {
      if (this.state.signUpState === 1) {
        return (
          <React.Fragment>
            <div className="emailHolder">
              <TextField
                error={
                  this.state.signUpHelperText.fullName === '' ? false : true
                }
                helperText={this.state.signUpHelperText.fullName}
                required
                id="name-with-icon"
                name="fullName"
                value={this.state.signUpForm.fullName}
                onChange={this.handleSignUpChange}
                label="Full Name"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <School />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="emailHolder">
              <TextField
                error={this.state.signUpHelperText.email === '' ? false : true}
                helperText={this.state.signUpHelperText.email}
                required
                id="email-with-icon"
                name="email"
                value={this.state.signUpForm.email}
                onChange={this.handleSignUpChange}
                label="Email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="passHolder">
              <TextField
                error={
                  this.state.signUpHelperText.password === '' ? false : true
                }
                helperText={this.state.signUpHelperText.password}
                required
                onChange={this.handleSignUpChange}
                name="password"
                value={this.state.signUpForm.password}
                InputLabelProps={{ shrink: true }}
                type={this.state.passVisibility ? 'text' : 'password'}
                id="password-with-icon"
                label="Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      className={this.props.classes.iconHandler}
                      onClick={() => {
                        this.setState((prevState) => ({
                          passVisibility: !prevState.passVisibility,
                        }));
                      }}
                      position="start"
                    >
                      {this.state.passVisibility ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="logButtonHolder">
              <Button
                onClick={() => {
                  this.setState({ signUpState: 2 });
                }}
                variant="outlined"
                size="medium"
                className={this.props.classes.logSignButton}
              >
                Next
              </Button>
            </div>
            <div className="signUpChanger">
              <Typography
                onClick={() => {
                  this.setState({ toggleForm: false });
                }}
                className={this.props.classes.signUpText}
              >
                Already have an account? Sign In.{' '}
              </Typography>
            </div>
          </React.Fragment>
        );
      } else if (this.state.signUpState === 2) {
        return (
          <React.Fragment>
            <div className="dobHolder">
              <KeyboardDatePicker
                required
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                disableFuture={true}
                id="DOB"
                label="Date of Birth"
                value={moment(this.state.signUpForm.dob)}
                onChange={this.handleDob}
                allowKeyboardControl={false}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </div>
            <div className="emailHolder GRHolder">
              <TextField
                error={this.state.signUpHelperText.GRNo === '' ? false : true}
                helperText={this.state.signUpHelperText.GRNo}
                required
                id="email-with-icon"
                name="GRNo"
                value={this.state.signUpForm.GRNo}
                onChange={this.handleSignUpChange}
                label="G.R. No"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="passHolder selectHolder">
              <Select
                required
                value={this.state.signUpForm.dept}
                native
                name="dept"
                label="Department"
                onChange={this.handleSignUpChange}
              >
                <option aria-label="None" value="" />
                <option value="Computer">Computer</option>
                <option value="Elex">Elex</option>
                <option value="EnTC">EnTC</option>
                <option value="Chemical">Chemical</option>
                <option value="Industrial">Industrial</option>
                <option value="Production">Production</option>
                <option value="Mechanical">Mechanical</option>
                <option value="IT">IT</option>
                <option value="Instru">Instrumentation</option>
                <option value="MCA">MCA</option>
              </Select>
            </div>
            <div className="logButtonHolder dualButtonHolder">
              <Button
                onClick={() => {
                  this.setState({ signUpState: 1 });
                }}
                variant="outlined"
                size="medium"
                className={this.props.classes.prevButton}
              >
                Previous
              </Button>
              <Button
                onClick={() => {
                  this.setState({ signUpState: 3 });
                }}
                variant="outlined"
                size="medium"
                className={this.props.classes.nextButton}
              >
                Next
              </Button>
            </div>
            <div className="signUpChanger">
              <Typography
                onClick={() => {
                  this.setState({ toggleForm: false });
                }}
                className={this.props.classes.signUpText}
              >
                Already have an account? Sign In.{' '}
              </Typography>
            </div>
          </React.Fragment>
        );
      } else if (this.state.signUpState === 3) {
        return (
          <React.Fragment>
            <div className="emailHolder loginAvatarHolder">
              <div className="loginAvatarDiv">
                <img
                  src={
                    this.state.signUpForm.photoData
                      ? this.state.signUpForm.photoData
                      : this.state.signUpForm.photo
                  }
                  className="login-profile-img"
                />
                <EditIcon onClick={this.handleUpload} className="fa fa-edit" />
                <input
                  type="file"
                  onChange={this.handlePhotoChange}
                  name="photo"
                  id="hiddenPhotoInput"
                  className="hiddenInputEdit"
                />
              </div>
            </div>

            <div className="emailHolder quoteHolder">
              <label className="quoteLabel">Enter your Quote!</label>
              <TextareaAutosize
                disabled={!this.state.signUpForm.class2020}
                error={
                  this.state.signUpHelperText.quote === '' ? 'false' : 'true'
                }
                helpertext={this.state.signUpHelperText.quote}
                required
                onChange={this.handleSignUpChange}
                name="quote"
                value={this.state.signUpForm.quote}
                rowsMax={2}
                label="Enter your quote"
                aria-label="empty-textarea"
              />
            </div>

            <div className="emailHolder">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.signUpForm.class2020}
                    onChange={this.handleSignUpChange}
                    name="class2020"
                  />
                }
                label="Are you in the Graduating Class of 2020?"
              />
            </div>

            <div className="logButtonHolder dualButtonHolder">
              <Button
                onClick={() => {
                  this.setState({ signUpState: 2 });
                }}
                variant="outlined"
                size="medium"
                className={this.props.classes.prevButton}
              >
                Previous
              </Button>
              <Button
                onClick={this.signUpSubmit}
                variant="outlined"
                size="medium"
                className={this.props.classes.nextButton}
              >
                Register
              </Button>
            </div>
            <div className="signUpChanger">
              <Typography
                onClick={() => {
                  this.setState({ toggleForm: false });
                }}
                className={this.props.classes.signUpText}
              >
                Already have an account? Sign In.{' '}
              </Typography>
            </div>
          </React.Fragment>
        );
      }
    }
  }

  async sendVerification() {
    if (this.state.postRegOpen) {
      this.setState({
        verificationOpen: false,
        loadingBackDropOpen: true,
        postRegOpen: false,
      });
      const url = apiURL + 'resend_email';
      let sendVerificationPromise = await fetch(url, {
        headers: {
          Authorization: `Bearer ${this.state.access_token}`,
        },
      })
        .then((res) => res.json())
        .then((resendData) => {
          this.setState({ loadingBackDropOpen: false });
          let alertMessage, alertSeverity;
          if (resendData !== null && resendData.msg == 'success') {
            alertMessage = 'Verification Mail Sent Successfully!';
            alertSeverity = 'success';
          } else {
            alertMessage = 'Verification Mail Failure! Contact Administrator.';
            alertSeverity = 'error';
          }
          this.setState({
            access_token: '',
            alertMessage: alertMessage,
            alertSeverity: alertSeverity,
          });
          this.setState({ alertOpen: true });
          if (
            this.state.access_token !== null ||
            this.state.access_token !== undefined ||
            this.state.access_token !== ''
          ) {
            localStorage.setItem('name', this.state.user.name);
            localStorage.setItem('photo', this.state.user.photo);
            localStorage.setItem('access_token', this.state.access_token);
            localStorage.setItem('is2020', this.state.user.is2020);
            localStorage.setItem('isVerified', this.state.user.isVerified);
            localStorage.setItem('id', this.state.user.id);
            window.location.replace('/yearbook');
          }
        });
    } else {
      this.setState({
        verificationOpen: false,
        loadingBackDropOpen: true,
        postRegOpen: false,
      });
      const url = apiURL + 'resend_email';
      let sendVerificationPromise = await fetch(url, {
        headers: {
          Authorization: `Bearer ${this.state.access_token}`,
        },
      })
        .then((res) => res.json())
        .then((resendData) => {
          this.setState({ loadingBackDropOpen: false });
          let alertMessage, alertSeverity;
          if (resendData !== null && resendData.msg == 'success') {
            alertMessage = 'Verification Mail Sent Successfully!';
            alertSeverity = 'success';
          } else {
            alertMessage = 'Verification Mail Failure! Contact Administrator.';
            alertSeverity = 'error';
          }
          this.setState({
            alertMessage: alertMessage,
            alertSeverity: alertSeverity,
          });
          this.setState({ alertOpen: true });
          if (
            this.state.access_token !== null ||
            this.state.access_token !== undefined ||
            this.state.access_token !== ''
          ) {
            localStorage.setItem('name', this.state.user.name);
            localStorage.setItem('photo', this.state.user.photo);
            localStorage.setItem('access_token', this.state.access_token);
            localStorage.setItem('is2020', this.state.user.is2020);
            localStorage.setItem('isVerified', this.state.user.isVerified);
            localStorage.setItem('id', this.state.user.id);
            window.location.replace('/yearbook');
          }
        });
    }
  }
  async checkVerificationAndLogin(data) {
    if (!(data.access_token != null)) {
      return;
    }
    const url = apiURL + 'check_verification';
    await fetch(url, {
      headers: {
        Authorization: `Bearer ${data.access_token}`,
      },
    })
      .then((res) => res.json())
      .then((checkData) => {
        this.setState({ loadingBackDropOpen: false });
        if (checkData.msg === 'yes') {
          this.setState({ loginForm: { email: '', password: '' } });
          localStorage.setItem('access_token', data.access_token);
          localStorage.setItem('name', data.name);
          localStorage.setItem('photo', data.photo);
          localStorage.setItem('isVerified', this.state.user.isVerified);
          localStorage.setItem('is2020', this.state.user.is2020);
          localStorage.setItem('id', this.state.user.id);
          localStorage.setItem('college', 'vit'); // TODO: change this to user's college
          if (
            localStorage.getItem('access_token') !== null &&
            localStorage.getItem('access_token') !== 'undefined'
          ) {
            window.location.replace('/yearbook');
          } else {
            let alertMessage, alertSeverity;
            alertMessage = 'Wrong Email/Password';
            alertSeverity = 'error';
            this.setState({
              alertMessage: alertMessage,
              alertSeverity: alertSeverity,
            });
            this.setState({ alertOpen: true });
          }
        } else {
          this.setState({
            verificationOpen: true,
            access_token: data.access_token,
          });
        }
      })
      .catch((err) => console.log(err));
  }
  forgotPasswordSubmit() {
    if (this.state.forgotPasswordEmail === '') {
      this.setState({
        forgotPasswordHelperText: 'Enter a valid email address.',
      });
    } else {
      this.setState({ forgotPassOpen: false, loadingBackDropOpen: true });
      const url = apiURL + 'forgotPasswordSendMail';
      let formData = new FormData();
      formData.append('email', this.state.forgotPasswordEmail);

      fetch(url, {
        method: 'POST',
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          this.setState({ loadingBackDropOpen: false });
          let alertMessage, alertSeverity;
          if (data.msg === 'email sent') {
            alertMessage = 'Check email for reset link!';
            alertSeverity = 'success';
          } else {
            alertMessage = 'Unable to send mail! Contact Administrator.';
            alertSeverity = 'error';
          }
          this.setState({
            forgotPasswordEmail: '',
            forgotPasswordHelperText: '',
            alertMessage: alertMessage,
            alertSeverity: alertSeverity,
          });
          this.setState({ alertOpen: true });
        })
        .catch((err) => console.log(err));
    }
  }

  forgotPasswordChange(event) {
    let value = event.target.value;
    this.setState({
      forgotPasswordEmail: value,
    });

    if (value !== '') {
      this.setState({
        forgotPasswordHelperText: '',
      });
    }
  }

  handleLoginChange(event) {
    const nameOfField = event.target.name;
    const value = event.target.value;
    this.setState((prevState) => ({
      loginForm: {
        ...prevState.loginForm,
        [nameOfField]: value,
      },
    }));

    if (nameOfField === 'email' && value !== '') {
      this.setState({ emailErrorHelperText: '' });
    } else if (nameOfField === 'password' && value !== '') {
      this.setState({ passwordHelperText: '' });
    }
  }

  handleSignUpChange(event) {
    const nameOfField = event.target.name;
    const value = event.target.value;
    if (nameOfField === 'class2020') {
      this.setState((prevState) => ({
        signUpForm: {
          ...prevState.signUpForm,
          class2020: !prevState.signUpForm.class2020,
        },
      }));
    } else {
      const helperText = this.state.signUpHelperTextArray[nameOfField];
      this.setState((prevState) => ({
        signUpForm: {
          ...prevState.signUpForm,
          [nameOfField]: value,
        },
      }));
      if (value !== '') {
        this.setState((prevState) => ({
          signUpHelperText: {
            ...prevState.signUpHelperText,
            [nameOfField]: '',
          },
        }));
      }
    }
  }

  async loginSubmit() {
    if (this.state.loginForm.email === '') {
      this.setState({
        emailErrorHelperText: 'Please Enter A Valid Email',
      });
    }

    if (this.state.loginForm.password === '') {
      this.setState({
        passwordHelperText: 'Please Enter A Password',
      });
    }

    if (
      this.state.loginForm.email === '' ||
      this.state.loginForm.password === ''
    ) {
      return;
    } else {
      this.setState({ loadingBackDropOpen: true });
      let url = apiURL + 'login';
      let formData = new FormData();
      let data = this.state;

      formData.append('email', data.loginForm.email);
      formData.append('password', data.loginForm.password);

      fetch(url, {
        method: 'POST',
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error !== null && data.error !== 'none') {
            this.setState({ loadingBackDropOpen: false });
            let alertMessage, alertSeverity;
            alertMessage = 'Wrong Email/Password';
            alertSeverity = 'error';
            this.setState({
              alertMessage: alertMessage,
              alertSeverity: alertSeverity,
            });
            this.setState({ alertOpen: true });
          } else {
            let user = {
              id: data.id,
              name: data.name,
              photo: data.photo,
              is2020: data.is2020,
              isVerified: data.isVerified,
            };
            this.setState({ user: user, access_token: data.access_token });
            let checkVerificationPromise = this.checkVerificationAndLogin(data);
          }
        })
        .catch((err) => console.log(err));
    }
  }

  forgotClose() {
    this.setState({ forgotPassOpen: false });
  }

  render() {
    let loadingBackDrop = (
      <Backdrop
        className={this.props.classes.backdrop}
        open={this.state.loadingBackDropOpen}
      >
        <School className="loadingSchool" />
      </Backdrop>
    );
    let dialogBox = (
      <Dialog
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        open={this.state.forgotPassOpen}
        onClose={this.forgotClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Forgot your Password?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            No worries, happens to all of us. We'll hook you up with a new one
            in no time. Drop your email here.
          </DialogContentText>
          <TextField
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            value={this.state.forgotPasswordEmail}
            onChange={this.forgotPasswordChange}
            fullWidth
            error={this.state.forgotPasswordHelperText === '' ? false : true}
            helperText={this.state.forgotPasswordHelperText}
          />
        </DialogContent>
        <DialogActions>
          <Button
            className={this.props.classes.popUpButton}
            onClick={this.forgotClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            className={this.props.classes.popUpButton}
            onClick={this.forgotPasswordSubmit}
            color="primary"
          >
            Send Mail
          </Button>
        </DialogActions>
      </Dialog>
    );

    let verificationAlertDialog = (
      <Dialog
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        open={this.state.verificationOpen}
        onClose={() => {
          this.setState({ verificationOpen: false });
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Verification Pending'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Oops, looks like somebody hasn't verified their account. Click on
            Resend Email to send the verification email again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={this.props.classes.popUpButton}
            onClick={this.cancelContinue}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            className={this.props.classes.popUpButton}
            onClick={this.sendVerification}
            color="primary"
            autoFocus
          >
            Resend Email
          </Button>
        </DialogActions>
      </Dialog>
    );
    let postRegDialog = (
      <Dialog
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        open={this.state.postRegOpen}
        onClose={() => {
          this.setState({ postRegOpen: false });
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Welcome Aboard.'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            A very warm welcome to you from Team GradGoggles. Please verify your
            account with the link sent on your email. If you haven't received
            one, please click Resend Email.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={this.props.classes.popUpButton}
            onClick={this.canceledPostReg}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            className={this.props.classes.popUpButton}
            onClick={this.sendVerification}
            color="primary"
            autoFocus
          >
            Resend Email
          </Button>
        </DialogActions>
      </Dialog>
    );

    let snackBar = (
      <Snackbar
        open={this.state.alertOpen}
        autoHideDuration={6000}
        onClose={() => {
          this.setState({ alertOpen: false });
        }}
      >
        <Alert
          onClose={() => {
            this.setState({ alertOpen: false });
          }}
          severity={this.state.alertSeverity}
        >
          {this.state.alertMessage}
        </Alert>
      </Snackbar>
    );

    if (isLoggedIn()) {
      return <Redirect to={{ pathname: '/yearbook' }} />;
    } else {
      return (
        <React.Fragment>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <section className="loginSignUpHolder">
                <div className="joinContainer">
                  <div className="formHalf">
                    <div className="joinHeading">
                      <Typography variant="h4">
                        {this.state.toggleForm
                          ? 'Get on Board!'
                          : 'Welcome Back!'}
                      </Typography>
                    </div>
                    <div className="joinBody">{this.toggleForm()}</div>
                  </div>
                  <div className="backgroundHalf">
                    <div className="quoteFloat">
                      "Once upon a time in Bibwewadi"
                      <hr className="hr_width" />
                      <img src={ggLogo} width="100" height="100" />
                    </div>
                  </div>
                </div>
              </section>
              {dialogBox}
              {verificationAlertDialog}
              {snackBar}
              {loadingBackDrop}
              {postRegDialog}
            </MuiPickersUtilsProvider>
          </ThemeProvider>

          <footer className="footer"> © 2020 Team GradGoggles</footer>
        </React.Fragment>
      );
    }
  }
}

export default withStyles(useStyles, { withTheme: true })(LoginSignUpComponent);
