import React from 'react';
import { motion } from 'framer-motion';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import useAnimatedNavToggler from './useAnimatedNavToggler';
import { ReactComponent as MenuIcon } from './images/menu.svg';
import { ReactComponent as CloseIcon } from './images/x.svg';
import { Link } from 'react-router-dom';
import { isLoggedIn } from '../../utils/auth';
import UserHeaderMenu from '../UserMenuHeader';

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto
`;

export const NavLinks = tw.div`flex items-center`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const NavLinkReactRouter = tw(Link)`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const NavLinkReactRouterWithoutHoverBorder = tw(Link)`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent
`;

export const PrimaryLink = tw(NavLinkReactRouter)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-bold border-b-0 text-3xl! ml-0!`};

  img {
    ${tw`w-20 mr-3`}
  }
`;

export const MobileLogoLink = styled(NavLink)`
  ${tw`flex items-center font-bold border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-16 mr-2`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion.custom(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

const defaultLinks = [
  <NavLinks key={1}>
    <NavLink href="#our-story">About</NavLink>

    {isLoggedIn() ? (
      <>
        <NavLinkReactRouter to="/yearbook">Yearbook</NavLinkReactRouter>
        <NavLinkReactRouter to="/my-college-wall">
          My College Wall
        </NavLinkReactRouter>
        <NavLinkReactRouter to="/department-pic">
          Department Picture
        </NavLinkReactRouter>
        <NavLinkReactRouterWithoutHoverBorder to="/#">
          <UserHeaderMenu />
        </NavLinkReactRouterWithoutHoverBorder>
      </>
    ) : (
      <>
        <NavLinkReactRouter to="/join" tw="lg:ml-12!">
          Login
        </NavLinkReactRouter>
        <PrimaryLink
          css={tw`rounded-full`}
          to="/join"
          className={'headerSignUpLink'}
        >
          Sign Up
        </PrimaryLink>
      </>
    )}
  </NavLinks>,
];

const mobileLinks = [
  <NavLinks key={1}>
    <NavLink href="#our-story">About</NavLink>

    {isLoggedIn() ? (
      <>
        <NavLinkReactRouter to="/yearbook">Yearbook</NavLinkReactRouter>
        <NavLinkReactRouter to="/my-college-wall">
          My College Wall
        </NavLinkReactRouter>
        <NavLinkReactRouter to="/department-pic">
          Department Picture
        </NavLinkReactRouter>
        <NavLinkReactRouter to="/profile">My Profile</NavLinkReactRouter>
        <NavLinkReactRouter to="/logout">Log Out</NavLinkReactRouter>
      </>
    ) : (
      <>
        <NavLinkReactRouter to="/join" tw="lg:ml-12!">
          Login
        </NavLinkReactRouter>
        <PrimaryLink
          css={tw`rounded-full`}
          to="/join"
          className={'headerSignUpLink'}
        >
          Sign Up
        </PrimaryLink>
      </>
    )}
  </NavLinks>,
];

export default ({
  logoLink,
  links,
  className,
  collapseBreakpointClass = 'lg',
}) => {
  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href="/">
      <img
        src={'https://d1577j21c01vef.cloudfront.net/logo_with_shadow.png'}
        alt="logo"
      />
      GradGoggles
    </LogoLink>
  );

  const mobileLogoLink = (
    <MobileLogoLink href="/" className={'headerLogoLink'}>
      <img
        src={'https://d1577j21c01vef.cloudfront.net/logo_with_shadow.png'}
        alt="logo"
      />
      GradGoggles
    </MobileLogoLink>
  );

  logoLink = logoLink || defaultLogoLink;

  return (
    <Header className={className || 'header-light'}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {defaultLinks}
      </DesktopNavLinks>

      <MobileNavLinksContainer
        css={collapseBreakpointCss.mobileNavLinksContainer}
      >
        {mobileLogoLink}
        <MobileNavLinks
          initial={{ x: '150%', display: 'none' }}
          animate={animation}
          css={collapseBreakpointCss.mobileNavLinks}
        >
          {mobileLinks}
          <div className="mobile-nav-close">
            <NavToggle
              onClick={toggleNavbar}
              className={showNavLinks ? 'open' : 'closed'}
              style={{ zIndex: '1000' }}
            >
              <CloseIcon tw="w-6 h-6" id="close-icon-mobile" />
            </NavToggle>
          </div>
        </MobileNavLinks>
        <NavToggle
          onClick={toggleNavbar}
          className={showNavLinks ? 'open' : 'closed'}
        >
          {!showNavLinks && <MenuIcon tw="w-6 h-6" />}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};
