import React, { useEffect, useState, useRef } from 'react';
import '../../styles/SearchResultsComponent.css';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import YearbookCard from './new/YearbookCard';
import SearchIcon from '@material-ui/icons/Search';
import userAPI from '../../utils/api/UserAPI';
import { withRouter } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import { School } from '@material-ui/icons';
import ggLogo from '../../assets/gg_compressed.png';
import { TextField, InputAdornment } from '@material-ui/core';
import tw from 'twin.macro';
import YearbookHeader from './new/YearbookHeader';
import { getYearbookHeightWidth } from '../../utils/yearbookCardDimensionsUtils';
import YearbookCardSkeleton from './new/YearbookCardSkeleton';

const StyledDiv = tw.div`font-display min-h-screen`;
const [ybCardWidth, ybCardHeight] = getYearbookHeightWidth();

const SearchResults = ({ location, collegeData }) => {
  const [deptValue, setDeptValue] = useState('');

  const [loadingBackdropOpen, setLoadingBackdropOpen] = useState(true);
  const [searchTerm, setSearchTerm] = useState(
    location.pathname.replace('/searchResults/', ''),
  );
  const [searchData, setSearchData] = useState([]);
  const [searchValue, setSearchValue] = useState(searchTerm);
  const [closeFriends, setCloseFriends] = useState([]);
  const [cardOpen, setCardOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [run, setRun] = useState(true);

  const departments = collegeData ? collegeData.departments : [];

  const [isShareProfileDialogOpen, setIsShareProfileDialogOpen] = useState(
    false,
  );
  const [currentShareProfileID, setCurrentShareProfileID] = useState(undefined); // to keep track of which profileID should be showed in ShareProfileDialog

  const callSearch = () => {
    if (searchValue.toLowerCase() !== searchTerm.toLowerCase()) {
      setSearchTerm(searchValue);
      setDeptValue('');
      window.history.replaceState(
        null,
        'searchResults',
        '/searchResults/' + searchValue,
      );
      setLoadingBackdropOpen(true);
    }
  };

  useEffect(() => {
    userAPI
      .searchTerm(searchTerm)
      .then((res) => res.json())
      .then((data) => {
        setSearchData(data);

        setLoadingBackdropOpen(false);
      })
      .catch((err) => console.log(err));
  }, [searchTerm]);

  const handleSearchText = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearchDept = (event) => {
    const inputDeptValue = event.target.value;
    setDeptValue(inputDeptValue);
  };

  const handleShareProfileDialogOpen = (profileID) => {
    setCurrentShareProfileID(profileID);
    setIsShareProfileDialogOpen(true);
  };

  return (
    <StyledDiv>
      <YearbookHeader />

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          marginLeft: 50,
          marginRight: 50,
        }}
      >
        <div className="searchSelectBox">
          <FormControl variant="outlined" className="internals">
            <InputLabel htmlFor="outlined-age-native-simple">
              Department
            </InputLabel>
            <Select
              native
              label="Department"
              value={deptValue}
              onChange={handleSearchDept}
            >
              <option aria-label="None" value="" />
              {collegeData
                ? departments.map((dept) => (
                    <option value={dept} key={dept}>
                      {dept}
                    </option>
                  ))
                : null}
            </Select>
          </FormControl>
        </div>

        <div className="searchBoxInResult searchBoxResult">
          <TextField
            id="outlined-basic"
            label="Search"
            value={searchValue}
            className="internals"
            onChange={handleSearchText}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                callSearch();
              }
            }}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment onClick={callSearch}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
      <div className="yb-cards">
        {!loadingBackdropOpen ? (
          searchData
            .filter((profile) => {
              if (!deptValue) return true;
              else {
                return profile.dept.toLowerCase() === deptValue.toLowerCase();
              }
            })

            .map((person) => (
              <YearbookCard
                id={person.id}
                user={person}
                profile={person}
                key={person.id}
                profileBgImage={collegeData.profile_bg}
                isCloseFriend={isUserCloseFriend(person.id, closeFriends)}
                openShareProfileDialog={handleShareProfileDialogOpen}
                ybCardWidth={ybCardWidth}
                ybCardHeight={ybCardHeight}
                setCardOpen={setCardOpen}
                cardOpen={cardOpen}
                setStepIndex={setStepIndex}
                stepIndex={stepIndex}
                run={run}
                setRun={setRun}
              />
            ))
        ) : (
          <>{<YearbookSkeletonPage />}</>
        )}
        {searchData.length === 0 && (
          <div className="col-md-12">
            <h4 className="noResultsFound">No Results Found!</h4>
          </div>
        )}
      </div>
      {searchData.length !== 0 && <EndOfYearbook />}
    </StyledDiv>
  );
};

const LoadingBackdrop = ({ isOpen }) => (
  <Backdrop className="backdropIn" open={isOpen}>
    <School className="loadingSchool" />
  </Backdrop>
);

export default withRouter(SearchResults);

const EndOfYearbook = () => (
  <div className={'loading-more'}>
    <div className="loading-more-text">
      Looks like you've reached the end of search results.
    </div>
  </div>
);

const YearbookSkeletonPage = () =>
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
    <YearbookCardSkeleton key={item} />
  ));

const isUserCloseFriend = (userID, closeFriends) => {
  let isCloseFriend = false;
  for (let i = 0; i < closeFriends.length; i++) {
    if (closeFriends[i] == userID) {
      isCloseFriend = true;
      break;
    }
  }
  return isCloseFriend;
};
