import React, { useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
//eslint-disable-next-line
import { css } from 'styled-components/macro';
import '../../styles/newHome.css';
import Header from './Header';

import { ReactComponent as SvgDecoratorBlob1 } from './images/header_blob_1.svg';
import { ReactComponent as SvgDecoratorBlob2 } from './images/header_blob_2.svg';
import HomeHeroIllustration from './images/illustration_2021.svg';
import { collegeList } from '../../utils/api/collegeDataAPI';
import { Link } from 'react-router-dom';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:pt-16 md:pb-24`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-start`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  select {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center m-auto max-w-md`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none absolute left-0 bottom-0 transform -z-10`}
`;

const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none absolute right-0 bottom-0 transform -z-10`}
`;

const CustomersLogoStrip = styled.div`
  ${tw`mt-8 lg:mt-12`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-50`}
  }
`;

const collegeOptionsStyle = {
  fontFamily: "'Inter', sans-serif",
  fontSize: '16px',
};

export default ({ roundedHeaderButton }) => {
  const [selectedCollege, setSelectedCollege] = useState(null);

  const handleSelectedCollegeChange = (e) => {
    setSelectedCollege(e.target.value);
  };

  return (
    <>
      <Header roundedHeaderButton={roundedHeaderButton} />
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>
              One of a kind <span tw="text-primary-500">Virtual Yearbook.</span>
            </Heading>
            <Paragraph>
              Years down the line, these will be your memories to cherish and
              smile back on.
            </Paragraph>

            <Actions>
              <select
                name={'college'}
                id={'college-select'}
                className={'collegeSelectHero'}
                onChange={handleSelectedCollegeChange}
              >
                <option value={''} style={collegeOptionsStyle}>
                  Choose your college
                </option>
                {collegeList.map((college) => (
                  <option
                    value={college.id}
                    key={college.id}
                    style={collegeOptionsStyle}
                  >
                    {college.name}
                  </option>
                ))}
              </select>
              <Link
                to={{
                  pathname: '/join',
                  state: { selectedCollege: selectedCollege },
                }}
              >
                <button>Get Started</button>
              </Link>
            </Actions>

            {/*<CustomersLogoStrip>*/}
            {/*  <p>Our Partner Colleges</p>*/}
            {/*  <div className="partnerColleges">*/}
            {/*    {collegeList.map((college) => (*/}
            {/*      <div className={'partnerCollege'} key={college.id}>*/}
            {/*        {college.short}*/}
            {/*      </div>*/}
            {/*    ))}*/}
            {/*  </div>*/}
            {/*</CustomersLogoStrip>*/}
            <div className="gg-college-container">
              <div className="gg-your-college">
                <a
                  className="gg-your-college typeform-share button"
                  href="https://form.typeform.com/to/Nthlirwg?typeform-medium=embed-snippet"
                  data-mode="popup"
                >
                  Want GradGoggles in Your College?
                </a>
              </div>
            </div>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img
                tw="min-w-0 w-full max-w-lg xl:max-w-3xl"
                src={HomeHeroIllustration}
                alt="GradGoggles 2021"
              />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 className={'hero-decorator-blob-1'} />
        <DecoratorBlob2 className={'hero-decorator-blob-2'} />
      </Container>
    </>
  );
};
