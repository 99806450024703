import React from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const YearbookCollegeAndSearchSection = ({
  collegeData,
  setSearchValue,
  callSearch,
}) => {
  return (
    <div
      className={'yb-college-section'}
      style={{
        background: '#373B44',
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url(${collegeData?.mainCollegeBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="yb-clg-name">{collegeData?.college_fullname}</div>
      <div className="yb-batch-name">Batch of 2021</div>
      <div className="yb-search-box">
        <TextField
          variant={'filled'}
          id={'yearbook-search-box'}
          placeholder={'Search for your friends'}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              callSearch();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position={'start'}>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default YearbookCollegeAndSearchSection;
