import tw from 'twin.macro';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import useAnimatedNavToggler from '../../home-page/useAnimatedNavToggler';
import { ReactComponent as CloseIcon } from '../../home-page/images/x.svg';
import { ReactComponent as MenuIcon } from '../../home-page/images/menu.svg';
import React from 'react';
import { Link } from 'react-router-dom';
import Notifications from '../../Notifications';
import UserHeaderMenu from '../../UserMenuHeader';
import IconButton from '@material-ui/core/IconButton';
import { HelpOutlineOutlined } from '@material-ui/icons';

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto px-8 py-2
`;

export const NavLinks = tw.div`inline-block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw(Link)`
  text-base my-2 lg:text-base lg:mx-6 lg:my-0
  font-medium tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-bold border-b-0 text-2xl! ml-0`};

  img {
    ${tw`w-16 mr-3`}
  }
`;

const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
const MobileNotificationsWrapper = tw.div`lg:hidden focus:outline-none transition duration-300`;
const MobileNavLinks = motion.custom(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

const DesktopNavLinks = tw.nav`
  hidden lg:flex justify-between items-center w-full
`;

const RightSideWrapper = tw.div`flex flex-row flex-1 justify-end`;

const YbHeaderLinks = ({ currentPath }) => {
  const getYbNavLinkStyle = (link) =>
    link === currentPath ? { borderColor: 'rgb(4,47,134, 0.2)' } : null;

  return (
    <NavLinks key={1}>
      <NavLink
        to={'/yearbook'}
        style={getYbNavLinkStyle('/yearbook')}
        id="yearbook-dialog"
      >
        Yearbook
      </NavLink>
      <NavLink
        to={'/my-college-wall'}
        style={getYbNavLinkStyle('/my-college-wall')}
        id="college-wall-dialog"
      >
        My College Wall
      </NavLink>
      <NavLink
        to={'/department-pic'}
        style={getYbNavLinkStyle('/department-pic')}
        id="department-pic-dialog"
      >
        Department Picture
      </NavLink>
    </NavLinks>
  );
};

const ybMobileHeaderLinks = [
  <NavLinks key={1}>
    <NavLink to={'/yearbook'} id="yearbook-dialog-mobile">
      Yearbook
    </NavLink>
    <NavLink to={'/my-college-wall'} id="college-wall-dialog-mobile">
      My College Wall
    </NavLink>
    <NavLink to={'/department-pic'} id="department-pic-dialog-mobile">
      Department Picture
    </NavLink>
    <NavLink to={'/profile'} id="my-profile-mobile">
      My Profile
    </NavLink>
    <NavLink to={'/logout'} id="my-profile-mobile">
      Log Out
    </NavLink>
  </NavLinks>,
];

const defaultLogoLink = (
  <LogoLink to="/">
    <img
      src={'https://d1577j21c01vef.cloudfront.net/logo_with_shadow.png'}
      alt="logo"
    />
    GradGoggles
  </LogoLink>
);

const mobileLogoLink = (
  <LogoLink to="/" className={'yb-header-mobile-logo'}>
    <img
      src={'https://d1577j21c01vef.cloudfront.net/logo_with_shadow.png'}
      alt="logo"
      style={{ width: '3.35rem' }}
    />
  </LogoLink>
);

const HelpButton = ({ handleHelpToggle }) => {
  if (window.location.pathname === '/yearbook') {
    return (
      <IconButton
        style={{ border: 'none', outline: 'none' }}
        onClick={handleHelpToggle}
      >
        <HelpOutlineOutlined style={{ height: '36px', width: '36px' }} />
      </IconButton>
    );
  } else {
    return null;
  }
};

//todo: do the same thing for mobile as well in MobileNavLinks
const RightSideHeaderBar = ({ handleHelpToggle }) => {
  return (
    <RightSideWrapper>
      <HelpButton handleHelpToggle={handleHelpToggle} />
      <Notifications />
      <UserHeaderMenu />
    </RightSideWrapper>
  );
};

const YearbookHeader = (props) => {
  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler(
    props.setStepIndex,
  );
  const collapseBreakpointCss = collapseBreakPointCssMap['lg'];

  return (
    <Header className={'header-light yb-header'}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {defaultLogoLink}
        <YbHeaderLinks currentPath={window.location.pathname} />
        <RightSideHeaderBar handleHelpToggle={props.handleHelpToggle} />
      </DesktopNavLinks>

      <MobileNavLinksContainer
        css={collapseBreakpointCss.mobileNavLinksContainer}
      >
        <NavToggle
          onClick={toggleNavbar}
          className={showNavLinks ? 'open' : 'closed'}
          style={{ zIndex: '1000' }}
        >
          {!showNavLinks && (
            <>
              <MenuIcon tw="w-6 h-6" id="menu-icon-mobile" />
            </>
          )}
        </NavToggle>
        {mobileLogoLink}
        <MobileNavLinks
          initial={{ x: '150%', display: 'none' }}
          style={{ zIndex: '999' }}
          animate={animation}
          css={collapseBreakpointCss.mobileNavLinks}
        >
          {ybMobileHeaderLinks}
          <div className="mobile-nav-close">
            <NavToggle
              onClick={toggleNavbar}
              className={showNavLinks ? 'open' : 'closed'}
              style={{ zIndex: '1000' }}
            >
              <CloseIcon tw="w-6 h-6" id="close-icon-mobile" />
            </NavToggle>
          </div>
        </MobileNavLinks>
        <MobileNotificationsWrapper>
          <Notifications />
        </MobileNotificationsWrapper>
      </MobileNavLinksContainer>
    </Header>
  );
};

export default YearbookHeader;
/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};
