const CurrentUserProfileStyles = (theme) => ({
  root: {
    borderRadius: '15px',
    [theme.breakpoints.up(900)]: {
      display: 'flex',
      flexDirection: 'row',
      width: '80vw',
      minHeight: '624px',
      height: '90vh',
    },
    [theme.breakpoints.down(900)]: {
      display: 'flex',
      flexDirection: 'column',
      width: '80vw',
      height: '160vh',
      minHeight: '1200px',
    },
  },
  image: {
    [theme.breakpoints.up(900)]: {
      borderTopLeftRadius: '15px',
      borderBottomLeftRadius: '15px',
      minHeight: '100%',
      maxWidth: '100%',
      '&::before': {
        borderTopLeftRadius: '15px',
        borderBottomLeftRadius: '15px',
        content: "''",
        position: 'absolute',
        top: '0',
        left: '0',
        right: '50%',
        bottom: '0',
        zIndex: '1',
        background: 'rgba(0,0,0,.8)',
      },
    },
    [theme.breakpoints.down(900)]: {
      borderTopLeftRadius: '15px',
      borderTopRightRadius: '15px',
      minHeight: '500px',
      height: '80vh',
      maxWidth: '100%',
      '&::before': {
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        content: "''",
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        minHeight: '500px',
        height: '80vh',
        zIndex: '1',
        background: 'rgba(0,0,0,.8)',
      },
    },
  },
  cover: {
    [theme.breakpoints.up(900)]: {
      borderTopLeftRadius: '15px',
      borderBottomLeftRadius: '15px',
      flex: '0 1 50%',
      minHeight: '100%',
      maxWidth: '100%',
    },
    [theme.breakpoints.down(900)]: {
      borderTopLeftRadius: '15px',
      borderTopRightRadius: '15px',
      flex: '0 1 100%',
      minHeight: '500px',
      height: '80vh',
      maxWidth: '100%',
      marginTop: 'auto',
    },
  },
  content: {
    [theme.breakpoints.up(900)]: {
      borderTopLeftRadius: '15px',
      borderBottomLeftRadius: '15px',
      flex: '0 1 50%',
      minHeight: '100%',
      maxWidth: '100%',
    },
    [theme.breakpoints.down(900)]: {
      borderTopLeftRadius: '15px',
      borderTopRightRadius: '15px',
      flex: '0 1 100%',
      minHeight: '500px',
      height: '85vh',
      maxWidth: '100%',
      marginBottom: 'auto',
    },
  },

  profile: {
    [theme.breakpoints.up(900)]: {
      borderTopLeftRadius: '15px',
      borderBottomLeftRadius: '15px',
      minHeight: '100%',
      maxWidth: '100%',
      background: 'transparent',
      zIndex: '3',
      position: 'absolute',
      top: '0',
      left: '0',
      right: '50%',
      bottom: '0',
      color: '#fff',
    },

    [theme.breakpoints.down(900)]: {
      borderTopLeftRadius: '15px',
      borderTopRightRadius: '15px',
      minHeight: '500px',
      height: '80vh',
      maxWidth: '100%',
      background: 'transparent',
      zIndex: '3',
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '100%',
      color: '#fff',
    },
  },
  nameWrapper: {
    [theme.breakpoints.up(900)]: {
      textAlign: 'center',
      float: 'left',
      width: '100%',
      height: '15%',
    },
    [theme.breakpoints.down(900)]: {
      textAlign: 'center',
      float: 'left',
      width: '100%',
      height: '15%',
    },
  },
  name: {
    [theme.breakpoints.up(500)]: {
      margin: '0',
      fontSize: '20px',
      lineHeight: '5',
      fontFamily: "'Source Sans Pro',sans-serif",
      fontWeight: '700',
      fontStyle: 'bold',
    },
    [theme.breakpoints.down(500)]: {
      margin: '0',
      fontSize: '15px',
      lineHeight: '4',
      fontFamily: "'Source Sans Pro',sans-serif",
      fontWeight: '700',
      fontStyle: 'bold',
    },
  },
  avatarWrapper: {
    [theme.breakpoints.up(900)]: {
      float: 'left',
      width: '100%',
      height: '35%',
    },

    [theme.breakpoints.down(900)]: {
      float: 'left',
      width: '100%',
      height: '30%',
    },
  },
  avatar: {
    [theme.breakpoints.up(900)]: {
      display: 'block',
      margin: '0.25rem auto',
      width: theme.spacing(25),
      height: theme.spacing(25),
      filter: 'grayscale(100%)',
    },
    [theme.breakpoints.down(900)]: {
      display: 'block',
      margin: '0.25rem auto',
      width: theme.spacing(15),
      height: theme.spacing(15),
      filter: 'grayscale(100%)',
    },
  },
  quoteWrapper: {
    float: 'left',
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.up(900)]: {
      height: '25%',
    },
    [theme.breakpoints.down(900)]: {
      height: '30%',
    },
  },

  quote: {
    fontSize: '15px',
    textAlign: 'center',
    fontFamily: "'Source Sans Pro',sans-serif",
    fontWeight: '300',
    fontStyle: 'italic',
    margin: '0.25rem',

    [theme.breakpoints.down(400)]: {
      fontSize: '12.5px',
    },

    [theme.breakpoints.up(1440)]: {
      fontSize: '20px',
    },
  },

  bottomWrapper: {
    float: 'left',
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.up(900)]: {
      height: '25%',
    },
    [theme.breakpoints.down(900)]: {
      height: '25%',
    },
  },
  emailIconWrapper: {
    float: 'left',
    width: '50%',
    textAlign: 'center',
    overflowWrap: 'break-word',
  },
  calendarIconWrapper: {
    float: 'left',
    width: '50%',
    overflowWrap: 'break-word',
  },
  emailIcon: {
    [theme.breakpoints.up(900)]: {
      color: '#3caea3',
      width: theme.spacing(10),
      height: theme.spacing(10),
      margin: '0.5rem auto',
    },
    [theme.breakpoints.down(900)]: {
      color: '#3caea3',
      width: theme.spacing(7),
      height: theme.spacing(7),
      margin: '0.5rem auto',
    },
    [theme.breakpoints.down(400)]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      margin: '0.5rem auto',
    },
  },
  calendarIcon: {
    [theme.breakpoints.up(900)]: {
      color: '#3caea3',
      width: theme.spacing(10),
      height: theme.spacing(10),
      margin: '0.5rem auto',
    },
    [theme.breakpoints.down(900)]: {
      color: '#3caea3',
      width: theme.spacing(7),
      height: theme.spacing(7),
      margin: '0.5rem auto',
    },
    [theme.breakpoints.down(400)]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      margin: '0.5rem auto',
    },
  },
  textSimp: {
    fontSize: '15px',
    textAlign: 'center',
    fontFamily: "'Source Sans Pro',sans-serif",
    fontWeight: '400',
    fontStyle: 'bold',
    margin: '0.25rem',
    [theme.breakpoints.down(400)]: {
      fontSize: '12.5px',
    },

    [theme.breakpoints.up(1440)]: {
      fontSize: '20px',
    },
  },

  commentAvatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    margin: 'auto 0.5rem',
  },

  commentWriteAvatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    margin: '0.5rem',
  },
  cardDiv: {
    height: '100%',
  },
  textArea: {
    width: '100%',
    height: '50%',
  },
  appBar: {
    position: 'relative',
    backgroundColor: '#3caea3',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  backdrop: {
    zIndex: '4000',
    color: '#fff',
  },

  iconHandler: {
    cursor: 'pointer',
  },

  alertBox: {
    zIndex: '2500',
  },

  firstNameTitle: {
    fontFamily: "'Source Sans Pro',sans-serif",
    fontWeight: '400',
    fontStyle: 'bold',
  },
  downAppBar: {
    backgroundColor: '#3caea3',
  },
  changePassButton: {
    color: '#3caea3',
    backgroundColor: '#fff',
    borderColor: '#3caea3',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#3caea3',
    },
  },
});

export default CurrentUserProfileStyles;
