import React from 'react';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import AnimationRevealPage from './AnimationRevealPage';
import 'tailwindcss/dist/base.css';
import Hero from './HeroSection';
import Features from './FeaturesSection';
import MainFeature from './MainFeature';
import OurStory from './OurStory';
import FeatureWithSteps from './FeatureWithSteps';
import Testimonial from './TestimonialSection';
import FAQ from './FAQSection';
import GetStarted from './GetStartedSection';
import Footer from './FooterSection';
import mainFeatureIllustration from '../../assets/havingFun.svg';
import prototypeIllustrationImageSrc from './images/prototype-illustration.svg';
import { ReactComponent as BriefcaseIcon } from './images/briefcase.svg';
import { ReactComponent as MoneyIcon } from './images/dollar-sign.svg';

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <MainFeature
        subheading={<Subheading>Virtual Yearbook</Subheading>}
        imageSrc={mainFeatureIllustration}
        imageBorder={false}
        imageDecoratorBlob={true}
      />
      <Features
        heading={
          <>
            Unique <HighlightedText>Features</HighlightedText>
          </>
        }
      />
      <FeatureWithSteps
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Get Started.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <OurStory
        subheading={<Subheading>By Students, For Students</Subheading>}
        heading={
          <>
            Our <HighlightedText>Story</HighlightedText>
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: 'Affordable',
            description:
              'We promise to offer you the best rate we can - at par with the industry standard.',
            iconContainerCss: tw`bg-green-300 text-green-800`,
          },
          {
            Icon: BriefcaseIcon,
            title: 'Professionalism',
            description:
              'We assure you that our templates are designed and created by professional designers.',
            iconContainerCss: tw`bg-red-300 text-red-800`,
          },
        ]}
      />
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            Frequently Asked <HighlightedText>Questions</HighlightedText>
          </>
        }
        faqs={[
          {
            question: 'Can any college participate in this yearbook?',
            answer:
              'We are reaching out and onboarding more schools/colleges each day. If you wish for your school/college to participate in this, kindly get in touch with us at team@gradgoggles.com',
          },
          {
            question: 'How do I enter myself into my college’s yearbook?',
            answer:
              'You will need to select your college’s name from our list and then create an account. The Sign-up process will collect all your details required for an entry into your college yearbook. For additional security against spam, you will need to verify your email in order to appear in your college’s yearbook.',
          },
          {
            question: 'What are scraps?',
            answer:
              'Scraps are a way to communicate with other users on Gradgoggles. They are meant as a way to leave messages on your friends’ walls. To leave a scrap, you can search and click the person’s yearbook entry. You’ll be able to see the Scraps section where you can leave a scrap for your friend to read.',
          },
          {
            question: ' Can I control what scraps are shown on my profile?',
            answer:
              'Yes, to prevent spam and unwanted messages, you can control how others are able to view your scraps. All of this can be found under the Edit Profile Section.',
          },
          {
            question: 'My details are incorrect, how do I change them?',
            answer:
              'You can change your details anytime. Just click on your Avatar in the Menu, and you’ll be directed to the Edit Profile section. There, you can update any of your details. Don’t forget to hit Save after you’re done.',
          },
        ]}
      />

      <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Users Love <HighlightedText>GradGoggles.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              'https://d1577j21c01vef.cloudfront.net/200/f4WRmVi0iiVh4aUnE1S8DfWSZvtrfwHC',
            heading: 'Introduced at the right time!',
            quote:
              'GradGoggles was introduced to us just at the right time. Although nothing can replace the physical convocation, the team ensured we get the best possible virtual experience with the most seamless experience.',
            customerName: 'Deboshree Roy',
            customerTitle: '2020 Grad, VIT Pune',
          },
          {
            stars: 5,
            profileImageSrc:
              'https://d1577j21c01vef.cloudfront.net/200/UIvFf3JSSpvY38IzsVTMNPnZdQ7YK71w',
            heading: 'Unique Idea',
            quote:
              'Loved the thought and the idea, and now with added functionalities, the experience is only going to get better',
            customerName: 'Prathamesh Patil',
            customerTitle: '2020 Grad, VIT Pune',
          },
          {
            stars: 5,
            profileImageSrc:
              'https://d1577j21c01vef.cloudfront.net/200/jmRmG3dFbaazxwpxZ2qSQ2H8PcyoFIpZ',
            heading: 'A Nostalgic Experience',
            quote:
              "This virtual yearbook and all the nostalgia it carries shall always remain special to me and I can't thank team GradGoggles enough for it",
            customerName: 'Kamini Kumar',
            customerTitle: '2020 Grad, VIT Pune',
          },
        ]}
      />
      {/*<GetStarted />*/}
      <Footer />
    </AnimationRevealPage>
  );
};
