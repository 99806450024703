import moment from 'moment';
import { formatDate } from './formatDate';

export function timeDiff(date, numberOfItems) {
  let timeString;

  if (numberOfItems > 20) {
    timeString = formatDate(date);
    return timeString;
  }

  let postDate = moment.utc(date);
  let currentDate = moment.utc();
  let timeDiff = Math.floor(currentDate.diff(postDate) / 1000);

  let minutes = Math.floor(timeDiff / 60);
  let hours = Math.floor(timeDiff / 3600);
  let days = Math.floor(timeDiff / 86400);
  let weeks = Math.floor(timeDiff / 604800);

  if (weeks > 0) {
    if (weeks === 1) {
      timeString = `${weeks} Week Ago`;
    } else {
      timeString = `${weeks} Weeks Ago`;
    }
  } else if (days > 0) {
    if (days === 1) {
      timeString = `${days} Day Ago`;
    } else {
      timeString = `${days} Days Ago`;
    }
  } else if (hours > 0) {
    if (hours == 1) {
      timeString = `${hours} Hour Ago`;
    } else {
      timeString = `${hours} Hours Ago`;
    }
  } else if (minutes > 0) {
    if (minutes === 1) {
      timeString = `${minutes} Minute Ago`;
    } else {
      timeString = `${minutes} Minutes Ago`;
    }
  } else {
    if (timeDiff === 1) {
      timeString = `${timeDiff} Second Ago`;
    } else {
      timeString = `${timeDiff} Seconds Ago`;
    }
  }

  return timeString;
}
