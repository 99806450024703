import React, { useState } from 'react';
import { Avatar, Menu, MenuItem, Popover, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';

const UserHeaderMenu = () => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const handleClick = (e) => {
    setMenuAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setMenuAnchorEl(null);
  };
  return (
    <>
      <div className="user-icon">
        <Tooltip title={'Your Profile'}>
          <IconButton
            style={{ border: 'none', outline: 'none' }}
            onClick={handleClick}
          >
            <Avatar
              src={localStorage.getItem('photo').replace('/800/', '/200/')}
              alt={localStorage.getItem('name')}
              style={{ height: '45px', width: '45px' }}
            />
          </IconButton>
        </Tooltip>
      </div>
      <Menu
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        onClose={handleClose}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Link to={'/profile'}>
          <MenuItem>My Scraps</MenuItem>
        </Link>

        <Link to={'/profile'}>
          <MenuItem>Edit Profile</MenuItem>
        </Link>

        <a
          href={
            'https://form.typeform.com/to/Nthlirwg?typeform-medium=embed-snippet'
          }
          target={'_blank'}
        >
          <MenuItem>Contact Us</MenuItem>
        </a>

        <Link to={'/logout'}>
          <MenuItem>Logout</MenuItem>
        </Link>
      </Menu>
    </>
  );
};

export default UserHeaderMenu;
