import {
  withStyles,
  ThemeProvider,
  Typography,
  InputAdornment,
  Button,
  TextField,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import googleLogo from '../../../assets/googleIcon.svg';
const Login = ({
  loginForm,
  emailErrorHelperText,
  handleLoginChange,
  passwordHelperText,
  setPassVisibility,
  passVisibility,
  setForgotPassOpen,
  loginSubmit,
  setToggleForm,
  setSignUpForm,
  setSignUpState,
  signUpForm,
}) => {
  const handleGoogleBtnClick = () => {
    window.open(
      'https://api.gradgoggles.com/google-login',
      'mywindow',
      'location=1,status=1,scrollbars=1, width=800,height=800',
    );

    window.addEventListener('message', (message) => {
      console.log(message.data);
      if (message.data) {
        if (message.data.type === 'login') {
          // log in the user directly
          localStorage.setItem('access_token', message.data.access_token);
          localStorage.setItem('name', message.data.name);
          localStorage.setItem('photo', message.data.photo);
          localStorage.setItem('isVerified', message.data.isVerified);
          localStorage.setItem('id', message.data.id);
          localStorage.setItem('college', message.data.college); // TODO: change this to user's college
          localStorage.setItem(
            'batch',
            message.data.batch ? message.data.batch : '2021',
          );

          window.location.replace('/yearbook');
        } else {
          // display message to create an account, account does not exist
          setSignUpForm((prevSignUpForm) => ({
            ...signUpForm,
            email: message.data.email,
            firstName: message.data.name.substring(
              0,
              message.data.name.indexOf(' '),
            ),
            lastName: message.data.name.substring(
              message.data.name.indexOf(' ') + 1,
            ),
            password: 'Null',
            isGoogleAuth: true,
          }));
          setSignUpState(2);
          setToggleForm(true);
        }
      } else {
        // handle google error. ask user to try again
      }
    });
  };

  return (
    <React.Fragment>
      <div className="signUpChanger">
        <Typography
          onClick={() => {
            setToggleForm(true);
          }}
          // className={props.classes.signUpText}
        >
          Don't have an account? <a href="#">Sign Up</a>{' '}
        </Typography>
      </div>
      <div className="emailHolder">
        <TextField
          error={emailErrorHelperText === '' ? false : true}
          helperText={emailErrorHelperText}
          required
          id="email-with-icon"
          name="email"
          value={loginForm.email}
          onChange={handleLoginChange}
          placeholder="Email"
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailOutlineOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="passHolder">
        <TextField
          error={passwordHelperText === '' ? false : true}
          helperText={passwordHelperText}
          required
          onChange={handleLoginChange}
          name="password"
          value={loginForm.password}
          InputLabelProps={{ shrink: true }}
          type={passVisibility ? 'text' : 'password'}
          id="password-with-icon"
          placeholder="Password"
          variant="outlined"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              loginSubmit();
            }
          }}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment
                //     className={props.classes.iconHandler}
                onClick={() => {
                  setPassVisibility(!passVisibility);
                }}
                position="start"
              >
                {passVisibility ? (
                  <VisibilityOutlinedIcon />
                ) : (
                  <VisibilityOffOutlinedIcon />
                )}
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="forgotPasswordHolder">
        <Typography
          onClick={() => {
            setForgotPassOpen(true);
          }}
          //  className={props.classes.forgotPassword}
        >
          Forgot Password?
        </Typography>
      </div>
      <div className="logButtonHolder">
        <Button
          fullWidth
          onClick={loginSubmit}
          variant="outlined"
          size="medium"
          style={{ borderRadius: 25, textTransform: 'none' }}
          //  className={props.classes.logSignButton}
        >
          Log In
        </Button>
      </div>
      <div id="orSpan"> or </div>
      <div className="googleButton">
        <Button
          fullWidth
          onClick={handleGoogleBtnClick}
          variant="outlined"
          size="medium"
          style={{ borderRadius: 25, textTransform: 'none' }}
          //  className={props.classes.logSignButton}
          startIcon={<img src={googleLogo} alt="google_icon" width="30" />}
        >
          Log in with Google
        </Button>
      </div>
    </React.Fragment>
  );
};

export default Login;
