import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import '../../styles/new/ProfilePageComponent.css';
import { AppBar, Avatar, Snackbar } from '@material-ui/core';
import FormatQuoteRoundedIcon from '@material-ui/icons/FormatQuoteRounded';
import Button from '@material-ui/core/Button';
import userAPI from '../../utils/api/UserAPI';
import { CloseOutlined, School } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EmailIcon from '@material-ui/icons/Email';
import CakeIcon from '@material-ui/icons/Cake';
import moment from 'moment';
import ProfilePageStyles from '../../styles/mui-theme-styles/ProfilePageStyles';
import { formatDate } from '../../utils/formatDate';
import { useEffect, useState } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import UserDetails from './UserProfileModalUserDetails';
import ScrapSection from './UserProfileModalScrapSection';
import ScrapSectionSkeleton from './UserProfileModalScrapSectionSkeleton';
import { isDeviceMobile } from '../../utils/yearbookCardDimensionsUtils';
import ShareIcon from '@material-ui/icons/Share';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FormatQuoteOutlinedIcon from '@material-ui/icons/FormatQuoteOutlined';
import CakeOutlinedIcon from '@material-ui/icons/CakeOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import LanguageIcon from '@material-ui/icons/Language';
import { apiURL } from '../../utils/apiURL';
import Alert from '@material-ui/lab/Alert';
import ReactGA from 'react-ga';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const UserProfileModal = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(props.open ? props.open : false);

  const [newScrapvalue, setNewScrapValue] = useState('');
  const [loadingBackDropOpen, setLoadingBackDropOpen] = useState(true);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertMessageHeading, setAlertMessageHeading] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [user, setUser] = useState();
  const [scraps, setScraps] = useState();
  const [currentUser, setCurrentUser] = useState(props.id);
  const [history, setHistory] = useState([props.id]);

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('success');

  const [shouldStepIndexIncreased, setShouldStepIndexIncreased] = useState(
    false,
  );

  useEffect(() => {
    if (open) {
      setLoadingBackDropOpen(true);
      userAPI
        .getUserFromDBById(currentUser)
        .then((data) => {
          setUser({ ...data, dob: moment(data.dob) });
          setLoadingBackDropOpen(false);
          getScraps();
          if (shouldStepIndexIncreased) {
            props.setCardOpen(true);
            props.setRun((prevRun) =>
              props.stepIndex === 0 ? false : prevRun,
            );
            if (props.stepIndex === 8) {
              props.setStepIndex((prevStepIndex) =>
                prevStepIndex === 8 ? 9 : prevStepIndex,
              );
            } else {
              props.setStepIndex((prevStepIndex) =>
                prevStepIndex === 17 ? 18 : prevStepIndex,
              );
            }
            setShouldStepIndexIncreased(false);
          }
        })
        .catch((err) => console.log(err));
      ReactGA.event({
        category: 'User',
        action: "Opened someone's profile",
      });
    }
  }, [open, currentUser]);

  const getScraps = () => {
    userAPI
      .getScrapsFromDBById(currentUser)
      .then((data) => data.json())
      .then((scraps) => setScraps(scraps.scraps));
  };

  const handleOpen = () => {
    setOpen(true);
    setShouldStepIndexIncreased(true);
  };

  const handleClose = () => {
    setScraps(null);
    setUser(null);
    setHistory([props.id]);
    setCurrentUser(props.id);
    setOpen(false);
    if (typeof props.setIsHovered === 'function') {
      setTimeout(() => {
        // doing a setTimeout zero here to solve the bug that isHovered was again being set to true because of click method
        props.setIsHovered(false); // setTimeout zero will allow it to become true and then will make it false, solving our problem!
      }, 0);
    }
    if (props.stepIndex === 14) {
      props.setCardOpen(false);
      props.setRun(false);
    } else {
      props.setCardOpen(false);
      props.setRun((prevRun) => (props.stepIndex === 0 ? false : prevRun));
      props.setStepIndex((prevStepIndex) =>
        prevStepIndex === 21 ? 22 : prevStepIndex,
      );
    }
  };

  const handleNewUserClick = (newid) => {
    setCurrentUser(newid);
    setScraps(null);
    setHistory([...history, newid]);
  };

  const handleBackButton = () => {
    history.pop();
    setHistory(history);
    setCurrentUser(history[history.length - 1]);
    setScraps(null);
  };

  const handleSubmitScrap = (event) => {
    if (newScrapvalue.trim()) {
      const randomID = Math.floor(Math.random() * 10);
      const newScrap = {
        id: randomID,
        content: newScrapvalue,
        timestamp: Date.now(),
        posted_by: {
          id: +localStorage.getItem('id'),
          name: localStorage.getItem('name'),
          photo: localStorage.getItem('photo'),
        },
        visibility: true,
        num_of_comments: 0,
        num_of_likes: 0,
      };

      setScraps([newScrap, ...scraps]); // add newly created scrap to the top

      if (
        document.getElementsByClassName('MuiCardContent-root pp-rhs-card ')[0]
      ) {
        document
          .getElementsByClassName('MuiCardContent-root pp-rhs-card ')[0] // add smooth scrolling to desktop on new scrap addition
          .scroll({ top: 0, behavior: 'smooth' });
      }

      setNewScrapValue('');

      userAPI
        .postScrap(newScrapvalue, currentUser)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg === 'Scrap created successfully') {
            let scrapHeading = 'Scrap Creation Successful!';
            let scrapMessage = `Thank you for posting a scrap! I'm sure ${user.name} will be delighted to read your scrap. Your scrap will appear on their wall, once approved by them.`;
            setAlertMessageHeading(scrapHeading);
            setAlertMessage(scrapMessage);
            const updateNewScrap = { ...newScrap, id: data.id };
            setScraps((prevScraps) => {
              if (prevScraps) {
                return prevScraps.map((scrap) =>
                  scrap.id === newScrap.id ? updateNewScrap : scrap,
                );
              }
            });
          } else {
            let scrapHeading = 'Something Went Wrong.';
            let scrapMessage = `${data.msg}. Try again or contact your network administrator.`;
            setAlertMessageHeading(scrapHeading);
            setAlertMessage(scrapMessage);
          }
        });
      ReactGA.event({
        category: 'User',
        action: 'Posted a scrap',
      });
    }
  };

  const sendVerification = async () => {
    setDialogOpen(false);
    setLoadingBackDropOpen(true);

    const url = apiURL + 'resend_email';
    let sendVerificationPromise = await fetch(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then((res) => res.json())
      .then((resendData) => {
        setLoadingBackDropOpen(false);
        let Message, Severity;
        if (resendData !== null && resendData.msg == 'success') {
          Message = 'Verification Mail Sent Successfully!';
          Severity = 'success';
        } else {
          Message = 'Verification Mail Failure! Contact Administrator.';
          Severity = 'error';
        }

        setAlertMessage(Message);
        setAlertSeverity(Severity);
        setAlertOpen(true);
      });
    ReactGA.event({
      category: 'User',
      action: 'Asked to send verification email via scrap dialog box',
    });
  };

  const LoadingBackDrop = () => (
    <Backdrop className={props.classes.backdrop} open={loadingBackDropOpen}>
      <School className="loadingSchool" />
    </Backdrop>
  );

  let dialog = (
    <Dialog
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      open={dialogOpen}
      onClose={() => {
        setDialogOpen(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle disableTypography={true} id="alert-dialog-title">
        <h6 className="dialogHeadingCSS">{alertMessageHeading}</h6>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          disableTypography={true}
          className={props.classes.dialogMessage}
          id="alert-dialog-description"
        >
          <p className="dialogBodyCSS">{alertMessage}</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className="dialogCloseCSS"
          onClick={() => {
            sendVerification();
            setDialogOpen(false);
          }}
          color="primary"
        >
          Verify Me
        </Button>

        <Button
          className="dialogCloseCSS"
          onClick={() => {
            setDialogOpen(false);
          }}
          color="primary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  let snackBar = (
    <Snackbar
      open={alertOpen}
      autoHideDuration={6000}
      onClose={() => {
        setAlertOpen(false);
      }}
    >
      <Alert
        onClose={() => {
          setAlertOpen(false);
        }}
        severity={alertSeverity}
      >
        {alertMessage}
      </Alert>
    </Snackbar>
  );

  return (
    <div>
      <div
        className={'new-profile-modal'}
        type="button"
        onClick={handleOpen}
        style={{ cursor: 'pointer' }}
      >
        {props.children}
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: { appear: 500, enter: 500, exit: 600 },
        }}
      >
        <Fade in={open} timeout={{ appear: 500, enter: 500, exit: 600 }}>
          <div className="profilePageCard2" style={{ outline: 'none' }}>
            <LoadingBackDrop />
            {dialog}
            {user ? (
              <>
                {isDeviceMobile() ? (
                  <>
                    <Card className={'pp-mobile-container'} elevation={8}>
                      <div className="pp-mobile-top-bar">
                        <div className="pp-mobile-top-bar-left">
                          <CloseOutlined
                            className={'pp-share-icon'}
                            onClick={handleClose}
                          />
                        </div>

                        <div className="pp-mobile-top-bar-right">
                          <div className="pp-share-icon">
                            <ShareIcon
                              onClick={props.handleShareProfileClick}
                              className={'pp-mobile-share-icon'}
                            />
                          </div>
                          {props.isCloseFriend ? (
                            <StarIcon
                              className={'pp-share-icon'}
                              onClick={props.handleRemoveAsCloseFriend}
                            />
                          ) : (
                            <StarBorderIcon
                              className={'pp-share-icon'}
                              onClick={props.handleAddAsCloseFriend}
                            />
                          )}
                        </div>
                      </div>

                      <div className="pp-mobile-user-intro">
                        <Avatar
                          className="pp-mobile-user-photo"
                          src={user.photo}
                          alt={user.name}
                        />

                        <div className="pp-mobile-user-name">{user.name}</div>

                        <div className="pp-mobile-user-dept">{user.dept}</div>

                        <div className="pp-mobile-user-quote">{user.quote}</div>
                      </div>

                      <div className="pp-user-details-tabs pp-mobile-tabs">
                        <MobileDetailsAndScrapsTabs
                          user={user}
                          scraps={scraps}
                          handleNewUserClick={handleNewUserClick}
                          handleSubmitScrap={handleSubmitScrap}
                          newScrapvalue={newScrapvalue}
                          setNewScrapValue={setNewScrapValue}
                          setStepIndex={props.setStepIndex}
                          setScraps={setScraps}
                        />
                      </div>
                    </Card>
                  </>
                ) : (
                  <>
                    <Card className={props.classes.root} elevation={8}>
                      <div className={props.classes.cover}>
                        <CardMedia
                          className={props.classes.image}
                          image={props.profileBgImage}
                          height="80vh"
                        />

                        <div className={props.classes.profile}>
                          {history.length > 1 ? (
                            <div className="pp-history-back">
                              <ArrowBackIcon onClick={handleBackButton} />
                            </div>
                          ) : (
                            <></>
                          )}

                          <div className="pp-lhs-img-wrapper">
                            <img src={user.photo} alt={user.name} />
                          </div>
                        </div>
                      </div>

                      <div className={props.classes.content}>
                        <CardContent className="pp-rhs-card">
                          <UserDetails
                            user={user}
                            handleShareProfileClick={
                              props.handleShareProfileClick
                            }
                            isCloseFriend={props.isCloseFriend}
                            handleAddAsCloseFriend={
                              props.handleAddAsCloseFriend
                            }
                            handleRemoveAsCloseFriend={
                              props.handleRemoveAsCloseFriend
                            }
                            handleClose={handleClose}
                          />
                          {scraps ? (
                            <ScrapSection
                              scraps={scraps}
                              handleNewUserClick={handleNewUserClick}
                              handleSubmitScrap={handleSubmitScrap}
                              newScrapValue={newScrapvalue}
                              setNewScrapValue={setNewScrapValue}
                              name={user.name}
                              setScraps={setScraps}
                            />
                          ) : (
                            <ScrapSectionSkeleton />
                          )}
                        </CardContent>
                      </div>
                    </Card>
                  </>
                )}
                {snackBar}
              </>
            ) : null}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default withStyles(ProfilePageStyles, { withTheme: true })(
  UserProfileModal,
);

const MobileDetailsAndScrapsTabs = ({
  user,
  scraps,
  handleNewUserClick,
  handleSubmitScrap,
  newScrapvalue,
  setNewScrapValue,
  setStepIndex,
  setScraps,
}) => {
  const [currentActiveTab, setCurrentActiveTab] = useState(0);
  return (
    <div className={'pp-mobile-user-tabs'}>
      <AppBar position={'static'}>
        <Tabs
          value={currentActiveTab}
          onChange={(e, newValue) => {
            setCurrentActiveTab(newValue);
            if (newValue === 1) {
              setStepIndex((prevStepIndex) =>
                prevStepIndex === 9 ? 10 : prevStepIndex,
              );
            }
          }}
        >
          <Tab label={'About'} />
          <Tab label={'Scraps'} id="scraps-mobile" />
        </Tabs>
      </AppBar>
      <TabPanel value={currentActiveTab} index={0}>
        <UserAboutMobile dob={user.dob} email={user.email} user={user} />
      </TabPanel>
      <TabPanel value={currentActiveTab} index={1}>
        <UserScrapsMobile
          name={user.name}
          handleNewUserClick={handleNewUserClick}
          handleSubmitScrap={handleSubmitScrap}
          newScrapvalue={newScrapvalue}
          scraps={scraps}
          setNewScrapValue={setNewScrapValue}
          setScraps={setScraps}
        />
      </TabPanel>
    </div>
  );
};

const UserAboutMobile = ({ dob, email, user }) => {
  const areAnySocialProfiles =
    user.social_web ||
    user.social_twitter ||
    user.social_insta ||
    user.social_linkedin;
  return (
    <div className={'pp-mobile-user-about'}>
      <div className="pp-user-bday-container">
        <div className="pp-user-bday-icon">
          <CakeOutlinedIcon />
        </div>
        <div className="pp-user-bday">{formatDate(dob)}</div>
      </div>
      <div className="pp-user-bday-container">
        <div className="pp-user-email-icon">
          <MailOutlineIcon />
        </div>
        <a href={`mailto:${email}`} target={'_blank'}>
          <div className="pp-user-email">{email.toLowerCase()}</div>
        </a>
      </div>
      {areAnySocialProfiles ? (
        <>
          {user.social_insta != 'null' && (
            <div className="pp-user-bday-container">
              <div className="pp-user-email-icon">
                <InstagramIcon />{' '}
              </div>

              <div className={'social-links-text'}>
                <a href={user.social_insta} target={'_blank'}>
                  {user.name}
                </a>
              </div>
            </div>
          )}

          {user.social_twitter != 'null' && (
            <div className="pp-user-bday-container">
              <div className="pp-user-email-icon">
                <TwitterIcon />
              </div>
              <div className={'social-links-text'}>
                <a href={user.social_twitter} target={'_blank'}>
                  {user.name}
                </a>
              </div>
            </div>
          )}

          {user.social_linkedin != 'null' && (
            <div className="pp-user-bday-container">
              <div className="pp-user-email-icon">
                <LinkedInIcon />{' '}
              </div>

              <div className={'social-links-text'}>
                <a href={user.social_linkedin} target={'_blank'}>
                  {user.name}
                </a>
              </div>
            </div>
          )}

          {user.social_web != 'null' && (
            <div className="pp-user-bday-container">
              <div className="pp-user-email-icon">
                <LanguageIcon />
              </div>

              <div className={'social-links-text'}>
                <a href={user.social_web} target={'_blank'}>
                  {user.name}
                </a>
              </div>
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

const UserScrapsMobile = ({
  scraps,
  handleNewUserClick,
  handleSubmitScrap,
  newScrapvalue,
  setNewScrapValue,
  name,
  setScraps,
}) => (
  <>
    {scraps ? (
      <ScrapSection
        scraps={scraps}
        handleNewUserClick={handleNewUserClick}
        handleSubmitScrap={handleSubmitScrap}
        newScrapValue={newScrapvalue}
        setNewScrapValue={setNewScrapValue}
        name={name}
        setScraps={setScraps}
      />
    ) : (
      <ScrapSectionSkeleton />
    )}
  </>
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}
