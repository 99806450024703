import React from 'react';
import Scrap from './Scrap';
import {
  Avatar,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { isDeviceMobile } from '../../utils/yearbookCardDimensionsUtils';

const ScrapSection = ({
  scraps,
  handleNewUserClick,
  handleSubmitScrap,
  newScrapValue,
  setNewScrapValue,
  name,
  setScraps,
}) => {
  const userFirstName = name.substring(0, name.indexOf(' '));
  const handleNewScrapValueChange = (e) => {
    setNewScrapValue(e.target.value);
  };
  return (
    <section className={'pp-scraps-section'}>
      <div className="pp-scraps-heading">Scraps</div>
      <div className="pp-scraps-container">
        {scraps.length ? (
          <>
            {scraps.map(
              (scrap) =>
                scrap.visibility && (
                  <Scrap
                    key={scrap.id}
                    scrap={scrap}
                    numOfScraps={scraps.length}
                    handleNewUserClick={handleNewUserClick}
                    setScraps={setScraps}
                  />
                ),
            )}
          </>
        ) : (
          <div className={'pp-scraps-none'}>
            {userFirstName} hasn't yet received any scrap, be the first one to
            post!
          </div>
        )}
      </div>
      <div className="pp-new-scrap">
        <TextField
          id="text-field"
          value={newScrapValue}
          placeholder={
            isDeviceMobile()
              ? `Enter your scrap here.`
              : `Enter your scrap for ${userFirstName} here.`
          }
          multiline
          onChange={handleNewScrapValueChange}
          variant={'filled'}
          InputProps={{
            startAdornment: (
              <InputAdornment position={'start'}>
                <Avatar src={localStorage.getItem('photo')} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleSubmitScrap}>
                  <SendIcon style={{ fill: '#582bee' }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
    </section>
  );
};

export default ScrapSection;
