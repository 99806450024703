const ProfilePageStyles = (theme) => ({
  root: {
    borderRadius: '15px',
    [theme.breakpoints.up(900)]: {
      display: 'flex',
      flexDirection: 'row',
      width: '80vw',
      minHeight: '624px',
      height: '90vh',
    },
    [theme.breakpoints.down(900)]: {
      display: 'flex',
      flexDirection: 'column',
      width: '80vw',
      height: '160vh',
      minHeight: '1200px',
    },
  },
  image: {
    [theme.breakpoints.up(900)]: {
      borderTopLeftRadius: '15px',
      borderBottomLeftRadius: '15px',
      minHeight: '100%',
      maxWidth: '100%',
      '&::before': {
        borderTopLeftRadius: '15px',
        borderBottomLeftRadius: '15px',
        content: "''",
        position: 'absolute',
        top: '0',
        left: '0',
        right: '50%',
        bottom: '0',
        zIndex: '1',
        background: 'rgba(0,0,0,.8)',
      },
    },
    [theme.breakpoints.down(900)]: {
      borderTopLeftRadius: '15px',
      borderTopRightRadius: '15px',
      minHeight: '500px',
      height: '80vh',
      maxWidth: '100%',
      '&::before': {
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        content: "''",
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        minHeight: '500px',
        height: '80vh',
        zIndex: '1',
        background: 'rgba(0,0,0,.8)',
      },
    },
  },
  cover: {
    [theme.breakpoints.up(900)]: {
      borderTopLeftRadius: '15px',
      borderBottomLeftRadius: '15px',
      flex: '0 1 50%',
      minHeight: '100%',
      maxWidth: '100%',
    },
    [theme.breakpoints.down(900)]: {
      borderTopLeftRadius: '15px',
      borderTopRightRadius: '15px',
      flex: '0 1 100%',
      minHeight: '500px',
      height: '80vh',
      maxWidth: '100%',
      marginTop: 'auto',
    },
  },
  content: {
    [theme.breakpoints.up(900)]: {
      borderTopLeftRadius: '15px',
      borderBottomLeftRadius: '15px',
      flex: '0 1 50%',
      minHeight: '100%',
      maxWidth: '100%',
    },
    [theme.breakpoints.down(900)]: {
      borderTopLeftRadius: '15px',
      borderTopRightRadius: '15px',
      flex: '0 1 100%',
      minHeight: '500px',
      height: '85vh',
      maxWidth: '100%',
      marginBottom: 'auto',
    },
  },

  profile: {
    [theme.breakpoints.up(900)]: {
      borderTopLeftRadius: '15px',
      borderBottomLeftRadius: '15px',
      minHeight: '100%',
      maxWidth: '100%',
      background: 'transparent',
      zIndex: '3',
      position: 'absolute',
      top: '0',
      left: '0',
      right: '50%',
      bottom: '0',
      color: '#fff',
    },

    [theme.breakpoints.down(900)]: {
      borderTopLeftRadius: '15px',
      borderTopRightRadius: '15px',
      minHeight: '500px',
      height: '80vh',
      maxWidth: '100%',
      background: 'transparent',
      zIndex: '3',
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '100%',
      color: '#fff',
    },
  },

  bottomWrapper: {
    float: 'left',
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.up(900)]: {
      height: '25%',
    },
    [theme.breakpoints.down(900)]: {
      height: '25%',
    },
  },
  emailIconWrapper: {
    float: 'left',
    width: '50%',
    textAlign: 'center',
    overflowWrap: 'break-word',
  },
  calendarIconWrapper: {
    float: 'left',
    width: '50%',
    overflowWrap: 'break-word',
  },
  emailIcon: {
    [theme.breakpoints.up(900)]: {
      color: '#3caea3',
      width: theme.spacing(10),
      height: theme.spacing(10),
      margin: '0.5rem auto',
    },
    [theme.breakpoints.down(900)]: {
      color: '#3caea3',
      width: theme.spacing(7),
      height: theme.spacing(7),
      margin: '0.5rem auto',
    },
    [theme.breakpoints.down(400)]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      margin: '0.5rem auto',
    },
  },
  calendarIcon: {
    [theme.breakpoints.up(900)]: {
      color: '#3caea3',
      width: theme.spacing(10),
      height: theme.spacing(10),
      margin: '0.5rem auto',
    },
    [theme.breakpoints.down(900)]: {
      color: '#3caea3',
      width: theme.spacing(7),
      height: theme.spacing(7),
      margin: '0.5rem auto',
    },
    [theme.breakpoints.down(400)]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      margin: '0.5rem auto',
    },
  },
  textSimp: {
    fontSize: '15px',
    textAlign: 'center',
    fontFamily: "'Source Sans Pro',sans-serif",
    fontWeight: '400',
    fontStyle: 'bold',
    margin: '0.25rem',
    [theme.breakpoints.down(400)]: {
      fontSize: '12.5px',
    },

    [theme.breakpoints.up(1440)]: {
      fontSize: '20px',
    },
  },

  commentAvatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    margin: 'auto 0.5rem',
  },

  commentWriteAvatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    margin: '0.5rem',
  },
  cardDiv: {
    height: '100%',
  },
  textArea: {
    width: '100%',
    height: '50%',
  },
  backdrop: {
    zIndex: '4000',
    color: '#fff',
  },
  firstNameTitleProfile: {
    fontFamily: "'Source Sans Pro',sans-serif",
    fontWeight: '400',
    fontStyle: 'bold',
  },
  submitPassButton: {
    color: '#3caea3',
    backgroundColor: '#fff',
    borderColor: '#3caea3',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#3caea3',
    },
    dialogHeading: {
      fontFamily: "'Source Sans Pro',sans-serif!important",
      fontWeight: '700',
    },
    dialogMessage: {
      fontFamily: "'Source Sans Pro',sans-serif",
      fontWeight: '300',
    },
  },
});

export default ProfilePageStyles;
