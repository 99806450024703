import React, { Component } from 'react';
import { deleteTokens } from '../../utils/auth';

class Logout extends Component {
  render() {
    return (
      <div style={{ marginLeft: '200px' }}>
        {deleteTokens()}
        {window.location.replace('/join')}
      </div>
    );
  }
}

export default Logout;
