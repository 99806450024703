import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isLoggedIn } from '../../utils/auth';

// If user isLoggedIn, render the passed component, otherwise, redirect to Login Page
// Props expected: Component & Path of that component

class ProtectedRoute extends Component {
  render() {
    const isAuth = isLoggedIn();
    const { children, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={() =>
          isAuth ? children : <Redirect to={{ pathname: '/join' }} />
        }
      />
    );
  }
}

export default ProtectedRoute;
