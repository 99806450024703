import React, { useCallback, useEffect, useRef, useState } from 'react';
import { timeDiff } from '../../utils/timeDiff';
import { Avatar, Tooltip, Menu, MenuItem } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteOutlined';
import userAPI from '../../utils/api/UserAPI';
import ReactGA from 'react-ga';
import { MoreVertOutlined } from '@material-ui/icons';

const Scrap = ({ scrap, numOfScraps, handleNewUserClick, setScraps }) => {
  const [hasCurrentUserLiked, setHasCurrentUserLiked] = useState(false);
  const [scrapData, setScrapData] = useState(scrap); // initializing this state var from prop since we might need to change later
  const [commentsDialogOpen, setCommentsDialogOpen] = useState(false);
  const isLikeRequestHappening = useRef(true);
  const [currentUsermenuAnchorEl, setCurrentUserMenuAnchorEl] = useState(null);
  const [shouldVisibilityShowLoader, setShouldVisibilityShowLoader] = useState(
    false,
  );
  useEffect(() => {
    isLikeRequestHappening.current = true;
    const getLikes = async () => {
      await userAPI
        .getLikesOnScrap(scrapData.id)
        .then((data) => data.json())
        .then((likes) => {
          likes.forEach((like) => {
            if (like.user_id.id === +localStorage.getItem('id')) {
              setHasCurrentUserLiked(true);
            }
          });
          setScrapData((prevState) =>
            Object.assign(prevState, { likes: likes }),
          );
          isLikeRequestHappening.current = false;
        });
    };
    getLikes().catch((e) => {
      console.log(e);
    });
    // eslint-disable-next-line
  }, [scrapData]);

  useEffect(() => {
    setScrapData(scrap);
  }, [scrap]);

  const incrementNumOfComments = () => {
    setScrapData((prevScrap) => {
      return Object.assign(prevScrap, {
        num_of_comments: prevScrap.num_of_comments + 1,
      });
    });
  };

  const handleLike = () => {
    setHasCurrentUserLiked(true);

    setScrapData((prevScrap) => {
      const prevScrapLikes = prevScrap.likes ? [...prevScrap.likes] : [];
      return Object.assign(prevScrap, {
        num_of_likes: prevScrap.num_of_likes + 1,
        likes: [
          ...prevScrapLikes,
          {
            user_id: {
              id: +localStorage.getItem('id'),
              name: localStorage.getItem('name'),
            },
          },
        ],
      });
    });

    userAPI.likeScrap(scrapData.id).catch((e) => {
      console.log(e);
    });
  };

  const handleUnlike = () => {
    setHasCurrentUserLiked(false);

    setScrapData((prevScrap) =>
      Object.assign(prevScrap, {
        num_of_likes: prevScrap.num_of_likes - 1,
        likes: prevScrap.likes.filter(
          (likeObj) => likeObj.user_id.id !== +localStorage.getItem('id'),
        ),
      }),
    );

    userAPI.unlikeScrap(scrapData.id).catch((e) => {
      console.log(e);
    });
  };

  const handleLikeButtonClick = () => {
    if (hasCurrentUserLiked) {
      handleUnlike();
      ReactGA.event({
        category: 'User',
        action: 'Unliked a scrap',
      });
    } else {
      handleLike();
      ReactGA.event({
        category: 'User',
        action: 'Liked a scrap',
      });
    }
  };

  const handleDeleteScrap = (scrapID) => {
    setScraps((prevScrap) => {
      if (prevScrap) {
        return prevScrap.filter((scrap) =>
          scrap.id === scrapID ? false : true,
        );
      }
    });
    userAPI.deleteScrap(scrapID).catch((e) => {
      console.log(e);
    });
  };

  // to handle vertex menu for delete
  const handleMoreVertMenuClick = (e) => {
    setCurrentUserMenuAnchorEl(e?.currentTarget);
  };

  const handleMoreVertMenuClose = () => {
    setCurrentUserMenuAnchorEl(null);
  };

  // todo: what if the number of likes are very high
  const getScrapLikedByString = useCallback(() => {
    let scrapLikedByString = 'Liked by ';
    let isLikeEmpty = true;

    if (scrapData?.likes) {
      scrapData.likes.forEach((likeObj) => {
        scrapLikedByString =
          scrapLikedByString.concat(likeObj.user_id.name) + ', ';
        isLikeEmpty = false;
      });
    }

    return isLikeEmpty
      ? ''
      : scrapLikedByString.substring(0, scrapLikedByString.length - 2);
  }, []);

  const handleCommentButtonClick = () => {
    setCommentsDialogOpen(true);
  };

  const handleUserClick = () => {
    handleNewUserClick(scrapData.posted_by.id);
  };

  return (
    <div key={scrapData.id} className={'pp-scrap-container'}>
      <div className="pp-scrap-photo-name">
        <div className="pp-scrap-photo">
          <Avatar
            src={scrapData.posted_by.photo.replace('/800/', '/200/')}
            alt={scrapData.posted_by.name}
            onClick={() => {
              handleNewUserClick(scrapData.posted_by.id);
            }}
            style={{ cursor: 'pointer' }}
          />
        </div>
      </div>

      <div className="pp-scrap-content-container">
        <div className="pp-scrap-content">
          <span className="pp-scrap-name" onClick={handleUserClick}>
            {scrapData.posted_by.name.substring(
              0,
              scrapData.posted_by.name.indexOf(' '),
            ) + '  '}
          </span>
          <span>{scrapData.content}</span>
          <div className="pp-scrap-likes-time-container">
            <div className="pp-scrap-likes">
              <Tooltip title={getScrapLikedByString()}>
                <IconButton
                  className={'like-icon'}
                  style={{ outline: 'none' }}
                  onClick={handleLikeButtonClick}
                >
                  {hasCurrentUserLiked ? (
                    <FavoriteOutlinedIcon />
                  ) : (
                    <FavoriteBorderOutlinedIcon />
                  )}
                  <span className="like-count">{scrapData.num_of_likes}</span>
                </IconButton>
              </Tooltip>
            </div>
            <div className="pp-scrap-time">
              {timeDiff(scrapData.timestamp, numOfScraps)}
            </div>
          </div>
        </div>

        {scrap.posted_by.id === +localStorage.getItem('id') ? (
          <>
            <IconButton
              onClick={handleMoreVertMenuClick}
              style={{
                outline: 'none',
                position: 'absolute',
                top: '1px',
                right: '1px',
                color: '#828282',
              }}
            >
              <MoreVertOutlined />
            </IconButton>
            <Menu
              open={Boolean(currentUsermenuAnchorEl)}
              anchorEl={currentUsermenuAnchorEl}
              onClose={handleMoreVertMenuClose}
              transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              <MenuItem
                onClick={() => {
                  handleMoreVertMenuClose();
                  handleDeleteScrap(scrap.id);
                }}
              >
                Delete this scrap
              </MenuItem>
            </Menu>
          </>
        ) : null}
      </div>

      {/*<ScrapCommentsDialog*/}
      {/*  openState={commentsDialogOpen}*/}
      {/*  setOpenState={setCommentsDialogOpen}*/}
      {/*  scrapID={scrapData.id}*/}
      {/*  handleUserPhotoClick={handleNewUserClick}*/}
      {/*  incrementNumOfComments={incrementNumOfComments}*/}
      {/*  isLikeRequestHappening={isLikeRequestHappening}*/}
      {/*/>*/}
    </div>
  );
};

export default Scrap;
