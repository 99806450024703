import React, { useEffect, useRef, useState } from 'react';
import '../../styles/YearbookComponent.css';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { TextField, InputAdornment, CircularProgress } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import YearbookCard from './YearbookCard';
import userAPI from '../../utils/api/UserAPI';
import { withRouter } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import Backdrop from '@material-ui/core/Backdrop';
import { School } from '@material-ui/icons';
import ggLogo from '../../assets/gg_compressed.png';
import ReactGA from 'react-ga';

const Yearbook = ({ history, collegeData, ...props }) => {
  const [searchValue, setSearchValue] = useState('');
  const [deptValue, setDeptValue] = useState('');
  const deptValueRef = useRef(''); // to use in handleScroll to ensure fresh value of state is used
  const [loadingBackdropOpen, setLoadingBackdropOpen] = useState(true);
  const [yearbookData, setYearbookData] = useState([]);
  const isMoreDataFetching = useRef(false); // to keep track of infinite scroll data fetching
  const currentPage = useRef(1);
  const [
    shouldLoadingMoreComponentDisplay,
    setShouldLoadingMoreComponentDisplay,
  ] = useState(false);
  const [hasYearbookEnded, setHasYearbookEnded] = useState(false);

  useEffect(() => {
    userAPI.fetchYearbook(1).then((data) => {
      setYearbookData(data);
      setLoadingBackdropOpen(false);
    });
    window.addEventListener('scroll', handleScroll);
    ReactGA.pageview(window.location.pathname + window.location.search);

    return () => {
      removeEventListener();
    };
  }, []);

  const removeEventListener = () => {
    window.removeEventListener('scroll', handleScroll, true);
  };

  const callSearch = () => {
    history.push(`/searchResults/${searchValue}`);
  };

  const filterOnChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleDept = async (e) => {
    setLoadingBackdropOpen(true);
    const inputDeptValue = e.target.value;

    await userAPI.fetchYearbook(1, inputDeptValue).then((data) => {
      setYearbookData(data);
      setDeptValue(inputDeptValue);
      deptValueRef.current = inputDeptValue;
      currentPage.current = 1;
      setLoadingBackdropOpen(false);
    });
  };

  const handleScroll = () => {
    if (
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.offsetHeight - 600
    ) {
      if (!isMoreDataFetching.current) {
        isMoreDataFetching.current = true;
        setShouldLoadingMoreComponentDisplay(true);
        const nextPage = currentPage.current + 1;

        // fetch next page and append to the state
        userAPI.fetchYearbook(nextPage, deptValueRef.current).then((data) => {
          if (!data.length) {
            setHasYearbookEnded(true);
            removeEventListener();
          } else {
            setYearbookData((prevData) => [...prevData, ...data]);
          }
          currentPage.current = nextPage;
          isMoreDataFetching.current = false;
          setShouldLoadingMoreComponentDisplay(false);
        });
      }
    }
  };

  if (!yearbookData.length && loadingBackdropOpen) {
    return <LoadingBackdrop isOpen={true} />;
  } else {
    return (
      <>
        <LoadingBackdrop isOpen={loadingBackdropOpen} />

        <div id="yearBookBackDrop">
          <div className="yearBookContainer">
            <div className="yearBookHeading">
              <img src={ggLogo} width="75" height="75" alt={'GradGoggles'} />
              #CLASSOF2020
            </div>
            <div className="yearBookContent">
              <div className="filterArea">
                <div className="selectBox">
                  <FormControl className="internals" variant="outlined">
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Department
                    </InputLabel>
                    <Select
                      native
                      label="Department"
                      value={deptValue}
                      onChange={handleDept}
                    >
                      <option aria-label="None" value="" />
                      {collegeData &&
                        collegeData.departments.map((dept) => (
                          <option key={dept} value={dept}>
                            {dept}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="searchBox">
                  <TextField
                    id="outlined-basic"
                    label="Search"
                    value={searchValue}
                    className="internals"
                    onChange={filterOnChange}
                    onKeyPress={(ev) => {
                      if (ev.key === 'Enter') {
                        callSearch();
                      }
                    }}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment onClick={callSearch}>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="yearbookCardArea">
                <div className="row">
                  {yearbookData.map((person) => (
                    <div
                      key={person.id}
                      className={'col-xl-4 col-lg-6 col-md-12'}
                    >
                      <YearbookCard
                        id={person.id}
                        user={person}
                        profileBgImage={collegeData?.profile_bg}
                      />
                    </div>
                  ))}
                </div>
              </div>
              {(hasYearbookEnded || !yearbookData.length) && <EndOfYearbook />}
              {shouldLoadingMoreComponentDisplay && <LoadingMore />}
            </div>
            <div style={{ clear: 'both' }} />
          </div>
        </div>
      </>
    );
  }
};

const LoadingBackdrop = ({ isOpen }) => (
  <Backdrop className="backdropIn" open={isOpen}>
    <School className="loadingSchool" />
  </Backdrop>
);

const LoadingMore = () => (
  <div className={'loading-more'}>
    <div className="loading-more-circle">
      <CircularProgress />
    </div>
    <div className="loading-more-text">Hang On, Loading the Yearbook ...</div>
  </div>
);

const EndOfYearbook = () => (
  <div className={'loading-more'}>
    <div className="loading-more-text">
      Looks like you've reached the end U_U
    </div>
  </div>
);

export default withRouter(Yearbook);
