import React, { useEffect, useState } from 'react';
import {
  Badge,
  Card,
  CardActionArea,
  CardHeader,
  CircularProgress,
  Popover,
  Tooltip,
} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import userAPI from '../utils/api/UserAPI';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import { Refresh } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

const Notifications = () => {
  const [userNotifications, setUserNotifications] = useState(null);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [popoverAnchor, setPopoverAnchor] = useState(null);

  useEffect(() => {
    userAPI.userUnreadNotifications().then((d) => {
      setUnreadNotifications(+d);
    });
  }, []);

  const getAndSetUserNotifications = () => {
    userAPI.getUserNotifications().then((d) => {
      setUserNotifications(d);
      console.log(d);
    });
  };

  const handleClick = (e) => {
    setPopoverAnchor(e.currentTarget);
    getAndSetUserNotifications();
    setUnreadNotifications(0);
    userAPI.updateNotificationsLastReadTime().catch((e) => {
      console.log(e);
    });
    ReactGA.event({
      category: 'User',
      action: 'Clicked on Notifications IconButton',
    });
  };

  const handleClose = () => {
    setPopoverAnchor(null);
  };

  const handleNotifRefresh = () => {
    setUserNotifications(null);
    getAndSetUserNotifications();
    ReactGA.event({
      category: 'User',
      action: 'Refreshed Notifications',
    });
  };

  const open = Boolean(popoverAnchor);

  return (
    <div className="header-notification-icon">
      <Tooltip title={'Notifications'}>
        <IconButton
          style={{ border: 'none', outline: 'none' }}
          onClick={handleClick}
        >
          <Badge badgeContent={unreadNotifications} color={'primary'}>
            <NotificationsIcon style={{ height: '36px', width: '36px' }} />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={popoverAnchor}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'center', horizontal: 'right' }}
        className={'header-notification-popover'}
      >
        <div className="header-notifs-container">
          <CardHeader
            title={'Notifications'}
            action={
              <IconButton
                onClick={handleNotifRefresh}
                style={{ outline: 'none' }}
              >
                <Refresh />
              </IconButton>
            }
          />
          {userNotifications ? (
            <>
              {userNotifications.length ? (
                <>
                  {userNotifications.map((notif) => (
                    <SingleNotification
                      key={notif.id}
                      payload_string={notif.payload_json}
                    />
                  ))}
                </>
              ) : (
                <>
                  <div className={'notif-loader'}>
                    You don't have any notifications yet ...
                  </div>
                </>
              )}
            </>
          ) : (
            <div className={'notif-loader'}>
              <CircularProgress />
              <div className={'notif-loader-text'}>
                Loading your notifications ...
              </div>
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
};

export default Notifications;

const SingleNotification = ({ payload_string }) => {
  const payload = JSON.parse(payload_string);

  return (
    <>
      <Card className="notif-single">
        <Link to={getNotifLink(payload)}>
          <CardActionArea className={'notif-single-action'}>
            <div className="notif-image">
              <Avatar src={getNotifImageSrc(payload)} alt={'GG'} />
            </div>
            <div className="notif-body">{getNotifBody(payload)}</div>
          </CardActionArea>
        </Link>
      </Card>
    </>
  );
};

const getNotifLink = (payload) => {
  if (payload.type === 'new_user') {
    return '/yearbook';
  } else if (payload.type === 'like_scrap') {
    return `/yearbook?id=${payload.link_id}`;
  } else if (payload.type === 'new_scrap') {
    return `/profile`;
  } else if (payload.type === 'like_mcw_post') {
    return `/my-college-wall`;
  }
};

const getNotifImageSrc = (payload) => {
  if (payload.type === 'new_user') {
    return 'https://d1577j21c01vef.cloudfront.net/logo512.png';
  } else {
    return payload.picture;
  }
};

const getNotifBody = (payload) => {
  if (payload.type === 'like_scrap') {
    if (payload.posted_to_name) {
      return `${payload.user_name} liked a scrap you posted on ${payload.posted_to_name}'s profile.`;
    } else {
      return `${payload.user_name} liked a scrap you posted.`;
    }
  } else if (payload.type === 'new_scrap') {
    return `${payload.user_name} posted a new scrap on your profile.`;
  } else if (payload.type === 'like_mcw_post') {
    return `${payload.user_name} liked a note you posted on the college wall.`;
  } else {
    return `Welcome to GradGoggles! Get started by posting a scrap on your friend's profile.`;
  }
};
