import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';
import NavigateNextTwoToneIcon from '@material-ui/icons/NavigateNextTwoTone';
import NavigateBeforeTwoToneIcon from '@material-ui/icons/NavigateBeforeTwoTone';
import { IconButton } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'sticky',
    top: 0,
    zIndex: 100,
    backgroundColor: 'white',
    justifyContent: 'flex-start',
    marginTop: '12px',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const Categories = ({
  categories,
  selectedcategory,
  setSelectedCategory,
  setLoadingBackdropOpen,
}) => {
  const [shouldLeftArrowDisplay, setShouldLeftArrowDisplay] = useState(false);
  const scrollContainerRef = useRef();

  useEffect(() => {
    if (scrollContainerRef.current.scrollLeft != 0) {
      setShouldLeftArrowDisplay(true);
    }
  }, []);

  const handleClick = (category) => {
    setLoadingBackdropOpen(true);
    setSelectedCategory(category);
  };

  const handleLeftScroll = () => {
    scrollContainerRef.current.scrollLeft += 100;

    setShouldLeftArrowDisplay(true);
  };

  const handleRightScroll = () => {
    scrollContainerRef.current.scrollLeft -= 100;

    if (scrollContainerRef.current.scrollLeft - 100 <= 0) {
      setShouldLeftArrowDisplay(false);
    }
  };

  return (
    <>
      <div className={'dept-chips-wrapper'}>
        <div
          className="dept-chips-left-scroll"
          style={shouldLeftArrowDisplay ? null : { display: 'none' }}
        >
          <IconButton
            className={'dept-chips-left-arrow'}
            onClick={handleRightScroll}
            size="medium"
            style={{ outline: 'none' }}
          >
            <NavigateBeforeTwoToneIcon fontSize="inherit" />
          </IconButton>
        </div>
        <div className="dept-chips-scroll-container" ref={scrollContainerRef}>
          {categories &&
            categories.map((category) => (
              <Chip
                label={category}
                key={category}
                clickable
                size="medium"
                style={
                  category === selectedcategory
                    ? {
                        background:
                          'linear-gradient(90.86deg, #582BEE 0.74%, #9848E9 96.22%)',
                        color: 'white',
                        fontWeight: '500',
                      }
                    : { backgroundColor: '#e2e2e2d6' }
                }
                onClick={() => handleClick(category)}
                className={
                  category === selectedcategory ? null : 'yb-dept-chip'
                }
              />
            ))}
        </div>
        <div className="dept-chips-right-scroll">
          <IconButton
            aria-label="delete"
            className={'dept-chips-right-arrow'}
            onClick={handleLeftScroll}
            size="medium"
            style={{ outline: 'none' }}
          >
            <NavigateNextTwoToneIcon fontSize="inherit" />
          </IconButton>
        </div>
      </div>
    </>
  );
};

export default Categories;
