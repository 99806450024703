export async function getCollegeData(collegeName) {
  if (collegeName) {
    const url = `https://d1577j21c01vef.cloudfront.net/colleges/${collegeName}.json`;

    return await fetch(url)
      .then((data) => data.json())
      .catch((e) => {
        console.log(e);
      });
  } else {
    return null;
  }
}

export const collegeList = [
  {
    id: 'bits_goa',
    name: 'BITS Pilani Goa Campus',
    short: 'BITS Goa',
  },
  {
    id: 'seema_d',
    name: 'SDCH Rishikesh, Uttarakhand',
    short: 'SDCH Rishikesh, Uttarakhand',
  },
  {
    id: 'vit',
    name: 'Vishwakarma Institute of Technology',
    short: 'VIT Pune',
  },
  {
    id: 'seema_d',
    name: 'ILS Law College, Pune',
    short: 'ILS Pune',
  },
  {
    id: 'seema_d',
    name: 'Symbiosis Viman Nagar Campus',
    short: 'Symbiosis Viman Nagar',
  },
  {
    id: 'bits_goa',
    name: 'Veermata Jijabai Technological Institute, Mumbai',
    short: 'VJTI Mumbai',
  },
];
