import React, { Component } from 'react';
import '../styles/LandingScrap.css';
import gradCap from '../assets/gg_compressed.png';
import tedx from '../assets/amaze.jpg';
import fc from '../assets/FC.jpg';
import melange from '../assets/melange.jpg';
import hallway from '../assets/hallway.jpg';
import homeBuilding from '../assets/homeBuilding.svg';
import havingFun from '../assets/havingFun.svg';
import relaxo from '../assets/relaxo.svg';
import moments from '../assets/moments.svg';
import { Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

class Home extends Component {
  _isMounted = false;
  state = {};

  constructor() {
    super();
    let fixDate = new Date(2016, 7, 1, 0, 0, 0, 0);
    let currDate = new Date();
    this.state = { fixDate, diff: currDate - fixDate, currentActive: 1 };
    this.toggleActive = this.toggleActive.bind(this);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toggleActive(number) {
    this.setState((prevState) => ({
      currentActive: number,
    }));
  }

  componentDidMount() {
    this._isMounted = true;
    setInterval(() => {
      this.setState((prevState) => ({
        diff: new Date().getTime() - prevState.fixDate,
      }));
    }, 1000);
  }

  render() {
    const { diff } = this.state;
    const secs = Math.floor(diff / 1000);

    return (
      <React.Fragment>
        <main id="homeContent" role="main">
          <section className="landingSection">
            <div className="left">
              <img
                src={gradCap}
                width="200"
                className="page-icon icon"
                alt=""
              />
              <h2 className="title">#ClassOf2020</h2>

              <div className="section-intro cms-text">
                <p>
                  From Kenny to Corona, this batch has seen it all. As we
                  graduate in these uncertain times, let's reflect back on the
                  four years that gave us so much. Let's celebrate all the fun
                  times, all the events, all the submissions, all the love, joy
                  and even frustration, and every unique thing that is straight
                  out of 666 Upper Indira Nagar.{' '}
                </p>

                <p>
                  Graduation Goggles is a small attempt to give you the
                  experience of a Yearbook and a Virtual Scribble Day.
                </p>
                <div className="homepageButtons">
                  <Button
                    onClick={() => {
                      this.props.history.push('/join');
                    }}
                    className="getStartedButton"
                    variant="outlined"
                  >
                    GET STARTED
                  </Button>
                  <a
                    target={'_blank'}
                    href="https://play.google.com/store/apps/details?id=com.team.android.gradgoggles&hl=en"
                    rel="noreferrer"
                  >
                    <img
                      className="googleplay"
                      alt="Get it on Google Play"
                      src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                    />
                  </a>
                </div>

                <p>
                  <strong>Update:</strong>{' '}
                  <a
                    href={
                      'https://play.google.com/store/apps/details?id=com.team.android.gradgoggles&hl=en'
                    }
                    className={'playLink'}
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    {' '}
                    Our Android App is live on the Google Play Store!
                  </a>
                </p>
              </div>
              <div className="itineraries">
                <p className="itineraries-heading">Your Journey</p>
                <ul>
                  <li>
                    <a>{secs} Seconds</a>
                  </li>
                  <li>
                    <a>400+ Submissions</a>
                  </li>
                  <li>
                    <a>30+ Festivals</a>
                  </li>
                  <li>
                    <a>
                      <i style={{ fontSize: '30px', margin: 0, padding: 0 }}>
                        &infin;
                      </i>{' '}
                      Memories
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="right">
              <div className="images">
                <div
                  className={`responsive image landscape restrict-width  ${
                    this.state.currentActive === 1 ? 'current' : ''
                  }`}
                >
                  <div className="aspect-maintainer">
                    <div>
                      <img
                        className="landscape "
                        src={tedx}
                        alt=""
                        sizes="(max-width: 667px) 100vw, (min-width: 668px) 64.58vw"
                        title=""
                        width="930"
                        height="680"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`responsive image landscape restrict-width  ${
                    this.state.currentActive === 2 ? 'current' : ''
                  }`}
                >
                  <div className="aspect-maintainer">
                    <div>
                      <img
                        className="landscape "
                        src={fc}
                        alt=""
                        sizes="(max-width: 667px) 100vw, (min-width: 668px) 64.58vw"
                        title=""
                        width="930"
                        height="680"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`responsive image landscape restrict-width  ${
                    this.state.currentActive === 3 ? 'current' : ''
                  }`}
                >
                  <div className="aspect-maintainer">
                    <div>
                      <img
                        className="landscape "
                        src={hallway}
                        alt=""
                        sizes="(max-width: 667px) 100vw, (min-width: 668px) 64.58vw"
                        title=""
                        width="930"
                        height="680"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`responsive image landscape restrict-width  ${
                    this.state.currentActive === 4 ? 'current' : ''
                  }`}
                >
                  <div className="aspect-maintainer">
                    <div>
                      <img
                        className="landscape "
                        src={melange}
                        alt=""
                        sizes="(max-width: 667px) 100vw, (min-width: 668px) 64.58vw"
                        title=""
                        width="930"
                        height="680"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <nav className="category-links">
                <div
                  id="numeroUno"
                  className={`category-item ${
                    this.state.currentActive === 1 ? 'current' : ''
                  }`}
                  onMouseEnter={() => this.toggleActive(1)}
                >
                  <a className="hover-link">
                    <p className="title">
                      <span>A Home</span>
                    </p>
                    <div className="icon-wrap">
                      <img
                        src={homeBuilding}
                        alt=""
                        width="100"
                        className="page-icon icon"
                      />
                    </div>
                  </a>
                  <div className="category-item-text">
                    <p className="heading">Leave Only Footprints</p>
                  </div>
                </div>
                <div
                  id="numeroDos"
                  className={`category-item ${
                    this.state.currentActive === 2 ? 'current' : ''
                  }`}
                  onMouseEnter={() => this.toggleActive(2)}
                >
                  <a className="hover-link">
                    <p className="title">
                      <span>A Hangout</span>
                    </p>
                    <div className="icon-wrap">
                      <img
                        src={havingFun}
                        alt=""
                        width="100"
                        className="page-icon icon"
                      />
                    </div>
                  </a>
                  <div className="category-item-text">
                    <p className="heading">Leave Only Laughs</p>
                  </div>
                </div>
                <div
                  id="numeroTres"
                  className={`category-item ${
                    this.state.currentActive === 3 ? 'current' : ''
                  }`}
                  onMouseEnter={() => this.toggleActive(3)}
                >
                  <a className="hover-link">
                    <p className="title">
                      <span>A Story</span>
                    </p>
                    <div className="icon-wrap">
                      <img
                        src={relaxo}
                        alt=""
                        width="100"
                        className="page-icon icon"
                      />
                    </div>
                  </a>
                  <div className="category-item-text">
                    <p className="heading">Leave Only The Journey</p>
                  </div>
                </div>
                <div
                  id="numeroCuatro"
                  className={`category-item ${
                    this.state.currentActive === 4 ? 'current' : ''
                  }`}
                  onMouseEnter={() => this.toggleActive(4)}
                >
                  <a className="hover-link">
                    <p className="title">
                      <span>A Feeling</span>
                    </p>
                    <div className="icon-wrap">
                      <img
                        src={moments}
                        alt=""
                        width="100"
                        className="page-icon icon"
                      />
                    </div>
                  </a>
                  <div className="category-item-text">
                    <p className="heading">Leave Only Memories</p>
                  </div>
                </div>
              </nav>
            </div>
          </section>
        </main>
        <footer className="footer"> © 2020 Team GradGoggles</footer>
      </React.Fragment>
    );
  }
}

export default withRouter(Home);
