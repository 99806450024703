import { apiURL } from '../apiURL';
import { formatDate } from '../formatDate';

class UserAPI {
  constructor(initialState) {
    this.state = initialState;
    this.listeners = [];
  }

  userProfilesCache = {};
  yearbookCache = {};

  async postScrap(scrapText, id) {
    const url = apiURL + `createScrap`;

    let formData = new FormData();
    formData.append('posted_to_id', id);
    formData.append('content', scrapText);
    formData.append('college', localStorage.getItem('college'));

    return await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: formData,
    }).catch((err) => console.log(err));
  }

  async getUserFromDBById(id) {
    if (id in this.userProfilesCache) {
      return this.userProfilesCache[id];
    } else {
      const url =
        apiURL +
        `user?id=${id}&name=1&photo=1&email=1&GRNo=1&dept=1&dob=1&quote=1&college=${localStorage.getItem(
          'college',
        )}`;
      let formData = new FormData();
      formData.append('id', id);

      const userProfileData = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then((data) => data.json())
        .catch((err) => console.log(err));

      this.userProfilesCache[id] = userProfileData;
      return userProfileData;
    }
  }

  async getScrapsFromDBById(id) {
    const url =
      apiURL +
      `user?id=${id}&scraps=1&college=${localStorage.getItem('college')}`;

    let formData = new FormData();

    formData.append('id', id);

    return await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }).catch((err) => console.log(err));
  }

  async getScraps(id) {
    const url =
      apiURL + `user?scraps=1&college=${localStorage.getItem('college')}`;

    return await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }).catch((err) => console.log(err));
  }

  async getCurrentUser() {
    const url =
      apiURL +
      `user?name=1&photo=1&email=1&GRNo=1&dept=1&dob=1&quote=1&college=${localStorage.getItem(
        'college',
      )}`;

    return await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }).catch((err) => console.log(err));
  }

  async getCurrentUserAndScraps() {
    const url =
      apiURL +
      'user?name=1&photo=1&email=1&GRNo=1&dept=1&dob=1&quote=1&scraps=1' +
      `&college=${localStorage.getItem('college')}`;

    return await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }).catch((err) => console.log(err));
  }

  async searchTerm(searchText) {
    const url =
      apiURL +
      `search?query=${searchText}&college=${localStorage.getItem('college')}`;

    return await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }).catch((err) => console.log(err));
  }

  async fetchYearbook(pageNo, deptValue) {
    let url;
    const cacheKey = deptValue + pageNo;

    if (cacheKey in this.yearbookCache) {
      return this.yearbookCache[cacheKey];
    } else {
      if (deptValue === null || deptValue === '' || deptValue === undefined) {
        url =
          apiURL +
          `users?page=${pageNo}&college=${localStorage.getItem('college')}`;
      } else {
        url =
          apiURL +
          `users?dept=${deptValue}&page=${pageNo}&college=${localStorage.getItem(
            'college',
          )}`;
      }

      const ybData = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then((data) => data.json())
        .catch((err) => console.log(err));

      this.yearbookCache[cacheKey] = ybData;

      return ybData;
    }
  }

  async fetchCloseFriends() {
    let url;

    url = apiURL + 'users/close-friends';

    return await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }).catch((err) => console.log(err));
  }

  async toggleScrapVisibility(id) {
    let url = apiURL + 'toggleScrapVisibility';

    let formData = new FormData();

    formData.append('id', id);

    return await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: formData,
    }).catch((err) => console.log(err));
  }

  async deleteScrap(id) {
    let url = apiURL + 'deleteScrap';

    let formData = new FormData();

    formData.append('id', id);

    return await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: formData,
    }).catch((err) => console.log(err));
  }

  async updateCurrentUser(userBean) {
    let url = apiURL + `user`;

    let formData = new FormData();
    let data = userBean;

    formData.append('name', data.name);
    formData.append('dept', data.dept);
    formData.append('dob', formatDate(data.dob));
    formData.append('quote', data.quote);
    formData.append('social_insta', data.social_insta);
    formData.append('social_twitter', data.social_twitter);
    formData.append('social_linkedin', data.social_linkedin);
    formData.append('social_web', data.social_web);
    formData.append(
      'photo',
      data.photo.replace(
        'gradgoggles-images-uploaded.s3.amazonaws.com',
        'd1577j21c01vef.cloudfront.net/800',
      ),
    );
    if (data.dept_photo) {
      formData.append(
        'dept_photo',
        data.dept_photo.replace(
          'gradgoggles-images-uploaded.s3.amazonaws.com',
          'd1577j21c01vef.cloudfront.net/800',
        ),
      );
    }
    formData.append('college', localStorage.getItem('college'));

    localStorage.setItem('name', data.name);
    localStorage.setItem('photo', data.photo);

    return await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: formData,
    }).catch((err) => console.log(err));
  }

  async likeScrap(scrapID) {
    const url = `${apiURL}/scrap/like`;

    let formData = new FormData();

    formData.append('scrap_id', scrapID);

    return await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: formData,
    }).catch((err) => console.log(err));
  }

  async unlikeScrap(scrapID) {
    const url = `${apiURL}/scrap/like`;

    let formData = new FormData();

    formData.append('scrap_id', scrapID);

    return await fetch(url, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: formData,
    }).catch((err) => console.log(err));
  }

  async addCommentToScrap(scrapID, comment) {
    const url = `${apiURL}/scrap/comment`;

    let formData = new FormData();

    formData.append('scrap_id', scrapID);
    formData.append('comment', comment);

    return await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: formData,
    }).catch((err) => console.log(err));
  }

  async getCommentsOnScrap(scrapID) {
    const url = apiURL + `/scrap/comment?scrap_id=${scrapID}`;

    return await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }).catch((err) => console.log(err));
  }

  async getLikesOnScrap(scrapID) {
    const url = apiURL + `/scrap/like?scrap_id=${scrapID}`;

    return await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }).catch((err) => console.log(err));
  }

  async likeMyCWPost(postID) {
    const url = `${apiURL}/my-college-wall/post/like`;

    let formData = new FormData();

    formData.append('cw_post_id', postID);

    return await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: formData,
    }).catch((err) => console.log(err));
  }

  async unlikeMyCWPost(postID) {
    const url = `${apiURL}/my-college-wall/post/like`;

    let formData = new FormData();

    formData.append('cw_post_id', postID);

    return await fetch(url, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: formData,
    }).catch((err) => console.log(err));
  }

  async addCloseFriend(userID) {
    const url = `${apiURL}/user/close-friends`;

    let formData = new FormData();

    formData.append('user_id', userID);

    return await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: formData,
    }).catch((err) => console.log(err));
  }

  async getCloseFriends() {
    const url = apiURL + `/user/close-friends`;

    return await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then((data) => data.json())
      .catch((err) => console.log(err));
  }

  async removeCloseFriend(userID) {
    const url = `${apiURL}/user/close-friends`;

    let formData = new FormData();

    formData.append('user_id', userID);

    return await fetch(url, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: formData,
    }).catch((err) => console.log(err));
  }

  async fetchdeptPicture(dept) {
    let url = `${apiURL}/user/dept-picture`;

    if (dept) {
      url = url + `?dept=${encodeURIComponent(dept)}`;
    }

    return await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }).catch((err) => console.log(err));
  }

  async getMyCWPosts() {
    const url = `${apiURL}/my-college-wall`;
    return await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then((d) => d.json())
      .catch((err) => console.log(err));
  }

  async addMyCWPost(type, content, imgURL) {
    const url = `${apiURL}/my-college-wall`;
    const imageURL = imgURL ? imgURL : '';

    let formData = new FormData();

    formData.append('type', type);
    formData.append('content', content);
    formData.append('imgURL', imageURL);

    return await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: formData,
    })
      .then((d) => d.json())
      .catch((err) => console.log(err));
  }

  async deleteMyCWPost(postID) {
    const url = `${apiURL}/my-college-wall`;

    let formData = new FormData();

    formData.append('post_id', postID);

    return await fetch(url, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: formData,
    })
      .then((d) => d.json())
      .catch((err) => console.log(err));
  }

  async getUserNotifications() {
    const url = `${apiURL}/user/notifications`;
    return await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then((d) => d.json())
      .catch((err) => console.log(err));
  }

  async userUnreadNotifications() {
    const url = `${apiURL}/user/notifications/has-unread-notifications`;
    return await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then((d) => d.json())
      .catch((err) => console.log(err));
  }

  async updateNotificationsLastReadTime() {
    const url = `${apiURL}/user/notifications/update-last-read-time`;
    return await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then((d) => d.json())
      .catch((err) => console.log(err));
  }
}

const userAPI = new UserAPI([]);

export default userAPI;
