import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { collegeList } from '../../../utils/api/collegeDataAPI';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from '@material-ui/core';

const SelectCollegeDialogBox = ({
  isSelectCollegeDialogBoxOpen,
  setIsSelectCollegeDialogBoxOpen,
  setCurrentCollege,
}) => {
  const [selectedCollege, setSelectedCollege] = useState('bits_goa');

  const handleSelectedCollegeChange = (e) => {
    setSelectedCollege(e.target.value);
  };

  const handleSelectCollegeSubmit = () => {
    setCurrentCollege(selectedCollege);
    setIsSelectCollegeDialogBoxOpen(false);
  };

  return (
    <Dialog
      open={isSelectCollegeDialogBoxOpen}
      onClose={() => {
        setIsSelectCollegeDialogBoxOpen(false);
      }}
    >
      <DialogTitle>Select your College</DialogTitle>
      <DialogContent>
        <p style={{ marginBottom: '10px', color: '#555' }}>
          Currently, we are only accepting entries from BITS Goa. If you wish to
          have GradGoggles in your campus,
          <a
            href={
              'https://form.typeform.com/to/Nthlirwg?typeform-medium=embed-snippet'
            }
            target={'_blank'}
            style={{ color: '#582bee' }}
          >
            {' '}
            contact us.
          </a>
        </p>
        <select
          name={'college'}
          className={'auth-select-college'}
          onChange={handleSelectedCollegeChange}
          value={selectedCollege}
        >
          {collegeList.map((college) => (
            <option value={college.id} key={college.id}>
              {college.name}
            </option>
          ))}
        </select>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleSelectCollegeSubmit}>
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectCollegeDialogBox;
