import React from 'react';
import Header from './home-page/Header';

const TermsOfService = () => {
  return (
    <>
      <Header />
      <div className={'tos-container'} style={{ padding: '25px 25%' }}>
        <h2 style={{ fontSize: '28px', padding: '10px 0' }}>
          Terms of Service
        </h2>
        <h2>
          <span className="c4">1. Introduction</span>
        </h2>
        <p className="c2">
          <span className="c1">
            GradGoggles Terms And Conditions (these &ldquo;Terms&rdquo; or these
            &ldquo;GradGoggles Standard Terms And Conditions&rdquo;) contained
            herein on this webpage, shall govern your use of this website,
            including all pages within this website (collectively referred to
            herein below as GradGoggles). These terms apply in full force and
            effect to your use of Gradgoggles and by using Gradgoggles, you
            expressly accept all terms and conditions contained herein in full.
            You must not use Gradgoggles, if you have any objection to any of
            these Gradoggles Standard Terms And Conditions.
          </span>
        </p>
        <p className="c2">
          <span className="c1">
            Gradgoggles is not for use by any minors (defined as those who are
            not at least 18 years of age), and you must not use Gradgoggles if
            you are a minor.
          </span>
        </p>
        <h2 className="c6" id="h.kp0muonwmixw">
          <span className="c4">2. Intellectual Property Rights</span>
        </h2>
        <p className="c2">
          <span className="c1">
            Other than the content you own, which you may have opted to include
            on Gradgoggles, under these terms, Gradgoggles and/or its licensors
            own all rights to the intellectual property and material contained
            in this website, and all such rights are reserved. You are granted a
            limited license only, subject to the restrictions provided in these
            terms, for purposes of viewing the material contained on this
            website.
          </span>
        </p>
        <h2 className="c6" id="h.b56ks4kq7ht">
          <span className="c4">3. Restrictions</span>
        </h2>
        <p className="c2">
          <span className="c1">
            You are expressly and emphatically restricted from all of the
            following:
          </span>
        </p>
        <ol className="c7 lst-kix_qx8agsp96ec-0 start" start="1">
          <li className="c0 li-bullet-0">
            <span className="c1">
              selling, sublicensing and/or otherwise commercializing any Website
              material;
            </span>
          </li>
          <li className="c0 li-bullet-0">
            <span className="c1">
              using GradGoggles in any way that is, or maybe, damaging to this
              Website;
            </span>
          </li>
          <li className="c0 li-bullet-0">
            <span className="c1">
              using GradGoggles in any way that impacts user access to this
              Website;
            </span>
          </li>
          <li className="c0 li-bullet-0">
            <span className="c1">
              using GradGoggles contrary to applicable laws and regulations, or
              in a way that causes, or may cause, harm to GradGoggles, or to any
              person or business entity;
            </span>
          </li>
          <li className="c0 li-bullet-0">
            <span className="c1">
              engaging in any data mining, data harvesting, data extracting or
              any other similar activity in relation to GradGoggles, or while
              using GradGoggles;
            </span>
          </li>
          <li className="c0 li-bullet-0">
            <span className="c1">
              using GradGoggles to engage in any advertising or marketing;
            </span>
          </li>
        </ol>
        <p className="c2">
          <span className="c1">
            Certain areas of GradGoggles are restricted from access by you and
            the GradGoggles team may further restrict access by you to any areas
            of this website, at any time, at its sole and absolute discretion.
            Any user ID and password you may have for GradGoggles are
            confidential and you must maintain the confidentiality of such
            information.
          </span>
        </p>
        <h2 className="c6" id="h.vl3bdjxwfkr0">
          <span className="c4">4. Your Content</span>
        </h2>
        <p className="c2">
          <span className="c1">
            In these GradGoggles Standard Terms And Conditions, &ldquo;Your
            Content&rdquo; shall mean any audio, video, text, images or other
            material you choose to display on GradGoggles. With respect to Your
            Content, by displaying it, you grant GradGoggles a non-exclusive,
            worldwide, irrevocable, royalty-free, sublicensable license to use,
            reproduce, adapt, publish, translate and distribute it in any and
            all media.
          </span>
        </p>
        <p className="c2">
          <span className="c1">
            Your Content must be your own and must not be infringing on any
            third party&rsquo;s rights. The GradGoggles team reserves the right
            to remove any of Your Content from this Website at any time, and for
            any reason, without notice.
          </span>
        </p>
        <h2 className="c6" id="h.mpbkeb69lqbu">
          <span className="c4">5. No warranties</span>
        </h2>
        <p className="c2">
          <span className="c3">GradGoggles</span>
          <span className="c1">
            &nbsp;is provided &ldquo;as is,&rdquo; with all faults, and the
            GradGoggles team makes no express or implied representations or
            warranties, of any kind related to GradGoggles or the materials
            contained on this website. Additionally, nothing contained on
            GradGoggles shall be construed as providing consult or advice to
            you.
          </span>
        </p>
        <h2 className="c6" id="h.xir9oorao163">
          <span className="c4">6. Limitation of liability</span>
        </h2>
        <p className="c2">
          <span className="c1">
            In no event shall Gradgoggles, nor any of its officers, directors
            and employees, be liable to you for anything arising out of or in
            any way connected with your use of GradGoggles, whether such
            liability is under contract, tort or otherwise, and GradGoggles,
            including its officers, directors and employees shall not be liable
            for any indirect, consequential or special liability arising out of
            or in any way related to your use of GradGoggles.
          </span>
        </p>
        <h2 className="c6" id="h.nv2asuts6eky">
          <span className="c4">7. Indemnification</span>
        </h2>
        <p className="c2">
          <span className="c1">
            You hereby indemnify to the fullest extent Gradgoggles from and
            against any and all liabilities, costs, demands, causes of action,
            damages and expenses (including reasonable attorney&rsquo;s fees)
            arising out of or in any way related to your breach of any of the
            provisions of these terms.
          </span>
        </p>
        <h2 className="c6" id="h.qd0innqdrgor">
          <span className="c4">8. Severability</span>
        </h2>
        <p className="c2">
          <span className="c1">
            If any provision of these terms is found to be unenforceable or
            invalid under any applicable law, such unenforceability or
            invalidity shall not render these terms unenforceable or invalid as
            a whole, and such provisions shall be deleted without affecting the
            remaining provisions herein.
          </span>
        </p>
        <h2 className="c6" id="h.qh8q2xfp2w0r">
          <span className="c4">9. Variation of Terms</span>
        </h2>
        <p className="c2">
          <span className="c3">GradGoggles</span>
          <span className="c1">
            &nbsp;is permitted to revise these terms at any time as it sees fit,
            and by using this website you are expected to review such terms on a
            regular basis to ensure you understand all terms and conditions
            governing the use of this website.
          </span>
        </p>
        <h2 className="c6" id="h.hmgkcndavdtl">
          <span className="c4">10. Assignment</span>
        </h2>
        <p className="c2">
          <span className="c3">GradGoggles</span>
          <span className="c1">
            &nbsp;shall be permitted to assign, transfer, and subcontract its
            rights and/or obligations under these terms without any notification
            or consent required. However, you shall not be permitted to assign,
            transfer, or subcontract any of your rights and/or obligations under
            these terms.
          </span>
        </p>
        <h2 className="c6" id="h.9sa3tdta223n">
          <span className="c4">11. Entire Agreement</span>
        </h2>
        <p className="c2">
          <span className="c1">
            These terms, including any legal notices and disclaimers contained
            on GradGoggles, constitute the entire agreement between GradGoggles
            and you in relation to your use of GradGoggles and supersede all
            prior agreements and understandings with respect to the same.
          </span>
        </p>
        <h2 className="c6" id="h.m2x9rx2ygtds">
          <span className="c4">12. Governing Law &amp; Jurisdiction</span>
        </h2>
        <p className="c2">
          <span className="c1">
            These Terms will be governed by and construed in accordance with the
            laws of India, and you submit to the non-exclusive jurisdiction of
            the state courts located in Maharashtra for the resolution of any
            disputes.
          </span>
        </p>
        <p className="c9">
          <span className="c8"></span>
        </p>
      </div>
    </>
  );
};

export default TermsOfService;
