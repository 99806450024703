import React, { useEffect, useRef, useState } from 'react';

import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  STATUS,
  Step,
} from 'react-joyride';
import useWindowDimensions from '../utils/windowDimension';

const DialogBoxNew = ({
  cardOpen,
  setCardOpen,
  stepIndex,
  setStepIndex,
  run,
  setRun,
  setHelpBtnClicked,
}) => {
  const isOpenRef = useRef(cardOpen);
  // localStorage.setItem('isFirstTime', 'no');
  const { height, width } = useWindowDimensions();
  useEffect(() => {
    isOpenRef.current = cardOpen;
  }, [cardOpen]);

  const handleJoyrideCallback = (CallBackProps) => {
    const { action, index, type, status } = CallBackProps;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setRun(false);
      setHelpBtnClicked(false);
      setStepIndex(0);
      localStorage.setItem('isFirstTime', 'no');
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      if (index == 0 && width >= 1024) {
        setStepIndex((prevStepIndex) =>
          prevStepIndex === 0 ? 15 : prevStepIndex,
        );
      } else if (index === 11 && width <= 1024) {
        setStepIndex((prevStepIndex) =>
          prevStepIndex === 11 ? 13 : prevStepIndex,
        );
      } else {
        setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
      }
    }
  };

  const steps = [
    {
      content: (
        <div className={'tutorial-text'}>
          <h1 style={{ fontWeight: 'bold', color: '#582bee' }}>
            Welcome to GradGoggles!
          </h1>
          <br></br>
          <p>
            Congratulations, you made it! You're a star for getting through your
            years at college. We know this is not how you imagined to conclude
            your journey, but we hope Gradgoggles can be a small celebration of
            this milestone that you've achieved. Let it be a memento for the
            future and a medal for the past.
          </p>
          <br></br>
          <p>
            To help you navigate, let us quickly go through the different things
            you can do on Gradgoggles.
          </p>
          <br></br>
          <p>
            <b>We strongly recommend</b> that you go through this guide in order
            to avoid any confusion or hiccups as you use our website.
          </p>
        </div>
      ),
      locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: 'center',
      target: 'body',
      hideCloseButton: true,
    },
    // for mobile view
    {
      content: (
        <div className={'tutorial-text'}>
          <p>Lets start with the menu first. Click on the hamburger!</p>
        </div>
      ),
      spotlightPadding: 20,
      target: '#menu-icon-mobile',
      hideCloseButton: true,
      spotlightClicks: true,
      placement: 'bottom',
      hideFooter: true,
    },
    {
      content: (
        <div className={'tutorial-text'}>
          <p>
            The main reason we are here, right? The <b>Yearbook Page</b> lets
            you see other students from your college just like a traditional
            yearbook. Do not forget to make your quote stand out.
          </p>
        </div>
      ),
      spotlightPadding: 0,
      target: '#yearbook-dialog-mobile',
      hideCloseButton: true,
      placement: 'bottom',
    },
    {
      content: (
        <div className={'tutorial-text'}>
          <p>
            The college wall is a place for you to reminisce your thoughts about
            the place where you spent a great deal of time.
          </p>
          <br />
          <p>
            Tell us your{' '}
            <b>
              favourite part about your college, or a memory that stands out
            </b>
            , by posting on the college wall.
          </p>
        </div>
      ),
      spotlightPadding: 20,
      target: '#college-wall-dialog-mobile',
      styles: {
        options: {
          zIndex: 100000,
        },
      },
      hideCloseButton: true,
      placement: 'bottom',
      scrollOffset: 0,
    },
    {
      content: (
        <div className={'tutorial-text'}>
          <p>
            The quintessential <b>department photo</b> without which any
            farewell is incomplete. .
          </p>
          <br />
          <p>
            You can click on the "Department Photo" link on the header to view
            it.
          </p>
          <br />
          <p>You also have an option to download the photo.</p>
        </div>
      ),
      spotlightPadding: 20,
      target: '#department-pic-dialog-mobile',
      styles: {
        options: {
          zIndex: 100000,
        },
      },
      hideCloseButton: true,
      placement: 'bottom',
      scrollOffset: 0,
    },
    {
      content: (
        <div className={'tutorial-text'}>
          <p>
            You can look at new notifications by hitting the bell icon in the
            navigation menu whenever somebody posts a scrap or likes/comments on
            yours.
          </p>
          <br />
          <p>
            You can also edit your details, quote, and picture by click on your
            avatar.
          </p>
          <br />
        </div>
      ),
      spotlightPadding: 20,
      target: '#my-profile-mobile',
      styles: {
        options: {
          zIndex: 100000,
        },
      },
      hideCloseButton: true,
      placement: 'top',
      scrollOffset: 0,
    },
    {
      content: (
        <div className={'tutorial-text'}>
          <p>Click on (X) to close the menu.</p>
        </div>
      ),
      spotlightPadding: 20,
      target: '#close-icon-mobile',
      hideCloseButton: true,
      placement: 'bottom',
      spotlightClicks: true,
      hideFooter: true,
    },
    {
      content: (
        <div className={'tutorial-text'}>
          <p>
            If you're looking for a particular person, you can search for them
            using their name.
          </p>
        </div>
      ),
      spotlightPadding: 10,
      target: '.yb-search-box',
      hideCloseButton: true,
    },
    {
      content: (
        <div className={'tutorial-text'}>
          <p>
            To view more about a person or to leave a scrap for them,{' '}
            <b>click on their card.</b>
          </p>
        </div>
      ),
      spotlightPadding: 10,
      target: '.new-profile-modal',
      placement: 'top',
      scrollOffset: 0,
      spotlightClicks: true,
      hideFooter: true,
      hideCloseButton: true,
    },
    {
      content: (
        <div className={'tutorial-text'}>
          <p>
            <b>Click on the scraps</b>
          </p>
        </div>
      ),
      spotlightPadding: 10,
      target: '#scraps-mobile',
      placement: 'top',
      spotlightClicks: true,
      hideFooter: true,
      hideCloseButton: true,
    },
    {
      content: (
        <div className={'tutorial-text'}>
          <p>
            Scrap is our version of scribbling a message for your fellow batch
            mates. While we would have loved to do this in person, the scraps
            provide an exciting alternative to do the same.
          </p>
        </div>
      ),

      hideCloseButton: true,
      target: '.pp-scraps-heading',
      placement: 'top',
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
    {
      content: (
        <div className={'tutorial-text'}>
          <p>
            You can leave a fond memory about a friend, a short excerpt of your
            time with them and see what they think about it.
          </p>
        </div>
      ),
      spotlightPadding: 10,
      target: '.pp-scrap-container',
      placement: 'left',
      hideCloseButton: true,
      spotlightClicks: true,
    },
    {
      content: (
        <div className={'tutorial-text'}>
          <p>
            You can also reply or like the scraps that have been sent to you.
          </p>
        </div>
      ),
      spotlightPadding: 10,
      target: '#text-field',
      placement: 'top',
      hideCloseButton: true,
    },
    {
      content: (
        <div className={'tutorial-text'}>
          <p>
            On our Yearbook page, when you hover/click over someone's card, you
            can use the star button to add them to your close friends list.
          </p>
          <br />
          <p>
            Close friends will now appear in a separate tab on your yearbook.
          </p>
          <br />
          <p>
            You can also <b>share the profile</b> via the same menu.
          </p>
          <br />
        </div>
      ),
      placement: 'bottom',
      target: '.pp-mobile-top-bar-right',
      spotlightClicks: true,
      hideCloseButton: true,
    },
    {
      content: (
        <div className={'tutorial-text'}>
          <p>
            Click on the <b>close (X) button</b> to close this card
          </p>
          <br />
        </div>
      ),
      placement: 'bottom',
      target: '.pp-mobile-top-bar-left',
      spotlightClicks: true,
      hideCloseButton: true,
      locale: { close: 'Close' },
      hideFooter: true,
    },
    {
      content: (
        <div className={'tutorial-text'}>
          <p>
            The main reason we are here, right? The Yearbook Page lets you see
            other students from your college just like a traditional yearbook.
            Do not forget to make your quote stand out.
          </p>
        </div>
      ),
      spotlightPadding: 20,
      target: '#yearbook-dialog',
      hideCloseButton: true,
    },
    {
      content: (
        <div className={'tutorial-text'}>
          <p>
            If you're looking for a particular person, you can search for them
            using their name.
          </p>
        </div>
      ),
      spotlightPadding: 10,
      target: '.yb-search-box',
      hideCloseButton: true,
    },
    {
      content: (
        <div className={'tutorial-text'}>
          <p>
            To view more about a person or to leave a scrap for them,{' '}
            <b>click on their card.</b>
          </p>
        </div>
      ),
      spotlightPadding: 10,
      target: '.new-profile-modal',
      placement: 'top',
      spotlightClicks: true,
      hideFooter: true,
      hideCloseButton: true,
    },
    {
      content: (
        <div className={'tutorial-text'}>
          Scrap is our version of scribbling a message for your fellow batch
          mates. While we would have loved to do this in person, the scraps
          provide an exciting alternative to do the same.
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      placement: 'left',
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      target: '.MuiCardContent-root',
    },
    {
      content: (
        <div className={'tutorial-text'}>
          <p>
            You can leave a fond memory about a friend, a short excerpt of your
            time with them and see what they think about it.
          </p>
        </div>
      ),
      spotlightPadding: 10,
      target: '.pp-scrap-content',
      placement: 'left',
      scrollOffset: 0,
      spotlightClicks: true,
      hideCloseButton: true,
    },
    {
      content: (
        <div className={'tutorial-text'}>
          <p>
            You can also reply or like the scraps that have been sent to you.
          </p>
        </div>
      ),
      spotlightPadding: 10,
      target: '.pp-new-scrap',
      placement: 'top',
      scrollOffset: 0,
      spotlightClicks: true,
      hideCloseButton: true,
    },

    {
      content: (
        <div className={'tutorial-text'}>
          <p>
            On our Yearbook page, when you hover/click over someone's card, you
            can use the star button to add them to your close friends list.
          </p>
          <br />
          <p>
            Close friends will now appear in a separate tab on your yearbook.
          </p>
          <br />
          <p>You can also share your profile via the same menu.</p>
          <br />
          <p>
            Click on the <b>close (X) button</b> to close this card
          </p>
        </div>
      ),
      placement: 'right',
      target: '.pp-share-star-icons',
      spotlightClicks: true,
      hideCloseButton: true,
      hideFooter: true,
    },
    {
      content: (
        <div className={'tutorial-text'}>
          <p>
            The college wall is a place for you to reminisce your thoughts about
            the place where you spent a great deal of time.
          </p>
          <br />
          <p>
            Tell us your favourite part about your college, or a memory that
            stands out, by posting on the college wall.
          </p>
        </div>
      ),
      spotlightPadding: 20,
      target: '#college-wall-dialog',
      styles: {
        options: {
          zIndex: 100000,
        },
      },
      hideCloseButton: true,
    },
    {
      content: (
        <div className={'tutorial-text'}>
          <p>
            The quintessential <b>department photo</b> without which any
            farewell is incomplete. .
          </p>
          <br />
          <p>
            You can click on the "Department Photo" link on the header to view
            it.
          </p>
          <br />
          <p>You also have an option to download the photo.</p>
        </div>
      ),
      spotlightPadding: 20,
      target: '#department-pic-dialog',
      styles: {
        options: {
          zIndex: 100000,
        },
      },
      hideCloseButton: true,
    },
    {
      content: (
        <div className={'tutorial-text'}>
          <p>
            You can look at new notifications by hitting the bell icon in the
            navigation menu whenever somebody posts a scrap or likes/comments on
            yours.
          </p>
          <br />
          <p>
            You can also edit your details, quote, and picture by click on your
            avatar.
          </p>
          <br />
          <p>
            We are aware that nothing is spam proof, so we've given you the
            option to hide/delete your scraps from the same place in your
            profile.
          </p>
          <br />
          <p>
            You can also change your password anytime using the profile section.
          </p>
          <br />
        </div>
      ),
      spotlightPadding: 20,
      target: '.header-notification-icon',
      styles: {
        options: {
          zIndex: 100000,
        },
      },
      hideCloseButton: true,
      locale: {
        last: 'Done',
      },
    },
  ];
  return (
    <div>
      <Joyride
        run={run}
        steps={steps}
        showSkipButton={true}
        continuous={true}
        scrollToFirstStep={true}
        stepIndex={stepIndex}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            zIndex: 1000000,
            opacity: 1,
            primaryColor: '#042F66',
          },
        }}
        hideBackButton={true}
        disableCloseOnEsc={true}
        disableOverlayClose={true}
      />
    </div>
  );
};

export default DialogBoxNew;
