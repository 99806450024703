import moment from 'moment';

export function formatDate(dateString) {
  let date = moment(dateString);
  let year = date.year();
  let month = date.month() + 1;
  let dates = date.date();
  let monthString = month,
    datestring = dates;
  if (month < 10) {
    monthString = `0${month}`;
  }
  if (dates < 10) {
    datestring = `0${dates}`;
  }
  return `${datestring}-${monthString}-${year}`;
}
