import {
  withStyles,
  ThemeProvider,
  Typography,
  TextField,
  Button,
  InputAdornment,
  Select,
  Checkbox,
  FormControlLabel,
  TextareaAutosize,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
} from '@material-ui/core';
import { AccountCircle, EditLocation } from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import demoImage from '../../../assets/demoimg.png';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 160,
  },
}));

const SignUp2 = ({
  signUpHelperText,
  handleSignUpChange,
  signUpForm,
  setSignUpState,
  handlePhotoChange,
  sameDeptPhoto,
  handleDeptPhotoChange,
  handleCheckBox,
  handlePhotoUpload,
  handleDeptUpload,
  departments,
  collegeId,
  setAlertOpen,
  setAlertMessage,
  setAlertSeverity,
}) => {
  const classes = useStyles();

  const CHARACTER_LIMIT = 140;

  const validateStep = () => {
    if (
      signUpForm.batch &&
      signUpForm.dept &&
      signUpForm.quote &&
      signUpForm.photo &&
      signUpForm.dept_photo
    ) {
      setSignUpState(3);
    } else {
      setAlertMessage('Some Fields Are Missing!');
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  return (
    <React.Fragment>
      <ArrowBackIcon
        onClick={() => {
          if (!signUpForm.isGoogleAuth) setSignUpState(1);
        }}
        className="arrowBack"
        fontSize="large"
      />
      <div>When did you graduate ?</div>
      <div className="emailHolder loginAvatarHolder" id="graduateDate">
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            required
            value={signUpForm.batch}
            displayEmpty
            name="batch"
            onChange={handleSignUpChange}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value="" disabled>
              Year
            </MenuItem>
            <MenuItem value="2021">2021</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            required
            value={signUpForm.dept}
            displayEmpty
            name="dept"
            onChange={handleSignUpChange}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value="" disabled>
              Department
            </MenuItem>
            {departments
              ? departments.map((dept) => (
                  <MenuItem value={dept} key={dept}>
                    {dept}
                  </MenuItem>
                ))
              : null}
          </Select>
        </FormControl>
      </div>
      <div className="photo-Container">
        <div className="photo-ContainerLeft">
          <div>Pic for Yearbook</div>
          <div className="loginAvatarDiv">
            <img
              src={signUpForm.photo ? signUpForm.photo : demoImage}
              className="login-profile-img"
              alt="profilepic"
              onClick={handlePhotoUpload}
              style={{
                cursor: 'pointer',
                objectFit: 'cover',
                width: '100px',
                height: '140px',
                borderRadius: '8px',
              }}
            />

            <input
              type="file"
              onChange={handlePhotoChange}
              name="photo"
              id="hiddenPhotoInput"
              className="hiddenInputEdit"
            />
          </div>
        </div>
        <div className="photo-ContainerRight">
          <div className="emailHolder loginAvatarHolder">
            <div id="dept-pic">Pic for Dept Photo</div>
            <div className="loginAvatarDiv">
              <img
                src={signUpForm.dept_photo ? signUpForm.dept_photo : demoImage}
                className="login-dept-img"
                alt="deptpic"
                onClick={handleDeptUpload}
                style={{
                  cursor: 'pointer',
                  objectFit: 'cover',
                  width: '100px',
                  height: '140px',
                  borderRadius: '8px',
                }}
              />

              <input
                type="file"
                onChange={handleDeptPhotoChange}
                name="dept_photo"
                id="hiddenDeptInput"
                className="hiddenInputEdit"
              />
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={sameDeptPhoto}
                  onChange={() => handleCheckBox(!sameDeptPhoto)}
                  name="deptPhotoCheck"
                  size="small"
                />
              }
              label="Same as yearbook photo"
            />
          </div>
        </div>
      </div>

      <div className="emailHolder quoteHolder">
        <label className="quoteLabel">
          Enter your Quote!{' '}
          <span style={{ color: '#888' }}>
            ({signUpForm.quote.length}/{CHARACTER_LIMIT})
          </span>
        </label>

        <TextareaAutosize
          error={signUpHelperText.quote === '' ? 'false' : 'true'}
          helpertext={signUpHelperText.quote}
          required
          onChange={handleSignUpChange}
          name="quote"
          value={signUpForm.quote}
          rowsMax={3}
          placeholder="A line that sums up your college experience in maximum 140 characters."
          id="qouteArea"
          maxLength={CHARACTER_LIMIT}
        />
      </div>

      <div className="next-btn">
        <Button
          onClick={validateStep}
          fullWidth
          variant="outlined"
          size="medium"
          style={{ borderRadius: 25, textTransform: 'none' }}
        >
          Next
        </Button>
      </div>
    </React.Fragment>
  );
};

export default SignUp2;
