import React, { useEffect, useRef, useState } from 'react';
import YearbookHeader from './YearbookHeader';
import tw from 'twin.macro';
import '../../../styles/new/Yearbook.css';
import YearbookCollegeAndSearchSection from './YearbookCollegeAndSearchSection';
import YearbookCard from './YearbookCard';
import userAPI from '../../../utils/api/UserAPI';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';
import ShareProfileDialog from './ShareProfileDialog';
import YearbookCardSkeleton from './YearbookCardSkeleton';
import {
  getYearbookHeightWidth,
  isDeviceMobile,
} from '../../../utils/yearbookCardDimensionsUtils';
import UserProfileModal from '../UserProfileModal';
import Categories from './Categories';
import DialogBoxNew from '../../DialogBox_new';

const StyledDiv = tw.div`font-display min-h-screen`;

const [ybCardWidth, ybCardHeight] = getYearbookHeightWidth();

const Yearbook = ({ location, history, collegeData }) => {
  const [searchValue, setSearchValue] = useState('');
  const [deptValue, setDeptValue] = useState('');
  const deptValueRef = useRef(''); // to use in handleScroll to ensure fresh value of state is used
  const [loadingBackdropOpen, setLoadingBackdropOpen] = useState(true);
  const [yearbookData, setYearbookData] = useState([]);
  const isMoreDataFetching = useRef(false); // to keep track of infinite scroll data fetching
  const currentPage = useRef(1);
  const [
    shouldLoadingMoreComponentDisplay,
    setShouldLoadingMoreComponentDisplay,
  ] = useState(false);
  const [hasYearbookEnded, setHasYearbookEnded] = useState(false);
  const [closeFriends, setCloseFriends] = useState([]);

  const [isShareProfileDialogOpen, setIsShareProfileDialogOpen] = useState(
    false,
  );
  const [currentShareProfileID, setCurrentShareProfileID] = useState(undefined); // to keep track of which profileID should be showed in ShareProfileDialog

  const [open, setOpen] = useState(false);

  const [shareId, setShareId] = useState();

  const [selectedcategory, setSelectedCategory] = useState('All');

  const selectedCategoryRef = useRef('All');
  const [cardOpen, setCardOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [run, setRun] = useState(true);
  const [helpBtnClicked, setHelpBtnClicked] = useState(false);
  const isFirstTime = localStorage.getItem('isFirstTime');
  const categories = collegeData
    ? ['All', 'Close Friends', ...collegeData.departments]
    : [];

  if (location.search.includes('?id=')) {
    const id_exists = location.search.replace('?id=', '');

    if (id_exists && !open) {
      setShareId(id_exists);
      setOpen(true);
    }
  }

  useEffect(() => {
    userAPI.getCloseFriends().then((closeFriends) => {
      setCloseFriends(closeFriends);
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
    window.addEventListener('scroll', handleScroll);

    return () => {
      removeEventListener();
    };
  }, []);

  useEffect(() => {
    currentPage.current = 1;
    selectedCategoryRef.current = selectedcategory;
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    let param = selectedcategory;

    if (hasYearbookEnded) {
      window.addEventListener('scroll', handleScroll);
      setHasYearbookEnded(false); // to fix "looks like yearbook has ended.." showing randomly to reset it to false
    }

    setYearbookData([]); // to set data to null once new chip(department) is selected
    setLoadingBackdropOpen(true);

    if (param === 'Close Friends') {
      userAPI
        .fetchCloseFriends()
        .then((res) => res.json())
        .then((data) => {
          setYearbookData(data);
          setLoadingBackdropOpen(false);
        });
    } else {
      if (param === 'All') {
        param = '';
      }
      userAPI.fetchYearbook(1, param).then((data) => {
        setYearbookData(data);
        setLoadingBackdropOpen(false);
      });
    }
  }, [selectedcategory]);

  const handleHelpToggle = () => {
    setHelpBtnClicked(true);
    setStepIndex(0);
    setRun(true);
  };

  const removeEventListener = () => {
    window.removeEventListener('scroll', handleScroll);
  };

  const callSearch = () => {
    history.push(`/searchResults/${searchValue}`);
    ReactGA.event({
      category: 'User',
      action: 'Used the search bar',
    });
  };

  const handleDept = async (e) => {
    setLoadingBackdropOpen(true);
    const inputDeptValue = e.target.value;
    setLoadingBackdropOpen(true);

    await userAPI.fetchYearbook(1, inputDeptValue).then((data) => {
      setYearbookData(data);
      setDeptValue(inputDeptValue);
      deptValueRef.current = inputDeptValue;
      currentPage.current = 1;
      setLoadingBackdropOpen(false);
    });
  };

  const handleScroll = () => {
    if (
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.offsetHeight - 600
    ) {
      if (!isMoreDataFetching.current) {
        isMoreDataFetching.current = true;
        setShouldLoadingMoreComponentDisplay(true);
        const nextPage = currentPage.current + 1;
        // fetch next page and append to the state
        let param = selectedCategoryRef.current;
        if (param === 'All') {
          param = '';
        }

        userAPI.fetchYearbook(nextPage, param).then((data) => {
          if (!data.length) {
            setHasYearbookEnded(true);
            removeEventListener();
          } else {
            setYearbookData((prevData) => [...prevData, ...data]);
          }
          currentPage.current = nextPage;
          isMoreDataFetching.current = false;
          setShouldLoadingMoreComponentDisplay(false);
        });
      }
    }
  };

  const handleShareProfileDialogOpen = async (profileID) => {
    setCurrentShareProfileID(profileID);
    if (isDeviceMobile()) {
      const shareData = {
        title: 'GradGoggles',
        text: 'Checkout this profile!',
        url: `https://gradgoggles.com/yearbook?id=${profileID}`,
      };
      await navigator.share(shareData);
    } else {
      setIsShareProfileDialogOpen(true);
    }
    ReactGA.event({
      category: 'User',
      action: 'Opened ShareProfile DialogBox',
    });
  };

  return (
    <StyledDiv>
      {(isFirstTime === 'yes' || helpBtnClicked) && (
        <DialogBoxNew
          cardOpen={cardOpen}
          setCardOpen={setCardOpen}
          stepIndex={stepIndex}
          setStepIndex={setStepIndex}
          run={run}
          setRun={setRun}
          setHelpBtnClicked={setHelpBtnClicked}
        />
      )}
      <YearbookHeader
        setStepIndex={setStepIndex}
        stepIndex={stepIndex}
        handleHelpToggle={handleHelpToggle}
      />
      <YearbookCollegeAndSearchSection
        collegeData={collegeData}
        setSearchValue={setSearchValue}
        callSearch={callSearch}
      />
      {categories.length > 3 && (
        <Categories
          categories={categories}
          selectedcategory={selectedcategory}
          setSelectedCategory={setSelectedCategory}
          setLoadingBackdropOpen={setLoadingBackdropOpen}
        />
      )}
      <div className="yb-cards">
        {!loadingBackdropOpen ? (
          <>
            {yearbookData.map((profile) => (
              <YearbookCard
                id={profile.id}
                key={profile.id}
                profile={profile}
                profileBgImage={collegeData?.profile_bg}
                isCloseFriend={isUserCloseFriend(profile.id, closeFriends)}
                openShareProfileDialog={handleShareProfileDialogOpen}
                ybCardWidth={ybCardWidth}
                ybCardHeight={ybCardHeight}
                cardOpen={cardOpen}
                setCardOpen={setCardOpen}
                setStepIndex={setStepIndex}
                stepIndex={stepIndex}
                run={run}
                setRun={setRun}
              />
            ))}
          </>
        ) : (
          <>{<YearbookSkeletonPage />}</>
        )}

        {shouldLoadingMoreComponentDisplay && <YearbookSkeletonPage />}
      </div>
      {(hasYearbookEnded || !yearbookData.length) && !loadingBackdropOpen && (
        <>
          <EndOfYearbook />
        </>
      )}
      <ShareProfileDialog
        handleClose={() => {
          setIsShareProfileDialogOpen(false);
        }}
        isDialogOpen={isShareProfileDialogOpen}
        profileID={currentShareProfileID}
      />
      {shareId && (
        <UserProfileModal
          open={open}
          id={shareId}
          profileBgImage={collegeData?.profile_bg}
          isCloseFriend={isUserCloseFriend(shareId, closeFriends)}
          handleShareProfileClick={handleShareProfileDialogOpen}
        />
      )}
    </StyledDiv>
  );
};

export default withRouter(Yearbook);

const EndOfYearbook = () => (
  <div className={'loading-more'}>
    <div className="loading-more-text">
      Looks like you've reached the end of the Yearbook. Come back when more
      people have registered.
    </div>
  </div>
);

const YearbookSkeletonPage = () =>
  [1, 2, 3, 4, 5, 6, 7, 8].map((item) => <YearbookCardSkeleton key={item} />);

const isUserCloseFriend = (userID, closeFriends) => {
  let isCloseFriend = false;
  for (let i = 0; i < closeFriends.length; i++) {
    if (closeFriends[i] == userID) {
      isCloseFriend = true;
      break;
    }
  }
  return isCloseFriend;
};
