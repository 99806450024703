import React, { Component } from 'react';
import '../styles/HowTo.css';
import howto from '../assets/How to Guide For Grad Goggles.pdf';

class HowTo extends Component {
  state = {};

  render() {
    return (
      <React.Fragment>
        <div className="howToButtonHolder">
          <a href={howto} target="_self" download className="howToButton">
            Download The How To Guide
          </a>
        </div>
        <footer className="footer">© 2020 Team GradGoggles</footer>
      </React.Fragment>
    );
  }
}

export default HowTo;
