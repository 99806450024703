import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  isDeviceMobile,
  getYearbookHeightWidth,
} from '../../../utils/yearbookCardDimensionsUtils';

const [mobileSkeletonWidth, mobileSkeletonHeight] = getYearbookHeightWidth();

const YearbookCardSkeleton = () => {
  return (
    <div className={'yb-card'}>
      <div className={'new-profile-modal'}>
        <div
          className="yb-card-content-container"
          style={isDeviceMobile() ? { width: mobileSkeletonWidth } : null}
        >
          <div className="yb-card-photo">
            <Skeleton
              width={isDeviceMobile() ? mobileSkeletonWidth : 250}
              height={isDeviceMobile() ? mobileSkeletonHeight : 350}
            />
            <div className="yb-card-quoname-container">
              <div className="yb-card-user-name">
                <Skeleton count={2} />
              </div>
              <div className="yb-card-user-quote">
                <Skeleton count={3} />
              </div>
            </div>
          </div>

          <div className="yb-card-below-quote" />
        </div>
      </div>
    </div>
  );
};

export default YearbookCardSkeleton;
