import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import LandingScrap from './components/Home';
import ProfilePageComponent from './components/yearbook-components/ProfilePage';
import PersonalProfileComponent from './components/CurrentUserProfile';
import YearbookComponent from './components/yearbook-components/Yearbook';
import Yearbook from './components/yearbook-components/new/Yearbook';
import SearchResultsComponent from './components/yearbook-components/SearchResults';
import LoginSignUpComponent from './components/auth-components/LoginSignUpComponent';
import { isLoggedIn } from './utils/auth';
import ProtectedRoute from './components/auth-components/ProtectedRoute';
import Logout from './components/auth-components/Logout';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import HowTo from './components/HowTo';
import ReactGA from 'react-ga';
import { getCollegeData } from './utils/api/collegeDataAPI';
import NewHome from './components/home-page';
import DepartmentPic from './components/DepartmentPic';
import MyCollegeWall from './components/MyCollegeWall';
import LoginSignUpParent from './components/auth-components/new/LoginSignUpParent';
import PrivacyPolicy from './components/PrivacyPolicy.';
import TermsOfService from './components/TermsOfService';

ReactGA.initialize('UA-195596350-1');

function App() {
  const [collegeData, setCollegeData] = useState(null);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    getCollegeData(localStorage.getItem('college')).then((data) => {
      setCollegeData(data);
    });
  }, []);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Router>
        <Switch>
          {/*new UI components that will replace the old ones later*/}
          <Route exact path="/" component={NewHome} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/terms-of-service" component={TermsOfService} />
          <ProtectedRoute path="/yearbook">
            <Yearbook collegeData={collegeData} />
          </ProtectedRoute>
          <ProtectedRoute path="/department-pic">
            <DepartmentPic collegeData={collegeData} />
          </ProtectedRoute>
          <ProtectedRoute path="/my-college-wall">
            <MyCollegeWall collegeData={collegeData} />
          </ProtectedRoute>

          {/*old UI components*/}
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/oldhome" component={LandingScrap} />
          <Route
            exact
            path="/join"
            render={(props) => <LoginSignUpParent {...props} />}
          />
          <Route exact path="/howTo" component={HowTo} />
          <ProtectedRoute exact path="/profile">
            <PersonalProfileComponent collegeData={collegeData} />
          </ProtectedRoute>
          <ProtectedRoute path="/oldyearbook">
            <YearbookComponent collegeData={collegeData} />
          </ProtectedRoute>
          <ProtectedRoute exact path="/profiles/:id">
            <ProfilePageComponent />
          </ProtectedRoute>
          <ProtectedRoute exact path="/searchResults/:searchTerm">
            <SearchResultsComponent collegeData={collegeData} />
          </ProtectedRoute>
          <Redirect to="/" />
        </Switch>
      </Router>
    </MuiPickersUtilsProvider>
  );
}

export default App;
