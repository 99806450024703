export function isLoggedIn(){
    return localStorage.getItem("access_token")!==null && localStorage.getItem("access_token")!=="undefined";
}

//for Sign Out
export function deleteTokens(){
    localStorage.removeItem("access_token");
    localStorage.removeItem("name");
    localStorage.removeItem("photo");
    localStorage.removeItem("is2020");
    localStorage.removeItem("isVerified");
    localStorage.removeItem("id");
}

export function requiredAuth(nextState, replace){
    if (!isLoggedIn){
        replace({
            pathname: '/',
            state: {
                nextPathname:nextState.location.pathname
            }
        })
    }
}