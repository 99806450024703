import {
  withStyles,
  ThemeProvider,
  Typography,
  TextField,
  Button,
  InputAdornment,
} from '@material-ui/core';
import {
  AccountCircle,
  School,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import React from 'react';
import googleLogo from '../../../assets/googleIcon.svg';

const SignUp1 = ({
  signUpHelperText,
  handleSignUpChange,
  signUpForm,
  passVisibility,
  setPassVisibility,
  setSignUpState,
  setToggleForm,
  setAlertOpen,
  setAlertMessage,
  setAlertSeverity,
  setSignUpForm,
  collegeId,
}) => {
  const handleGoogleBtnClick = () => {
    window.open(
      'https://api1.gradgoggles.com/google-login',
      'mywindow',
      'location=1,status=1,scrollbars=1, width=800,height=800',
    );

    const setSignupStateUsingOAuthDetails = (message) => {
      setSignUpForm((prevSignUpForm) => ({
        ...signUpForm,
        email: message.data.email,
        firstName: message.data.name.substring(
          0,
          message.data.name.indexOf(' '),
        ),
        lastName: message.data.name.substring(
          message.data.name.indexOf(' ') + 1,
        ),
        password: 'Null',
        isGoogleAuth: true,
      }));
    };

    window.addEventListener('message', (message) => {
      console.log(message.data);
      if (message.data) {
        if (message.data.type === 'login') {
          // log in the user directly
          // log in the user directly
          localStorage.setItem('access_token', message.data.access_token);
          localStorage.setItem('name', message.data.name);
          localStorage.setItem('photo', message.data.photo);
          localStorage.setItem('isVerified', message.data.isVerified);
          localStorage.setItem('id', message.data.id);
          localStorage.setItem('college', message.data.college); // TODO: change this to user's college
          localStorage.setItem(
            'batch',
            message.data.batch ? message.data.batch : '2021',
          );

          window.location.replace('/yearbook');
        } else {
          var patt = /@goa.bits-pilani.ac.in/;
          if (patt.test(message.data.email)) {
            if (
              message.data.email.includes('2017') ||
              message.data.email.includes('2019')
            ) {
              setSignupStateUsingOAuthDetails(message);
              setSignUpState(2);
            } else {
              setAlertMessage(`You are not eligible for the yearbook 2021`);
              setAlertSeverity('error');
              setAlertOpen(true);
            }
          } else if (collegeId === 'seema_d') {
            // add Seema College Id and add validation code if there is any specific mail id to be validated
            setSignupStateUsingOAuthDetails(message);
            setSignUpState(2);
          } else if (collegeId === 'vit') {
            var patt = /@vit.edu/;
            if (patt.test(message.data.email)) {
              if (
                message.data.email.includes('18') ||
                message.data.email.includes('17') ||
                message.data.email.includes('19')
              ) {
                setSignupStateUsingOAuthDetails(message);
                setSignUpState(2);
              } else {
                setAlertMessage(
                  `You are not eligible for the Batch of 2021 Yearbook`,
                );
                setAlertSeverity('error');
                setAlertOpen(true);
              }
            } else {
              setAlertMessage(`Please user your @vit.edu Google account`);
              setAlertSeverity('error');
              setAlertOpen(true);
            }
          } else {
            setAlertMessage(
              `Sorry! We are currently only accepting entries from selected colleges. If you wish to have GradGoggles in your campus, contact us.`,
            );
            setAlertSeverity('error');
            setAlertOpen(true);
          }
        }
      } else {
        // handle google error. ask user to try again
      }
    });
  };

  const validateStep = () => {
    if (
      signUpForm.firstName &&
      signUpForm.lastName &&
      signUpForm.email &&
      signUpForm.password
    ) {
      validateEmailandCollege();
    } else {
      setAlertMessage('Some Fields Are Missing!');
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const validateEmailandCollege = () => {
    if (collegeId === 'bits_goa') {
      var patt = /@goa.bits-pilani.ac.in/;
      if (patt.test(signUpForm.email)) {
        if (
          signUpForm.email.includes('2017') ||
          signUpForm.email.includes('2019')
        ) {
          setSignUpState(2);
        } else {
          setAlertMessage(`You are not eligible for the yearbook 2021`);
          setAlertSeverity('error');
          setAlertOpen(true);
        }
      } else {
        setAlertMessage(`Please enter your @goa.bits-pilani.ac.in mail id`);
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    } else if (collegeId === 'seema_d') {
      // add Seema College Id and add validation code if there is any specific mail id to be validated
      setSignUpState(2);
    } else if (collegeId === 'vit') {
      var patt = /@vit.edu/;
      if (patt.test(signUpForm.email)) {
        if (
          signUpForm.email.includes('18') ||
          signUpForm.email.includes('17') ||
          signUpForm.email.includes('19')
        ) {
          setSignUpState(2);
        } else {
          setAlertMessage(
            `You are not eligible for the Batch of 2021 Yearbook`,
          );
          setAlertSeverity('error');
          setAlertOpen(true);
        }
      } else {
        setAlertMessage(`Please enter your @vit.edu mail id`);
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    } else {
      setAlertMessage(
        `Sorry! We are currently only accepting entries from select colleges. If you wish to have GradGoggles in your campus, contact us.`,
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  return (
    <React.Fragment>
      <div className="signUpChanger">
        <Typography
          onClick={() => {
            setToggleForm(false);
          }}
        >
          Already have an account? <a href="#">Log In</a>.{' '}
        </Typography>
      </div>

      <div className="emailHolder" id="fullname">
        <TextField
          error={signUpHelperText.firstName === '' ? false : true}
          helperText={signUpHelperText.firstName}
          required
          id="name-with-icon"
          name="firstName"
          value={signUpForm.firstName}
          onChange={handleSignUpChange}
          placeholder="First Name"
          variant="outlined"
        />

        <TextField
          error={signUpHelperText.lastName === '' ? false : true}
          helperText={signUpHelperText.lastName}
          required
          id="name-with-icon"
          name="lastName"
          value={signUpForm.lastName}
          onChange={handleSignUpChange}
          placeholder="Last Name"
          variant="outlined"
        />
      </div>

      <div className="emailHolder">
        <TextField
          error={signUpHelperText.email === '' ? false : true}
          helperText={signUpHelperText.email}
          required
          fullWidth
          id="email-with-icon"
          name="email"
          value={signUpForm.email}
          onChange={handleSignUpChange}
          placeholder="Email"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailOutlineOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="passHolder">
        <TextField
          fullWidth
          error={signUpHelperText.password === '' ? false : true}
          helperText={signUpHelperText.password}
          required
          onChange={handleSignUpChange}
          name="password"
          value={signUpForm.password}
          InputLabelProps={{ shrink: true }}
          type={passVisibility ? 'text' : 'password'}
          id="password-with-icon"
          placeholder="Password"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <>
                <InputAdornment
                  // className={props.classes.iconHandler}
                  onClick={() => {
                    setPassVisibility(!passVisibility);
                  }}
                  position="start"
                >
                  {passVisibility ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </InputAdornment>
              </>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="logButtonHolder">
        <Button
          fullWidth
          onClick={validateStep}
          variant="outlined"
          size="medium"
          style={{ borderRadius: 25, textTransform: 'none' }}
        >
          Create my account
        </Button>
      </div>
      <div id="orSpan"> or </div>
      <div className="googleButton">
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          style={{ borderRadius: 25, textTransform: 'none' }}
          onClick={handleGoogleBtnClick}
          startIcon={<img src={googleLogo} alt="google_icon" width="30" />}
        >
          Sign up with Google
        </Button>
      </div>
    </React.Fragment>
  );
};

export default SignUp1;
