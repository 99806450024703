import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
//eslint-disable-next-line
import { css } from 'styled-components/macro';
import {
  SectionHeading,
  Subheading as SubheadingBase,
  SectionDescription,
} from './HelperComponents';

import defaultCardImage from './images/shield-icon.svg';

import { ReactComponent as SvgDecoratorBlob3 } from './images/svg-decorator-blob-3.svg';

import SupportIconImage from './images/support-icon.svg';
import CustomizeIconImage from './images/customize-icon.svg';
import FastIconImage from './images/fast-icon.svg';
import ReliableIconImage from './images/reliable-icon.svg';
import SimpleIconImage from './images/simple-icon.svg';

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-16 md:py-20`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({
  cards = null,
  heading = 'Unique Features',
  subheading = '',
  description = 'GradGoggles offers a wide range of features to make your college days and the virtual yearbook a memorable experience.',
}) => {
  const defaultCards = [
    {
      imageSrc: SimpleIconImage,
      title: 'Socialize',
      description:
        'The yearbook is a time to let people around you know their impact on you. Post scraps on their profile on GradGoggles to let your heart out!',
    },
    {
      imageSrc: FastIconImage,
      title: 'Make Memories',
      description:
        "You'll always carry a fond picture of your college, but we'll help you decorate it with our very own college wall and department pictures.",
    },
    {
      imageSrc: CustomizeIconImage,
      title: 'Make It Yours',
      description:
        'Customize your own virtual yearbook experience. Keep your OGs at the top of your mind and your yearbook with our close friends feature.',
    },
    {
      imageSrc: CustomizeIconImage,
      title: 'More Power to You',
      description:
        'You can decide whose scraps are shown, and remove the ones that don’t belong on your profile.',
    },
    {
      imageSrc: ReliableIconImage,
      title: 'Secure',
      description:
        'Our team is dedicated to safeguarding our users’ data by ensuring the use of secure and peer-tested solutions.',
    },
    {
      imageSrc: SupportIconImage,
      title: 'Top Notch Support',
      description:
        'We provide quality support to all users and institutions using our virtual yearbook experience.',
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container className={'features-section-contaienr'}>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || 'Fully Secure'}</span>
                <p className="description" style={{ width: '300px' }}>
                  {card.description ||
                    'Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud.'}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
