import React, { useState } from 'react';
import { AppBar, Tooltip } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/ShareOutlined';
import StarIcon from '@material-ui/icons/Star';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CakeOutlinedIcon from '@material-ui/icons/CakeOutlined';
import { formatDate } from '../../utils/formatDate';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import LanguageIcon from '@material-ui/icons/Language';
import FormatQuoteOutlinedIcon from '@material-ui/icons/FormatQuoteOutlined';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { CloseOutlined } from '@material-ui/icons';

const UserDetails = ({
  user,
  handleShareProfileClick,
  handleAddAsCloseFriend,
  handleRemoveAsCloseFriend,
  isCloseFriend,
  handleClose,
}) => {
  const handleAddCloseFriend = (e) => {
    if (handleAddAsCloseFriend) {
      handleAddAsCloseFriend(e);
    } else {
      console.log('handleAddAsCloseFriend doesnt exist');
    }
  };

  const handleRemoveCloseFriend = (e) => {
    if (handleRemoveAsCloseFriend) {
      handleRemoveAsCloseFriend(e);
    } else {
      console.log('handleRemoveAsCloseFriend doesnt exist');
    }
  };

  return (
    <>
      <div className="pp-user-info">
        <div className="pp-user-name-dept">
          <div className="pp-user-name">{user.name}</div>
          <div className="pp-user-dept">{user.dept.toUpperCase()}</div>
        </div>

        <div className="pp-share-star-icons">
          <div className="pp-share-icon">
            <Tooltip title={'Share this profile'}>
              <ShareIcon onClick={handleShareProfileClick} />
            </Tooltip>
          </div>

          <div className="pp-share-icon">
            <Tooltip
              title={
                isCloseFriend ? 'Remove as close friend' : 'Add as close friend'
              }
            >
              {isCloseFriend ? (
                <StarIcon
                  className={'pp-share-icon'}
                  onClick={handleRemoveCloseFriend}
                />
              ) : (
                <StarBorderIcon
                  className={'pp-share-icon'}
                  onClick={handleAddCloseFriend}
                />
              )}
            </Tooltip>
          </div>

          <div className="pp-share-icon">
            <Tooltip title={'Return to the Yearbook'}>
              <CloseOutlined
                className={'pp-share-icon'}
                onClick={handleClose}
              />
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="pp-user-details-tabs">
        <UserDetailsTabs user={user} />
      </div>
    </>
  );
};

const UserDetailsTabs = ({ user }) => {
  const [currentActiveTab, setCurrentActiveTab] = useState(0);
  return (
    <>
      <AppBar position={'static'}>
        <Tabs
          value={currentActiveTab}
          onChange={(e, newValue) => {
            setCurrentActiveTab(newValue);
          }}
        >
          <Tab label={'About'} />
          <Tab label={'Social Profiles'} />
        </Tabs>
      </AppBar>
      <TabPanel value={currentActiveTab} index={0}>
        <UserAbout quote={user.quote} dob={user.dob} email={user.email} />
      </TabPanel>
      <TabPanel value={currentActiveTab} index={1}>
        <UserSocialProfiles user={user} />
      </TabPanel>
    </>
  );
};

const UserAbout = ({ quote, dob, email }) => (
  <>
    <div className="pp-user-about-quote">
      <div className="pp-user-about-quote-icon">
        <FormatQuoteOutlinedIcon />
      </div>
      <div className="pp-user-about-quote-content">{quote}</div>
    </div>
    <div className="pp-user-about">
      <div className="pp-user-bday-container">
        <div className="pp-user-bday-icon">
          <CakeOutlinedIcon />
        </div>
        <div className="pp-user-bday">{formatDate(dob)}</div>
      </div>
      <div className="pp-user-email-container">
        <div className="pp-user-email-icon">
          <MailOutlineIcon />
        </div>
        <a href={`mailto:${email}`} target={'_blank'}>
          <div className="pp-user-email">{email.toLowerCase()}</div>
        </a>
      </div>
    </div>
  </>
);

const UserSocialProfiles = ({ user }) => {
  const areAnySocialProfiles =
    user.social_web ||
    user.social_twitter ||
    user.social_insta ||
    user.social_linkedin;

  return areAnySocialProfiles ? (
    <div className={'pp-user-socials'}>
      {user.social_insta != 'null' && (
        <div className="pp-user-social">
          <InstagramIcon />
          <div className="pp-user-social">
            <a href={user.social_insta} target={'_blank'}>
              {user.name}
            </a>
          </div>
        </div>
      )}

      {user.social_twitter != 'null' && (
        <div className="pp-user-social">
          <TwitterIcon />
          <div className="pp-user-social-twitter">
            <a href={user.social_twitter} target={'_blank'}>
              {user.name}
            </a>
          </div>
        </div>
      )}

      {user.social_linkedin != 'null' && (
        <div className="pp-user-social">
          <LinkedInIcon />
          <div className="pp-user-social-li">
            <a href={user.social_linkedin} target={'_blank'}>
              {user.name}
            </a>
          </div>
        </div>
      )}

      {user.social_web != 'null' && (
        <div className="pp-user-social">
          <LanguageIcon />
          <div className="pp-user-social">
            <a href={user.social_web} target={'_blank'}>
              {user.name}
            </a>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className={'pp-user-no-socials'}>
      Looks like {user.name.substring(0, user.name.indexOf(' '))} hasn't linked
      any social profiles.
    </div>
  );
};

export default UserDetails;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}
